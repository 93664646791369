import React from "react";
import { Attraction } from "../../data/attractions";
import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { calculateTimeDiff } from "../../data/time";
import { pink } from "@mui/material/colors";
import MarkAttractionCompleteDialog from "./MarkAttractionCompleteDialog";
import UnmarkAttractionCompleteDialog from "./UnmarkAttractionCompleteDialog";
import { ErrorOutline } from "@mui/icons-material";


export interface AttractionComponentProps {
    attraction: Attraction;
    currentLocalTime: Date;
    tier: number;
    hasBeenCompletedToday?: boolean;
    completedAt?: string;
    color?: string;
    customAttractionName?: string
    customAvatar?: JSX.Element;
    customAttractionDescription?: string;
}

export const AttractionComponent = ({
        attraction, 
        hasBeenCompletedToday = false, 
        currentLocalTime, 
        tier,
        color,
        customAttractionName,
        customAvatar,
        customAttractionDescription
    }: AttractionComponentProps) => {
    
    
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
    let secondaryAction = null;
    if (attraction.returnTime !== null) {
        secondaryAction =  (<Typography sx={{ fontSize: '.85em', color: '#505050'  }}>
            {attraction.returnTime}
        </Typography>)
    }

    const onAttractionClick = () => {
        setConfirmDialogOpen(true);
    }

    const onConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    }

    if (color == null) {
        switch(tier) {
            case 1:
                color = '#a2cf6e';
                break
            case 2: 
                color = '#ffcd38';
                break;
            case 3:
                color = '#b2dfdb';
                break;
        }
    }

    let attractionDialog = (<MarkAttractionCompleteDialog 
        open={confirmDialogOpen}
        onClose={onConfirmDialogClose}
        attractionName={attraction.name}
        attractionId={attraction.id}
        parkId={attraction.parkId}
    />)

    let completedAt = null;
    if (hasBeenCompletedToday) {
        color = '';
        attractionDialog = (<UnmarkAttractionCompleteDialog 
            open={confirmDialogOpen}
            onClose={onConfirmDialogClose}
            attractionName={attraction.name}
            attractionId={attraction.id}
            parkId={attraction.parkId}
        />)

        completedAt = (<div>Completed at {attraction.completedAt}</div>);
    }

    let attractionName = customAttractionName ?? attraction.name;
    let avatar = customAvatar ?? (<Avatar sx={{ bgcolor: pink[500] }}>{attraction.waitTime}</Avatar>)

    let attractionDescription;
    if (customAttractionDescription == null) {
        attractionDescription = (<>
            Updated {calculateTimeDiff(currentLocalTime, attraction.lastUpdated)}
            {completedAt}
        </>)
    }
    else {
        attractionDescription = ((<>
            {customAttractionDescription}
        </>))
    }

    return (<React.Fragment key={attraction.name}>
        <ListItem onClick={onAttractionClick} sx={{bgcolor: color}} secondaryAction={secondaryAction}>
            <ListItemAvatar>
                {avatar}
            </ListItemAvatar>
            <ListItemText 
                primary={attractionName} 
                secondary={
                    <Typography component="span" sx={{ display: 'inline', fontSize: '.85em', color: '#505050'  }}>
                        {attractionDescription}
                    </Typography>
                } />
        </ListItem>
        {attractionDialog}
        <Divider variant="fullWidth" component="li" />
    </React.Fragment>);
};

export interface DownAttractionProps {
    attraction: Attraction;
    currentLocalTime: Date;
    tier: number;
    hasBeenCompletedToday?: boolean;
    completedAt?: string;
}

export const DownAttractionComponent = ({attraction, currentLocalTime, tier, hasBeenCompletedToday, completedAt}: DownAttractionProps) => {
    const customAttractionName = attraction.name + attraction.tier + ' [DOWN]';
    const customAttractionDescription = attraction.buildDownSinceString(currentLocalTime);

    return (<AttractionComponent
                attraction={attraction}
                currentLocalTime={currentLocalTime}
                tier={tier}
                hasBeenCompletedToday={hasBeenCompletedToday}
                completedAt={completedAt}
                customAttractionName={customAttractionName}
                color={'tomato'}
                customAvatar={<ErrorOutline sx={{height: 45, width: 45, color: 'black'}}/>}
                customAttractionDescription={customAttractionDescription}
        />)
};