import {useEffect, useState} from 'react';
import {getActivePark, getSelloutAlertOffset, saveSelloutAlertOffset, setActivePark} from '../../data';
import { Snackbar, Alert, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, IconButton, Paper } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ANIMAL_KINGDOM, DISNEY_CALIFORNIA_ADVENTURE, DISNEYLAND, EPCOT, HOLLYWOOD_STUDIOS, MAGIC_KINGDOM } from '../../constants/parks';

function Settings() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [result, setResult] = useState('');
  const [activeParkId, setActiveParkId] = useState<string>('');
  const [selloutAlertOffset, setSelloutAlertOffset] = useState<string>('');

  const onActiveParkChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let parkName = event.target.labels![0].textContent;
    const parkId = (event.target as HTMLInputElement).value;
    try {
      setActiveParkId(parkId);
      await setActivePark(parkId);
      setResult(`Park updated to ${parkName}`);
      setSnackbarOpen(true);
    }
    catch (err: any) {
      console.error(err);
      setResult('Park failed to update: ' + err)
      setSnackbarOpen(true);
    }
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  }

  const loadActivePark = async () => {
    const activePark = await getActivePark()
    if (activePark != null) {
      setActiveParkId(activePark.parkId);
    }
  }

  useEffect(() => {
    loadActivePark();
  }, []);

  const onSelloutAlertSave = async () => {
    try {
      setSelloutAlertOffset(selloutAlertOffset);
      await saveSelloutAlertOffset(selloutAlertOffset);
      setResult(`Sellout alert offset saved.`);
      setSnackbarOpen(true);
    }
    catch (err: any) {
      console.error(err);
      setResult('Sellout alert offset failed to update: ' + err)
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const loadSelloutAlertOffset = async () => {
      const offset: string = await getSelloutAlertOffset()
      setSelloutAlertOffset(offset);
    }
    loadSelloutAlertOffset();
  }, [])

  const selloutAlertAsInt = parseInt(selloutAlertOffset);

  var hours = Math.floor(selloutAlertAsInt / 3600);
  var minutes = Math.floor(selloutAlertAsInt % 3600 / 60);
  var seconds = Math.floor(selloutAlertAsInt % 3600 % 60);

  return (
    <Box sx={{ marginLeft: 5, marginBottom: 2 }}>
      <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={snackbarOpen} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        {result}
      </Alert>
    </Snackbar>
    <FormControl>
        <h4>Turn on alerts for park</h4>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="6"
          name="radio-buttons-group"
          onChange={onActiveParkChange}
          value={activeParkId}
        >
          <FormLabel id="demo-radio-buttons-group-label">Disney world</FormLabel>
          <FormControlLabel value={MAGIC_KINGDOM} control={<Radio />} label="Magic Kingdom" />
          <FormControlLabel value={EPCOT} control={<Radio />} label="Epcot" />
          <FormControlLabel value={ANIMAL_KINGDOM} control={<Radio />} label="Animal Kingdom" />
          <FormControlLabel value={HOLLYWOOD_STUDIOS} control={<Radio />} label="Hollywood Studios" />
          <FormLabel id="demo-radio-buttons-group-label">Disneyland</FormLabel>
          <FormControlLabel value={DISNEYLAND} control={<Radio />} label="Disneyland" />
          <FormControlLabel value={DISNEY_CALIFORNIA_ADVENTURE} control={<Radio />} label="DCA" />
        </RadioGroup>
        <h4>Sellout alert offset</h4>
        <div>
          <TextField
            label={'seconds'}
              value={selloutAlertOffset}
              color="secondary"
              sx={{ mb: '10px'}}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelloutAlertOffset(event.target.value);
              }}
              helperText={`${hours} hour(s) ${minutes} minute(s) ${seconds} second(s)`}
          />
          <IconButton color="primary" onClick={onSelloutAlertSave}>
            <CheckCircleOutlineIcon />
          </IconButton>
        </div>
      </FormControl>

    </Box>
  );
}

export default Settings;
