import { CRITTER_CAROUSEL, GOLDEN_ZEPHYR, GOOFYS_SKY_SCHOOL, GRIZZLY_RIVER_RUN, INCREDICOASTER, INSIDE_OUT, JUMPIN_JELLYFISH, LITTLE_MERMAID, LUIGIS_ROLLICKIN_ROADSTERS, MATERS_JUNKYARD_JAMBOREE, MISSION_BREAKOUT, MONSTERS_INC, PHILHARMAGIC, PIXAR_PAL_AROUND_NON_SWINGING, PIXAR_PAL_AROUND_SWINGING, RADIATOR_SPRINGS_RACERS, SOARIN, SYMPHONY_SWINGS, TOY_STORY_MIDWAY_MANIA, WEBSLINGERS } from "../../constants/attractions/dca";
import { ALICE, ASTRO_ORBITER, AUTOPIA, BIG_THUNDER_MOUNTAIN, BUZZ_LIGHTYEAR, CASEY_JR_CIRCUS_TRAIN, DISNEYLAND_RAILROAD, DUMBO, EXPLORER_CANOES, FINDING_NEMO, GADGETCOASTER, HAUNTED_MANSION, INDIANA_JONES, ITS_A_SMALL_WORLD, JUNGLE_CRUISE, KING_ARTHUR_CAROUSEL, MAD_TEA_PARTY, MARK_TWAIN_RIVERBOAT, MATTERHORN, MICKEYS_HOUSE_MEET_MICKEY, MONORAIL, MR_TOADS, PETER_PANS_FLIGHT, PINOCCHIO, PIRATES_LAYER_TOM_SAWYERS, PIRATES_OF_THE_CARIBBEAN, RISE_OF_THE_RESISTANCE, ROGER_RABBIT, RUNAWAY_RAILWAY, SMUGGLERS_RUN, SNOW_WHITE, SPACE_MOUNTAIN, STAR_TOURS, STORYLAND_CANAL_BOATS, WINNIE_THE_POOH } from "../../constants/attractions/disneyland";
import { AVENGERS_CAMPUS, BUENA_VISTA_STREET, CARS_LAND, GRIZZLY_PEAK, HOLLYWOOD_LAND, PARADISE_GARDENS_PARK, PIXAR_PIER, SAN_FRANSOKYO_SQUARE } from "../../constants/lands/dca";
import { FRONTIERLAND, FANTASYLAND, GALAXYS_EDGE, TOONTOWN, ADVENTURELAND, TOMORROWLAND, CRITTER_COUNTRY, NEW_ORLEANS_SQUARE, MAIN_STREET } from "../../constants/lands/disneyland";
import { DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE } from "../../constants/parks";


export const attractionsByLand: {
    [park: string]: {
        [land: string]: string[]
    }
   } = {
    [DISNEYLAND]: {
        [FANTASYLAND]: [   
            STORYLAND_CANAL_BOATS,
            PETER_PANS_FLIGHT,
            MAD_TEA_PARTY,
            SNOW_WHITE,
            ITS_A_SMALL_WORLD,
            DUMBO,
            CASEY_JR_CIRCUS_TRAIN,
            MR_TOADS,
            PINOCCHIO,
            KING_ARTHUR_CAROUSEL,
            ALICE,
            MATTERHORN
        ],
        [CRITTER_COUNTRY]: [
            WINNIE_THE_POOH,
            EXPLORER_CANOES
        ],
        [FRONTIERLAND]: [
            PIRATES_LAYER_TOM_SAWYERS,
            MARK_TWAIN_RIVERBOAT,
            BIG_THUNDER_MOUNTAIN
        ],
        [ADVENTURELAND]: [
            INDIANA_JONES,
            JUNGLE_CRUISE
        ],
        [GALAXYS_EDGE]: [
            RISE_OF_THE_RESISTANCE,
            SMUGGLERS_RUN
        ],
        [TOMORROWLAND]: [
            FINDING_NEMO,
            MONORAIL,
            ASTRO_ORBITER,
            BUZZ_LIGHTYEAR,
            SPACE_MOUNTAIN,
            STAR_TOURS,
            AUTOPIA,
            DISNEYLAND_RAILROAD
        ],
        [TOONTOWN]: [
            GADGETCOASTER,
            DISNEYLAND_RAILROAD,
            ROGER_RABBIT,
            RUNAWAY_RAILWAY,
            MICKEYS_HOUSE_MEET_MICKEY
        ],
        [NEW_ORLEANS_SQUARE]: [
            DISNEYLAND_RAILROAD,
            PIRATES_OF_THE_CARIBBEAN,
            HAUNTED_MANSION
        ],
        [MAIN_STREET]: []
    },
    [DISNEY_CALIFORNIA_ADVENTURE]: {
        [AVENGERS_CAMPUS]: [
            MISSION_BREAKOUT,
            WEBSLINGERS
        ],
        [BUENA_VISTA_STREET]: [],
        [CARS_LAND]: [
            LUIGIS_ROLLICKIN_ROADSTERS,
            MATERS_JUNKYARD_JAMBOREE,
            RADIATOR_SPRINGS_RACERS
        ],
        [GRIZZLY_PEAK]: [
            GRIZZLY_RIVER_RUN,
            SOARIN
        ],
        [HOLLYWOOD_LAND]: [
            PHILHARMAGIC,
            MONSTERS_INC
        ],
        [PARADISE_GARDENS_PARK]: [
            GOLDEN_ZEPHYR,
            GOOFYS_SKY_SCHOOL,
            JUMPIN_JELLYFISH,
            LITTLE_MERMAID,
            SYMPHONY_SWINGS
        ],
        [PIXAR_PIER]: [
            INCREDICOASTER,
            INSIDE_OUT,
            CRITTER_CAROUSEL,
            PIXAR_PAL_AROUND_NON_SWINGING,
            PIXAR_PAL_AROUND_SWINGING,
            TOY_STORY_MIDWAY_MANIA
        ],
        [SAN_FRANSOKYO_SQUARE]: []
    }
}