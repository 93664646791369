import { useRefreshPageAfterSeconds } from "../data/time";
import { Typography } from "@mui/material";
import { useAppSelector } from "../redux/hooks";
import { onRefresh } from "../redux/parks";
import Clock from "./Clock";

interface RefreshProps {
    refreshAfterSeconds?: number;
}

const Refresh = ({refreshAfterSeconds = 60}: RefreshProps) => {
    const refreshState = useAppSelector(onRefresh);

    useRefreshPageAfterSeconds(refreshAfterSeconds);

    const localRefreshTime = new Date(refreshState.refreshedAt);

    return (<Typography component={'span'}  sx={{ display: 'inline', fontSize: '.8em' }}
                variant="body2"
                >
                <Clock />
                <div>Refreshed on {localRefreshTime.toLocaleString()}</div>
        </Typography>);
};

export default Refresh;