import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, Table, TableBody, TableContainer, TableHead, TableRow, TextField, styled, tableCellClasses } from "@mui/material";
import Paper from '@mui/material/Paper';
import { VirtualQueueAttraction } from "../data/attractions";
import { utcToLocal } from "../data/time";
import { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "./Tables";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getMyBoardingGroups, setMyBoardingGroup } from "../data";
import { useAppDispatch } from "../redux/hooks";
import { refreshPage } from "../redux/parks";


interface BoardingGroupHistoryProps {
    open: boolean;
    handleClose: () => void;
    attraction: VirtualQueueAttraction;
    existingBoardingGroupNumber?: string;
}

function BoardingGroupHistory({open, handleClose, attraction, existingBoardingGroupNumber}: BoardingGroupHistoryProps) {
    const dispatch = useAppDispatch();
    
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [boardingGroupResult, setBoardingGroupResult] = useState('');

    const [boardingGroupNumber, setBoardingGroupNumber] = useState<string>(existingBoardingGroupNumber ?? '');
    
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    }

    const loadMyBoardingGroup = async () => {
        const myBoardingGroups = await getMyBoardingGroups(attraction.parkId)
        const thisBoardingGroup = myBoardingGroups.find(bg => bg.attractionId === attraction.id);
        if (thisBoardingGroup != null) {
            setBoardingGroupNumber(thisBoardingGroup.boardingGroupNumber);
        }
      }
    
    useEffect(() => {
        if (existingBoardingGroupNumber == null) {
            loadMyBoardingGroup();
        }
    }, []);


    const onMyBoardingGroupSave = async () => {
        if (boardingGroupNumber == '') {
            return;
        }
    
        try {
            await setMyBoardingGroup(attraction.id, attraction.parkId, boardingGroupNumber)
            setBoardingGroupResult(`Boarding group set to ${boardingGroupNumber}`);
            setSnackbarOpen(true);
            dispatch(refreshPage());
        }
        catch (err: any) {
          console.error(err);
          setBoardingGroupNumber('Boarding group failed to set: ' + err)
          setSnackbarOpen(true);
        }
    }

    let boardingGroupsToDisplay = []

    if (attraction.boardingGroupHistory.boardingGroupHistory != null) {
        for (let i=0; i < attraction.boardingGroupHistory.boardingGroupHistory.length; i++) {
            const thisBoardingGroup = attraction.boardingGroupHistory.boardingGroupHistory[i];
            let lastBoardingGroupStart: number = 0;
            if (boardingGroupsToDisplay.length > 0) {
                lastBoardingGroupStart = Number(boardingGroupsToDisplay[boardingGroupsToDisplay.length - 1].boardingGroupStart)
            }
            else {
                boardingGroupsToDisplay.push(thisBoardingGroup);
            }

            if (lastBoardingGroupStart >= Number(thisBoardingGroup.boardingGroupStart)) {
                boardingGroupsToDisplay.push(thisBoardingGroup);
            }
        }
    }

    return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{minWidth: '75%', height: '100%'}}
        fullScreen={true}
    >
        <DialogTitle id="alert-dialog-title">
          {attraction.name}
        </DialogTitle>
        <DialogContent>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {boardingGroupResult}
                </Alert>
            </Snackbar>
            <Box 
                component="form"
                sx={{
                    '& > :not(style)': { m: 1 },
                }}
                noValidate
                autoComplete="off">
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    label={'Enter boarding group'}
                    value={boardingGroupNumber}
                    color="secondary"
                    sx={{ mb: '10px'}}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setBoardingGroupNumber(event.target.value);
                    }}
                />
                <IconButton color="primary" onClick={onMyBoardingGroupSave}>
                    <CheckCircleOutlineIcon />
                </IconButton>
            </Box>
            <TableContainer component={Paper}>
                <Table size="medium">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Last updated</StyledTableCell>
                            <StyledTableCell>Group start</StyledTableCell>
                            <StyledTableCell>Group end</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {boardingGroupsToDisplay.map((bgh) => {
                            return (
                                <StyledTableRow key={bgh.lastUpdated}>
                                    <StyledTableCell>{utcToLocal(bgh.lastUpdated)}</StyledTableCell>
                                    <StyledTableCell>{bgh.boardingGroupStart}</StyledTableCell>
                                    <StyledTableCell>{bgh.boardingGroupEnd}</StyledTableCell>
                                </StyledTableRow>
                            )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>);
};

export default BoardingGroupHistory;
