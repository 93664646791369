import { Attraction } from "../attractions";
import { attractionsByLand } from "./attractionsPerLand";
import { landPolygons } from "./polygons/lands";
import { neighboringLands } from './neighboringLands';
import { isPointInPolygon } from 'geolib';
import { parkPolygons } from "./polygons/parks";
import { showsByLand } from "./showsPerLand";
import { CANDY_CANE_INN, ESPLANADE, GRAND_CALIFORNIAN } from "../../constants/lands/general";

export type CurrentLocation = {
    currentLand: string;
    currentPark: string;
}

export const UNKNOWN_LOCATION: CurrentLocation = {
    currentLand: 'Unknown Land',
    currentPark: 'Unknown Park'
}


export const getCurrentLocation = async () : Promise<CurrentLocation> => {  
    try {
        const currentPosition: GeolocationPosition = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const currentCoords = {
            'latitude': currentPosition.coords.latitude,
            'longitude': currentPosition.coords.longitude
        }

        let currentPark = null;
        for (const parkPolygon of parkPolygons) {
            if (isPointInPolygon(currentCoords, parkPolygon.border)) {
                currentPark = parkPolygon.name;
            }
        }

        if (currentPark == null) {
            return UNKNOWN_LOCATION;
        }

        if(isNoveltyLocation(currentPark)) {
            return {
                currentPark: currentPark,
                currentLand: currentPark
            }
        }

        for (const landPolygon of landPolygons[currentPark]) {
            if (isPointInPolygon(currentCoords, landPolygon.border)) {
                return {
                    currentPark: currentPark,
                    currentLand: landPolygon.name
                }
            }
        }
    }
    catch (e) {
        console.error(e);
    }

    // TODO: add support for some novelty locations like hotels
    return UNKNOWN_LOCATION
}

const noveltyLocations = [
    CANDY_CANE_INN,
    ESPLANADE,
    GRAND_CALIFORNIAN
]

export const isNoveltyLocation = (currentPark: string) : boolean => {
    return noveltyLocations.includes(currentPark);
}

export const filterNearbyAttractions = (currentLocation: CurrentLocation, attractions: Attraction[]) => {
    if (currentLocation == UNKNOWN_LOCATION || isNoveltyLocation(currentLocation.currentPark)) {
        return attractions;
    }

    const neighborsForPark = neighboringLands[currentLocation.currentPark];
    if (neighborsForPark == null) {
        return [];
    }

    let landsToFilterFor = neighborsForPark[currentLocation.currentLand];
    if (landsToFilterFor === null) {
        landsToFilterFor = []
    }
    // add the current land to the front
    landsToFilterFor.unshift(currentLocation.currentLand);

    const attractionsInLandForPark = attractionsByLand[currentLocation.currentPark];
    if (attractionsInLandForPark == null) {
        return [];
    }

    const attractionsInLands = landsToFilterFor.flatMap(land => attractionsInLandForPark[land]);
    const nearbyAttractions = attractions.filter(
        attraction => attractionsInLands.includes(attraction.id)
    )
    return nearbyAttractions;
}


export const filterNearbyPerformancesInNextHour = (currentLocation: CurrentLocation, shows: Attraction[]) => {
    const currentTime = new Date();
    const oneHourFromNow = new Date();
    oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);

    // filter for showtimes within 1 hr
    shows = shows.filter(
        show => {
            for (const showTime of show.showTimes) {
                if (showTime.startTime >= currentTime && showTime.startTime <= oneHourFromNow) {
                    return true;
                }
            }

            return false;
        }
    );

    if (currentLocation == UNKNOWN_LOCATION) {
        return shows;
    }

    const neighborsForPark = neighboringLands[currentLocation.currentPark];
    if (neighborsForPark == null) {
        return [];
    }

    let landsToFilterFor = neighborsForPark[currentLocation.currentLand];
    if (landsToFilterFor === null) {
        landsToFilterFor = []
    }
    // add the current land to the front
    landsToFilterFor.unshift(currentLocation.currentLand);

    const showsInLandForPark = showsByLand[currentLocation.currentPark];
    if (showsInLandForPark == null) {
        return [];
    }

    const showsInLands = landsToFilterFor.flatMap(land => showsInLandForPark[land]);
    let nearbyShows = shows.filter(
        attraction => showsInLands.includes(attraction.id)
    )

    return nearbyShows;
}


