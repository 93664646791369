import { ParkSchedule } from "../../data/schedule";
import { toCustomTimeString } from "../../data/time";

export type RopeDropItem = {
    time: string;
    text: string;
    date: Date;
}


export const sortByDate = (a: RopeDropItem, b: RopeDropItem) => {
    if (a.date == null) return 1;
    if (b.date == null) return -1;

    return a.date > b.date ? 1 : 
        a.date < b.date ? -1 : 0
}


export const buildDefaultDisneyWorldItems = (parkSchedule: ParkSchedule) => {
    let items: RopeDropItem[] = []

    const openingTime = parkSchedule.absoluteParkOpen;
    const timeToLeaveHotel = new Date(openingTime);
    timeToLeaveHotel.setMinutes(timeToLeaveHotel.getMinutes() - 90);

    items.push({
        time: toCustomTimeString(timeToLeaveHotel),
        text: 'Time to leave hotel',
        date: timeToLeaveHotel
    });

    const aimToGetToPark = new Date(openingTime);
    aimToGetToPark.setMinutes(aimToGetToPark.getMinutes() - 60);

    items.push({
        time: toCustomTimeString(aimToGetToPark),
        text: 'Aim to get to park',
        date: aimToGetToPark
    });

    const bookGeniePlusTime = new Date(openingTime);
    bookGeniePlusTime.setHours(7, 0, 0, 0);
    items.push({
        time: toCustomTimeString(bookGeniePlusTime),
        text: 'Book first genie plus ride',
        date: bookGeniePlusTime
    });

    return items;
}

export const buildDefaultDisneylandItems = (parkSchedule: ParkSchedule) => {
    let items: RopeDropItem[] = []

    const openingTime = parkSchedule.absoluteParkOpen;
    const aimToGetToSecurity = new Date(openingTime);
    aimToGetToSecurity.setHours(6, 45, 0, 0);

    items.push({
        time: toCustomTimeString(aimToGetToSecurity),
        text: 'Aim to get in line for security',
        date: aimToGetToSecurity
    });

    return items;
}