import {useEffect, useState} from 'react';
import { List, ListItem, ListItemText, Divider } from '@mui/material';
import Header from '../../components/Header';
import { getLiveDataForPark, getOpenTimeHistory } from '../../data';
import { useAppSelector } from '../../redux/hooks';
import { selectSelectedPark } from '../../redux/parks';
import { LiveData, OpenTimeHistory } from '../../data/interfaces';
import { Attraction, AttractionWithAnalytics } from '../../data/attractions';
import { AttractionAnalytics } from './AttractionAnalytics';
import { INDIANA_JONES, MATTERHORN, RISE_OF_THE_RESISTANCE } from '../../constants/attractions/disneyland';
import { MISSION_BREAKOUT, RADIATOR_SPRINGS_RACERS, TOY_STORY_MIDWAY_MANIA, WEBSLINGERS } from '../../constants/attractions/dca';

const attractionsToPrioritize = [
    RISE_OF_THE_RESISTANCE,
    RADIATOR_SPRINGS_RACERS,
    MISSION_BREAKOUT,
    WEBSLINGERS,
    MATTERHORN,
    INDIANA_JONES,
    TOY_STORY_MIDWAY_MANIA
];


function Analytics() {
  const selectedPark = useAppSelector(selectSelectedPark);

  const [openTimeHistory, setOpenTimeHistory] = useState<{[id: string]: OpenTimeHistory[]}>({});
  const [attractions, setAttractions] = useState<LiveData[]>([]);

  useEffect(() => {
      const loadWaitTimesForPark = async () => {
          const waitTimes = await getLiveDataForPark(selectedPark.id);
          const allAttractions: LiveData[] = waitTimes.liveData
              .filter(data => Attraction.IsAnAttraction(data));
      
          setAttractions(allAttractions);
      }    

      loadWaitTimesForPark();
  }, [selectedPark]);

  useEffect(() => {
    const loadOpenTimeHistory = async () => {
        const openHistoryResponse = await getOpenTimeHistory();

        const historyGroupedByAttraction = openHistoryResponse.reduce(function (memo: {[id: string]: OpenTimeHistory[]}, x) {
            if (!memo[x.id]) { memo[x.id] = []; }
            memo[x.id].push(x);
            return memo;
        }, {});

        setOpenTimeHistory(historyGroupedByAttraction);
    }

    loadOpenTimeHistory();
  }, [])
 

  const priorityAttractions = [];
  const allOtherAttractions = [];
  for (const attraction of attractions) {
    const historyForThisAttraction = openTimeHistory[attraction.id];
    if (attractionsToPrioritize.includes(attraction.id)) {
        priorityAttractions.push(new AttractionWithAnalytics(attraction, historyForThisAttraction));
        continue;
    }
    
    allOtherAttractions.push(new AttractionWithAnalytics(attraction, historyForThisAttraction));
  }

  priorityAttractions.sort((a, b) => a.name.localeCompare(b.name));
  allOtherAttractions.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <List
        sx={{
            width: '100%',
            bgcolor: 'background.paper'
        }}
    >
        <ListItem>
            <ListItemText sx={{textAlign: 'center'}} 
                secondaryTypographyProps={{component: 'span'}} 
                secondary={<Header />} />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
        {priorityAttractions.map(attraction =>  
            (<AttractionAnalytics key={attraction.name} attraction={attraction} />
        ))}
        
        <Divider variant="fullWidth" component="li"  sx={{mt: '50px'}} />
        {allOtherAttractions.map(attraction =>  
            (<AttractionAnalytics key={attraction.name} attraction={attraction} />
        ))}

        <Divider variant="fullWidth" component="li" />
    </List>
)
}

export default Analytics;
