import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { getBoardingGroupHistory, getLiveDataForPark, getMyBoardingGroups, getWaitHistoryForPark } from '../../data';
import { BoardingGroupHistory, EntityType, LiveData, LiveStatusType, MyBoardingGroupEntry, WaitHistory} from '../../data/interfaces';
import { useAppSelector } from '../../redux/hooks';
import { onRefresh, selectSelectedPark } from '../../redux/parks';
import { DownAttractionComponent, MyBoardingGroupAttractionComponent, OpenAttractionComponent } from '../../components/Attractions';
import { Performance } from '../ShowTimes/Performance';
import { Attraction, VirtualQueueAttraction } from '../../data/attractions';
import Header from '../../components/Header';

import { averageWaits } from '../WaitTimes/AverageWaits';
import { CurrentLocation, UNKNOWN_LOCATION, filterNearbyAttractions, filterNearbyPerformancesInNextHour as filterNearbyPerformancesInNextHour, getCurrentLocation, isNoveltyLocation } from '../../data/location';
import { PARK_NAMES } from '../../constants/parks';
import { noveltyAttractions } from '../../constants/attractions/novelty';
import { MyBoardingGroup } from './boardingGroup';


const buildLocationText = (currentLocation: CurrentLocation) => {
    let landInPark = '';
    if (isNoveltyLocation(currentLocation.currentPark)) {
        landInPark = `Your location: ${currentLocation.currentLand}`;
    }
    else if (currentLocation != UNKNOWN_LOCATION) {
        landInPark = `Your location: ${currentLocation.currentLand}, ${PARK_NAMES[currentLocation.currentPark]}`;
    }

    return landInPark;
}

const buildNearbyText = (currentLocation: CurrentLocation) => {
    if (currentLocation.currentLand == UNKNOWN_LOCATION.currentLand || isNoveltyLocation(currentLocation.currentPark)) {
        return "All open attractions";
    }

    return "Nearby " + currentLocation.currentLand;
}

function Dashboard() {
    const selectedPark = useAppSelector(selectSelectedPark);
    const refreshState = useAppSelector(onRefresh);

    const [attractions, setAttractions] = useState<LiveData[]>([]);
    const [performances, setPerformances] = useState<Attraction[]>([]);
    const [myBoardingGroups, setMyBoardingGroups] = useState<MyBoardingGroupEntry[]>([]);
    const [boardingGroupHistory, setBoardingGroupHistory] = useState<{[id: string]: BoardingGroupHistory}>({});
    const [waitHistory, setWaitHistory] = useState<{[id: string]: WaitHistory}>({});

    const [currentLocation, setCurrentLocation] = useState<CurrentLocation>(UNKNOWN_LOCATION);

    useEffect(() => {
        const loadCurrentLocation = async () => {
            const currentLocationResponse = await getCurrentLocation();
            setCurrentLocation(currentLocationResponse);
        }
        loadCurrentLocation();
    }, []); 

    useEffect(() => {
        const loadWaitTimesForPark = async () => {
            const response = await getLiveDataForPark(selectedPark.id, true);
            const allAttractions: LiveData[] = response.liveData
                .filter(data => Attraction.IsAnAttraction(data) || Attraction.IsAShowWithWaitTimes(data));
        
            setAttractions(allAttractions);

            const showsThatArePerformances: Attraction[] = response.liveData
                .filter(data => data.entityType === EntityType.Show)
                .map(data => new Attraction(data))
                .filter(attraction => attraction.isAPerformance());
        
            setPerformances(showsThatArePerformances);
        }    

        if (refreshState.isHardRefresh) {
            setAttractions([]);
            setPerformances([]);
        }
        loadWaitTimesForPark();
    }, [selectedPark, refreshState]);

    useEffect(() => {    
        const loadWaitHistoryForPark = async () => {
            const newWaitHistory: {[id: string]: WaitHistory} = {};
            const waitHistoryResponse = await getWaitHistoryForPark(selectedPark.id, true);
            for (const history of waitHistoryResponse) {
                newWaitHistory[history.attractionId] = history;
            }
            setWaitHistory(newWaitHistory);
        }

        loadWaitHistoryForPark();
    }, [selectedPark, refreshState]);

    useEffect(() => {    
        const loadMyBoardingGroups = async () => {
            const myBoardingGroups = await getMyBoardingGroups(selectedPark.id);
            setMyBoardingGroups(myBoardingGroups);
        }

        loadMyBoardingGroups();
    }, [selectedPark, refreshState]);

    useEffect(() => {
        const loadBoardingGroupHistory = async () => {
            const newBoardingGroupHistory: {[id: string]: BoardingGroupHistory} = {};
            const boardingGroupHistoryResponse = await getBoardingGroupHistory(selectedPark.id);
            for (const history of boardingGroupHistoryResponse) {
                newBoardingGroupHistory[history.attractionId] = history;
            }
            setBoardingGroupHistory(newBoardingGroupHistory);    
        }
    
        loadBoardingGroupHistory();
    }, [selectedPark, refreshState]);

    let openAttractions: Attraction[] = [];
    let downAttractions: Attraction[] = [];
    let downAttractionsWithGeniePlus: Attraction[] = [];
    let activeBoardingGroups: MyBoardingGroup[] = [];

    const novelties = noveltyAttractions[currentLocation.currentLand];
    if (novelties != null) {
        openAttractions.push(...novelties);
    }

    for (const attraction of attractions) {
        const waitHistoryForThisAttraction = waitHistory[attraction.id];
        const avgWaitForThisAttraction = averageWaits[attraction.parkId][attraction.id];

        if (Attraction.HasAVirtualQueue(attraction)){
            const boardingGroupHistoryForThisAttraction = boardingGroupHistory[attraction.id] || [];
            const myBoardingGroup = myBoardingGroups.find(bg => bg.attractionId == attraction.id);
            if (myBoardingGroup != null) {
                const thisBoardingGroup = new MyBoardingGroup(
                    myBoardingGroup.boardingGroupNumber, 
                    new VirtualQueueAttraction(attraction, boardingGroupHistoryForThisAttraction)
                );
                if (!thisBoardingGroup.hasPast()) {
                    activeBoardingGroups.push(thisBoardingGroup);
                }
            }
            continue;
        }

        if (attraction.status === LiveStatusType.Operating && attraction.queue != null) {
            openAttractions.push(new Attraction(attraction, waitHistoryForThisAttraction, avgWaitForThisAttraction));
            continue;
        }

        if (attraction.status === LiveStatusType.Down && attraction.queue?.RETURN_TIME != null) {
            downAttractionsWithGeniePlus.push(new Attraction(attraction, waitHistoryForThisAttraction, avgWaitForThisAttraction));
            continue;
        }

        if (attraction.status === LiveStatusType.Down) {
            downAttractions.push(new Attraction(attraction, waitHistoryForThisAttraction, avgWaitForThisAttraction));
            continue;
        }
    }

    downAttractions = filterNearbyAttractions(currentLocation, downAttractions);
    openAttractions = filterNearbyAttractions(currentLocation, openAttractions);

    let sortFunc = Attraction.sortByAbsoluteFromAverage;
    openAttractions = openAttractions.sort(sortFunc);

    const nearbyPerformances: Attraction[] = filterNearbyPerformancesInNextHour(currentLocation, performances);

    let sortedPerformances = nearbyPerformances;
    sortedPerformances = sortedPerformances.sort(Attraction.sortByShowTime);

    const landInPark = buildLocationText(currentLocation);

    const currentLocalTime = new Date();

    let downAttractionsWithGeniePlusHtml = null;
    if (downAttractionsWithGeniePlus.length > 0) {
        downAttractionsWithGeniePlusHtml = (<>
            <ListItem>
                <ListItemText primary={"Down with Genie+"} />
            </ListItem>
            {downAttractionsWithGeniePlus.map(attraction =>  
                (<DownAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}

            <Divider variant="fullWidth" component="li"  sx={{mt: '50px'}} />
        </> );
    }

    let activeBoardingGroupsHtml = null;
    if (activeBoardingGroups.length > 0) {
        activeBoardingGroupsHtml = (<>
            <ListItem>
                <ListItemText primary={"Active boarding groups"} />
            </ListItem>
            
            {activeBoardingGroups.map(boardingGroup =>  
                (<MyBoardingGroupAttractionComponent key={boardingGroup.attraction.name} myBoardingGroup={boardingGroup} currentLocalTime={currentLocalTime} />
            ))}

            <Divider variant="fullWidth" component="li"  sx={{mt: '50px'}} />
        </>);
    }


    return (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper'
            }}
        >
            <ListItem>
                <ListItemText sx={{textAlign: 'center'}} 
                    secondaryTypographyProps={{component: 'span'}} 
                    secondary={<Header extraText={landInPark} />} />
            </ListItem>
            <Divider variant="fullWidth" component="li" />

            {downAttractionsWithGeniePlusHtml}

            {activeBoardingGroupsHtml}

            <ListItem>
                <ListItemText primary={buildNearbyText(currentLocation)} />
            </ListItem>
            {downAttractions.map(attraction =>  
                (<DownAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}
            {openAttractions.map(attraction =>  
                (<OpenAttractionComponent 
                    key={attraction.name} 
                    attraction={attraction} 
                    currentLocalTime={currentLocalTime}
                    shouldCalculatePercentFromAverage={true} />
            ))}

            <Divider variant="fullWidth" component="li"  sx={{mt: '50px'}} />

            <ListItem>
                <ListItemText primary={"Nearby shows in next hour"} />
            </ListItem>
            {sortedPerformances.map(performance =>  
                (<Performance key={performance.name} attraction={performance} />)
            )}

            <Divider variant="fullWidth" component="li"  sx={{mt: '50px'}} />
        </List>
    )
};


export default Dashboard;