import { Attraction } from "../../data/attractions";
import { EntityType, LiveStatusType } from "../../data/interfaces";
import { v4 as uuidv4 } from 'uuid';
import { CANDY_CANE_INN } from "../lands/general";


const buildNoveltyAttraction = (name: string, parkId: string) => {
    return new Attraction(
        {
            entityType: EntityType.Attraction,
            id: uuidv4(),
            name: name,
            status: LiveStatusType.Operating,
            parkId: parkId,
            lastUpdated: new Date().toISOString(),
            showtimes: [],
            queue: {
                STANDBY: {
                    waitTime: 5
                }
            }
        }
    )
}

export const noveltyAttractions = {
    [CANDY_CANE_INN]: [
        buildNoveltyAttraction('Candy Cane Inn Breakfast', CANDY_CANE_INN)
    ]
}