import React from "react";
import { Attraction } from "../../data/attractions";
import { Divider, ListItem, ListItemText, Typography } from "@mui/material";
import PerformanceDetails from "./PerformanceDetails";

export interface PerformanceProps {
    attraction: Attraction;
}

export const Performance = ({attraction}: PerformanceProps) => {
    const [performanceDetailsOpen, setPerformanceDetailsOpen] = React.useState(false);
    const onPerformanceClick = () => {
        setPerformanceDetailsOpen(true);
    }
    let waitHistory = <></>
    if (performanceDetailsOpen) {
        waitHistory = <PerformanceDetails attraction={attraction} open={performanceDetailsOpen} handleClose={() => setPerformanceDetailsOpen(false)} />;
    }

    let secondaryAction = <>
        <div>
            <Typography component="span" sx={{ display: 'inline', color: 'black', fontWeight: 'bold' }}>
                {attraction.buildUpcomingPerformanceFirst()}
            </Typography>{attraction.buildUpcomingPerformanceStringEnd()}
        </div>
    </>

    return (<React.Fragment key={attraction.name}>
        <ListItem onClick={onPerformanceClick}>
            <ListItemText primary={attraction.name} secondary={
                <Typography component="span" sx={{ display: 'inline', fontSize: '.85em', color: '#505050'  }}>
                    {secondaryAction}
                </Typography>
            } />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
        {waitHistory}
    </React.Fragment>);
};
