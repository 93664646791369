import { AVENGERS_CAMPUS, BUENA_VISTA_STREET, CARS_LAND, GRIZZLY_PEAK, HOLLYWOOD_LAND, PARADISE_GARDENS_PARK, PIXAR_PIER, SAN_FRANSOKYO_SQUARE } from "../../constants/lands/dca";
import { FRONTIERLAND, FANTASYLAND, GALAXYS_EDGE, TOONTOWN, ADVENTURELAND, TOMORROWLAND, CRITTER_COUNTRY, NEW_ORLEANS_SQUARE, MAIN_STREET } from "../../constants/lands/disneyland";
import { DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE } from "../../constants/parks";
import { AVENGERS_ASSEMBLE, AVENGERS_HEADQUARTERS, DR_STRANGE, FIVE_AND_DIME, GUARDIANS_DANCE_OFF, PARADISE_GARDEN_BANDSTAND, THE_AMAZING_SPIDERMAN, WARRIORS_OF_WAKANDA, WORLD_OF_COLOR } from "../../constants/shows/dca";
import { DAPPER_DANS, DISNEYLAND_BAND, FANTASMIC, FLAG_RETREAT, JAMBALAYA_JAZZ, MAGIC_HAPPENS_PARADE, MAIN_STREET_PIANO_PLAYER, PEARLY_BAND, STORYTELLING_AT_ROYAL_THEATER, THE_BOOTSTRAPPERS, TOGETHER_FOREVER_FIREWORKS, TOGETHER_FOREVER_PROJECTIONS } from "../../constants/shows/disneyland";


export const showsByLand: {
    [park: string]: {
        [land: string]: string[]
    }
   } = {
    [DISNEYLAND]: {
        [FANTASYLAND]: [
            PEARLY_BAND,
            MAGIC_HAPPENS_PARADE,
            STORYTELLING_AT_ROYAL_THEATER,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ],
        [CRITTER_COUNTRY]: [
            MAGIC_HAPPENS_PARADE,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ],
        [FRONTIERLAND]: [
            FANTASMIC,
            MAGIC_HAPPENS_PARADE,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ],
        [ADVENTURELAND]: [
            MAGIC_HAPPENS_PARADE,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ],
        [GALAXYS_EDGE]: [
            MAGIC_HAPPENS_PARADE,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ],
        [TOMORROWLAND]: [
            MAGIC_HAPPENS_PARADE,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ],
        [TOONTOWN]: [
            MAGIC_HAPPENS_PARADE,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ],
        [NEW_ORLEANS_SQUARE]: [
            JAMBALAYA_JAZZ,
            THE_BOOTSTRAPPERS,
            MAGIC_HAPPENS_PARADE,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ], 
        [MAIN_STREET]: [
            FLAG_RETREAT,
            MAGIC_HAPPENS_PARADE,
            DAPPER_DANS,
            DISNEYLAND_BAND,
            MAIN_STREET_PIANO_PLAYER,
            TOGETHER_FOREVER_FIREWORKS,
            TOGETHER_FOREVER_PROJECTIONS
        ]
    },
    [DISNEY_CALIFORNIA_ADVENTURE]: {
        [AVENGERS_CAMPUS]: [
            AVENGERS_HEADQUARTERS,
            DR_STRANGE,
            WORLD_OF_COLOR,
            AVENGERS_ASSEMBLE,
            GUARDIANS_DANCE_OFF,
            WARRIORS_OF_WAKANDA,
            THE_AMAZING_SPIDERMAN
        ],
        [BUENA_VISTA_STREET]: [
            FIVE_AND_DIME,
            WORLD_OF_COLOR
        ],
        [CARS_LAND]: [
            WORLD_OF_COLOR
        ],
        [GRIZZLY_PEAK]: [
            WORLD_OF_COLOR
        ],
        [HOLLYWOOD_LAND]: [
            WORLD_OF_COLOR
        ],
        [PARADISE_GARDENS_PARK]: [
            WORLD_OF_COLOR,
            PARADISE_GARDEN_BANDSTAND
        ],
        [PIXAR_PIER]: [
            WORLD_OF_COLOR
        ],
        [SAN_FRANSOKYO_SQUARE]: [
            WORLD_OF_COLOR
        ]
    }
}