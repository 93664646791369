import { Divider, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { getBoardingGroupHistory, getLiveDataForPark, getWaitHistoryForPark } from '../../data';
import { BoardingGroupHistory, LiveData, LiveStatusType, WaitHistory} from '../../data/interfaces';
import { useAppSelector } from '../../redux/hooks';
import { onRefresh, selectSelectedPark } from '../../redux/parks';
import { ClosedAttractionComponent, DownAttractionComponent, OpenAttractionComponent, VirtualQueueAttractionComponent } from '../../components/Attractions';
import { Attraction, VirtualQueueAttraction } from '../../data/attractions';
import Header from '../../components/Header';
import { averageWaits } from './AverageWaits';


function WaitTimes() {
    const selectedPark = useAppSelector(selectSelectedPark);
    const refreshState = useAppSelector(onRefresh);

    const [attractions, setAttractions] = useState<LiveData[]>([]);
    const [waitHistory, setWaitHistory] = useState<{[id: string]: WaitHistory}>({});
    const [boardingGroupHistory, setBoardingGroupHistory] = useState<{[id: string]: BoardingGroupHistory}>({});
    const [selectedSort, setSelectedSort] = useState<string>(localStorage.getItem('waitTimeSort') ?? 'totalWait');

    const onSelectedSortChange = (value: string) => {
        localStorage.setItem('waitTimeSort', value);
        setSelectedSort(value);
    }

    useEffect(() => {
        const loadWaitTimesForPark = async () => {
            const waitTimes = await getLiveDataForPark(selectedPark.id);
            const allAttractions: LiveData[] = waitTimes.liveData
                .filter(data => Attraction.IsAnAttraction(data) || Attraction.IsAShowWithWaitTimes(data));
        
            setAttractions(allAttractions);
        }    

        if (refreshState.isHardRefresh) {
            setAttractions([]);
        }
        loadWaitTimesForPark();
    }, [selectedPark, refreshState]);

    useEffect(() => {    
        const loadWaitHistoryForPark = async () => {
            const newWaitHistory: {[id: string]: WaitHistory} = {};
            const waitHistoryResponse = await getWaitHistoryForPark(selectedPark.id);
            for (const history of waitHistoryResponse) {
                newWaitHistory[history.attractionId] = history;
            }
            setWaitHistory(newWaitHistory);
        }

        loadWaitHistoryForPark();
    }, [selectedPark, refreshState]);

    useEffect(() => {
        const loadBoardingGroupHistory = async () => {
            const newBoardingGroupHistory: {[id: string]: BoardingGroupHistory} = {};
            const boardingGroupHistoryResponse = await getBoardingGroupHistory(selectedPark.id);
            for (const history of boardingGroupHistoryResponse) {
                newBoardingGroupHistory[history.attractionId] = history;
            }
            setBoardingGroupHistory(newBoardingGroupHistory);    
        }
    
        loadBoardingGroupHistory();
    }, [selectedPark, refreshState]);

    let virtualQueues: VirtualQueueAttraction[] = [];
    let openAttractions: Attraction[] = [];
    let downAttractions: Attraction[] = [];
    let downAttractionsWithGeniePlus: Attraction[] = [];
    let closedAttractions: Attraction[] = [];
    for (const attraction of attractions) {
        const avgWaitForThisAttraction = averageWaits[selectedPark.id][attraction.id];
        const waitHistoryForThisAttraction = waitHistory[attraction.id];
        if (attraction.queue?.BOARDING_GROUP != null) { 
            const boardingGroupHistoryForThisAttraction = boardingGroupHistory[attraction.id] || [];
            virtualQueues.push(new VirtualQueueAttraction(attraction, boardingGroupHistoryForThisAttraction, waitHistoryForThisAttraction));
            continue;
        }

        if (attraction.status === LiveStatusType.Operating && attraction.queue != null) {
            openAttractions.push(new Attraction(attraction, waitHistoryForThisAttraction, avgWaitForThisAttraction));
            continue;
        }

        if (attraction.status === LiveStatusType.Down && attraction.queue?.RETURN_TIME != null) {
            downAttractionsWithGeniePlus.push(new Attraction(attraction, waitHistoryForThisAttraction, avgWaitForThisAttraction));
            continue;
        }

        if (attraction.status === LiveStatusType.Down) {
            downAttractions.push(new Attraction(attraction, waitHistoryForThisAttraction, avgWaitForThisAttraction));
            continue;
        }

        if (attraction.status === LiveStatusType.Closed) {
            closedAttractions.push(new Attraction(attraction, waitHistoryForThisAttraction, avgWaitForThisAttraction));
            continue;
        }
    }

    let sortFunc = Attraction.sortByWaitTime;
    switch (selectedSort) {
        case 'absoluteFromAverage':
            sortFunc = Attraction.sortByAbsoluteFromAverage;
            break;
        case 'percentFromAverage':
            sortFunc = Attraction.sortByPercentFromAverage;
            break;
    }

    openAttractions = openAttractions.sort(sortFunc);
    virtualQueues = virtualQueues.sort(sortFunc);

    const currentLocalTime = new Date();
    return (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper'
            }}
        >
            <ListItem>
                <ListItemText sx={{textAlign: 'center'}} secondaryTypographyProps={{component: 'span'}} secondary={<Header />} />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
            <FormControl fullWidth variant='filled'>
                <InputLabel id="sort-label">Sort by</InputLabel>
                <Select
                    labelId="sort-label"
                    value={selectedSort}
                    onChange={(event) => onSelectedSortChange(event.target.value)}
                >
                    <MenuItem value={'totalWait'}>Total wait</MenuItem>
                    <MenuItem value={'absoluteFromAverage'}>Absolute from average</MenuItem>
                    <MenuItem value={'percentFromAverage'}>Percent from average</MenuItem>
                </Select>
            </FormControl>
            {downAttractionsWithGeniePlus.map(attraction =>  
                (<DownAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}
            {downAttractions.map(attraction =>  
                (<DownAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}
            {openAttractions.map(attraction =>  
                (<OpenAttractionComponent 
                    key={attraction.name} 
                    attraction={attraction} 
                    currentLocalTime={currentLocalTime} 
                    shouldCalculatePercentFromAverage={selectedSort==='percentFromAverage'} />
            ))}
            {virtualQueues.map(attraction =>  
                (<VirtualQueueAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}
            <Divider variant="fullWidth" component="li" sx={{mt: '50px'}} />
            {closedAttractions.map(attraction =>  
                (<ClosedAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}
        </List>
    )
};


export default WaitTimes;