export const RADIATOR_SPRINGS_RACERS: string = 'c60c768b-3461-465c-8f4f-b44b087506fc';
export const MISSION_BREAKOUT: string = 'b7678dab-5544-48d5-8fdc-c1a0127cfbcd';
export const TOY_STORY_MIDWAY_MANIA: string = '86ab3069-110d-49c5-a7e7-29ddf28695a6';
export const WEBSLINGERS: string = '2295351d-ce6b-4c04-92d5-5b416372c5b5';
export const GRIZZLY_RIVER_RUN: string = 'b1d285a7-2444-4a7c-b7bb-d2d4d6428a85';
export const SOARIN: string = '77f205a4-d482-4d91-a5ff-71e54a086ad2';
export const MONSTERS_INC: string = '40524fba-5d84-49e7-9204-f493dbe2d5a4';
export const GOOFYS_SKY_SCHOOL: string = 'f44a5072-3cda-4c7c-8574-33ad09d16cca';
export const INCREDICOASTER: string = '5d07a2b1-49ca-4de7-9d32-6d08edf69b08';
export const LUIGIS_ROLLICKIN_ROADSTERS: string = '7a09a2f0-e226-4f3e-86f8-2598ab67ec44';
export const PIXAR_PAL_AROUND_SWINGING: string = '528016ef-db24-47fa-a0f2-b6d26d61e29f';
export const PIXAR_PAL_AROUND_NON_SWINGING: string = '4ca6cdbf-4c5f-45bf-b0dc-db83393ec208';
export const MATERS_JUNKYARD_JAMBOREE: string = '46097afe-a1ea-4807-93d3-14d14f36e55f';
export const INSIDE_OUT: string = '6d876f4c-c3ff-4ae3-a2d8-d4b831e1039b';
export const SYMPHONY_SWINGS: string = '4f5b28d0-b78e-482b-8e2e-1f90756d6220';
export const LITTLE_MERMAID: string = 'e1fbc7a1-2cd1-4282-b373-ac11d9d9d38a';
export const JUMPIN_JELLYFISH: string = 'c8a4b7b1-c1b2-4dfe-b73c-4e834b4a73db';
export const PHILHARMAGIC: string = '8f586a2f-cef5-46d3-b822-fd622c4e9e33';
export const GOLDEN_ZEPHYR: string = '10a5fc6f-5ad3-414b-9bdd-e6bae097b6ad';
export const CRITTER_CAROUSEL: string = '388ad3f1-5cf5-4a9d-8d0e-6dfb817d7822';