import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import { AttractionWithAnalytics } from "../../data/attractions";
import { OpenTimeHistory } from "../../data/interfaces";
import { StyledTableCell, StyledTableRow } from "../../components/Tables";

interface OpeningTimeHistoryProps {
    open: boolean;
    handleClose: () => void;
    attraction: AttractionWithAnalytics;
    openTimeHistory: OpenTimeHistory[]
}

function OpeningTimeHistory({open, handleClose, openTimeHistory, attraction}: OpeningTimeHistoryProps) {
  
    openTimeHistory.sort((a, b) => b.date.localeCompare(a.date));
 
    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{minWidth: '75%', height: '100%'}}
        fullScreen={true}
      >
        <DialogTitle id="alert-dialog-title">
          {attraction.name}
        </DialogTitle>
        <DialogContent>
          <TableContainer sx={{'mb': '10px'}}>
            <Table size="medium">
                <TableBody>
                  <TableRow key={0}>
                      <TableCell sx={{'width': '200px', 'borderBottom': 'none'}} component="th" scope="row">Avg min after open</TableCell>
                      <TableCell sx={{'borderBottom': 'none'}}>{attraction.averageOpeningTimeInMinutes}</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                      <TableCell sx={{'width': '200px', 'borderBottom': 'none'}} component="th" scope="row">Total days</TableCell>
                      <TableCell sx={{'borderBottom': 'none'}}>{attraction.numberOfHistoryRecords}</TableCell>
                  </TableRow>
                  <TableRow key={2}>
                      <TableCell sx={{'borderBottom': 'none'}} component="th" scope="row">% in 10 min</TableCell>
                      <TableCell sx={{'borderBottom': 'none'}}>{attraction.percentOpenWithin10Minutes}%</TableCell>
                  </TableRow>
                  <TableRow key={3}>
                      <TableCell sx={{'borderBottom': 'none'}} component="th" scope="row">% in 30 min</TableCell>
                      <TableCell sx={{'borderBottom': 'none'}}>{attraction.percentOpenWithin30Minutes}%</TableCell>
                  </TableRow>
                  <TableRow key={4}>
                      <TableCell sx={{'borderBottom': 'none'}} component="th" scope="row">% in 60 min</TableCell>
                      <TableCell sx={{'borderBottom': 'none'}}>{attraction.percentOpenWithin60Minutes}%</TableCell>
                  </TableRow>
                  <TableRow key={5}>
                      <TableCell sx={{'borderBottom': 'none'}} component="th" scope="row">% more than 60 min</TableCell>
                      <TableCell sx={{'borderBottom': 'none'}}>{attraction.percentOpenMoreThan60Minutes}%</TableCell>
                  </TableRow>
                </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
              <Table size="medium">
                  <TableHead>
                      <StyledTableRow>
                          <StyledTableCell>Date</StyledTableCell>
                          <StyledTableCell>Opening time</StyledTableCell>
                          <StyledTableCell>Expected opening time</StyledTableCell>
                      </StyledTableRow>
                  </TableHead>
                  <TableBody>
                      {openTimeHistory.map((oth) => {
                          return (
                              <StyledTableRow key={oth.date}>
                                  <StyledTableCell component="th" scope="row">{oth.date}</StyledTableCell>
                                  <StyledTableCell>{oth.time}</StyledTableCell>
                                  <StyledTableCell>{oth.operatingStartTime}</StyledTableCell>
                              </StyledTableRow>
                          )})
                      }
                  </TableBody>
              </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>);
};

export default OpeningTimeHistory;
