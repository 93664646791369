import { CANDY_CANE_INN, ESPLANADE, GRAND_CALIFORNIAN } from "../../../constants/lands/general";
import { DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE } from "../../../constants/parks";


export const parkPolygons = [
    {
        "name": DISNEYLAND,
        "border": [
            {
                "latitude": "33.8148825",
                "longitude": "-117.9234883"
            },
            {
                "latitude": "33.813795",
                "longitude": "-117.9240194"
            },
            {
                "latitude": "33.8130949",
                "longitude": "-117.9233057"
            },
            {
                "latitude": "33.8129612",
                "longitude": "-117.9235417"
            },
            {
                "latitude": "33.8120296",
                "longitude": "-117.9234934"
            },
            {
                "latitude": "33.8105008",
                "longitude": "-117.9223883"
            },
            {
                "latitude": "33.8102245",
                "longitude": "-117.921927"
            },
            {
                "latitude": "33.8102557",
                "longitude": "-117.9211921"
            },
            {
                "latitude": "33.8105187",
                "longitude": "-117.9208273"
            },
            {
                "latitude": "33.8099526",
                "longitude": "-117.9198671"
            },
            {
                "latitude": "33.8095678",
                "longitude": "-117.9191995"
            },
            {
                "latitude": "33.8095455",
                "longitude": "-117.9187596"
            },
            {
                "latitude": "33.8096926",
                "longitude": "-117.9184914"
            },
            {
                "latitude": "33.8100269",
                "longitude": "-117.9182446"
            },
            {
                "latitude": "33.8101636",
                "longitude": "-117.9171097"
            },
            {
                "latitude": "33.8119109",
                "longitude": "-117.9158866"
            },
            {
                "latitude": "33.8129717",
                "longitude": "-117.9156184"
            },
            {
                "latitude": "33.8141483",
                "longitude": "-117.9156292"
            },
            {
                "latitude": "33.8149034",
                "longitude": "-117.9170211"
            },
            {
                "latitude": "33.8156452",
                "longitude": "-117.9170081"
            },
            {
                "latitude": "33.8161083",
                "longitude": "-117.9176634"
            },
            {
                "latitude": "33.8161997",
                "longitude": "-117.9176956"
            },
            {
                "latitude": "33.8162153",
                "longitude": "-117.9193424"
            },
            {
                "latitude": "33.8153415",
                "longitude": "-117.9201677"
            },
            {
                "latitude": "33.815814",
                "longitude": "-117.9205218"
            },
            {
                "latitude": "33.8157783",
                "longitude": "-117.9218683"
            },
            {
                "latitude": "33.8148825",
                "longitude": "-117.9234883"
            }
        ]
    },

    {
        "name": DISNEY_CALIFORNIA_ADVENTURE,
        "border": [
            {
                "latitude": "33.8088208",
                "longitude": "-117.9187508"
            },
            {
                "latitude": "33.8088208",
                "longitude": "-117.9192041"
            },
            {
                "latitude": "33.8086938",
                "longitude": "-117.9194589"
            },
            {
                "latitude": "33.8086635",
                "longitude": "-117.920075"
            },
            {
                "latitude": "33.8085921",
                "longitude": "-117.9205095"
            },
            {
                "latitude": "33.8084228",
                "longitude": "-117.9206489"
            },
            {
                "latitude": "33.8082712",
                "longitude": "-117.9212444"
            },
            {
                "latitude": "33.8075001",
                "longitude": "-117.9211908"
            },
            {
                "latitude": "33.8073062",
                "longitude": "-117.9212846"
            },
            {
                "latitude": "33.8073017",
                "longitude": "-117.9216306"
            },
            {
                "latitude": "33.8070588",
                "longitude": "-117.9220437"
            },
            {
                "latitude": "33.806573",
                "longitude": "-117.921915"
            },
            {
                "latitude": "33.8064434",
                "longitude": "-117.9220753"
            },
            {
                "latitude": "33.8065771",
                "longitude": "-117.9227861"
            },
            {
                "latitude": "33.8065303",
                "longitude": "-117.9234298"
            },
            {
                "latitude": "33.8063074",
                "longitude": "-117.9236605"
            },
            {
                "latitude": "33.8058818",
                "longitude": "-117.9236525"
            },
            {
                "latitude": "33.8056344",
                "longitude": "-117.9236122"
            },
            {
                "latitude": "33.8054828",
                "longitude": "-117.9234003"
            },
            {
                "latitude": "33.8047606",
                "longitude": "-117.922689"
            },
            {
                "latitude": "33.8043936",
                "longitude": "-117.9221884"
            },
            {
                "latitude": "33.803979",
                "longitude": "-117.9220436"
            },
            {
                "latitude": "33.8038587",
                "longitude": "-117.9213677"
            },
            {
                "latitude": "33.8038664",
                "longitude": "-117.9200116"
            },
            {
                "latitude": "33.803853",
                "longitude": "-117.9185846"
            },
            {
                "latitude": "33.8053106",
                "longitude": "-117.9174098"
            },
            {
                "latitude": "33.8058554",
                "longitude": "-117.9175924"
            },
            {
                "latitude": "33.8059869",
                "longitude": "-117.9171257"
            },
            {
                "latitude": "33.8066154",
                "longitude": "-117.9163103"
            },
            {
                "latitude": "33.8073531",
                "longitude": "-117.9169567"
            },
            {
                "latitude": "33.8073508",
                "longitude": "-117.9162344"
            },
            {
                "latitude": "33.8081464",
                "longitude": "-117.9162398"
            },
            {
                "latitude": "33.8087348",
                "longitude": "-117.9165965"
            },
            {
                "latitude": "33.8087281",
                "longitude": "-117.9180744"
            },
            {
                "latitude": "33.8087138",
                "longitude": "-117.9182385"
            },
            {
                "latitude": "33.8087138",
                "longitude": "-117.9185336"
            },
            {
                "latitude": "33.8088208",
                "longitude": "-117.9187508"
            }
        ]
    },
    {
        "name": GRAND_CALIFORNIAN,
        "border": [
            {
                "latitude": "33.8083773",
                "longitude": "-117.9230143"
            },
            {
                "latitude": "33.8070245",
                "longitude": "-117.9230169"
            },
            {
                "latitude": "33.8070156",
                "longitude": "-117.9234381"
            },
            {
                "latitude": "33.8065811",
                "longitude": "-117.9234041"
            },
            {
                "latitude": "33.8066279",
                "longitude": "-117.9226155"
            },
            {
                "latitude": "33.8065588",
                "longitude": "-117.9220496"
            },
            {
                "latitude": "33.8070892",
                "longitude": "-117.9220737"
            },
            {
                "latitude": "33.8070588",
                "longitude": "-117.9220437"
            },
            {
                "latitude": "33.8073017",
                "longitude": "-117.9216306"
            },
            {
                "latitude": "33.8073062",
                "longitude": "-117.9212846"
            },
            {
                "latitude": "33.8075001",
                "longitude": "-117.9211908"
            },
            {
                "latitude": "33.8082712",
                "longitude": "-117.9212444"
            },
            {
                "latitude": "33.8083773",
                "longitude": "-117.9230143"
            }
        ]
    },
    {
        "name": ESPLANADE,
        "border": [
            {
                "latitude": "33.809817",
                "longitude": "-117.9196494"
            },
            {
                "latitude": "33.8098304",
                "longitude": "-117.9203092"
            },
            {
                "latitude": "33.8089189",
                "longitude": "-117.9202904"
            },
            {
                "latitude": "33.8086938",
                "longitude": "-117.9194589"
            },
            {
                "latitude": "33.8088208",
                "longitude": "-117.9192041"
            },
            {
                "latitude": "33.8088208",
                "longitude": "-117.9187508"
            },
            {
                "latitude": "33.8087138",
                "longitude": "-117.9185336"
            },
            {
                "latitude": "33.8087138",
                "longitude": "-117.9182385"
            },
            {
                "latitude": "33.8088475",
                "longitude": "-117.9178845"
            },
            {
                "latitude": "33.8097033",
                "longitude": "-117.9180052"
            },
            {
                "latitude": "33.8096926",
                "longitude": "-117.9184914"
            },
            {
                "latitude": "33.8095455",
                "longitude": "-117.9187596"
            },
            {
                "latitude": "33.8095678",
                "longitude": "-117.9191995"
            },
            {
                "latitude": "33.809817",
                "longitude": "-117.9196494"
            }
        ]
    },
    {
        "name": CANDY_CANE_INN,
        "border": [
            {
                "latitude": "33.8046312",
                "longitude": "-117.9175431"
            },
            {
                "latitude": "33.8039671",
                "longitude": "-117.9175431"
            },
            {
                "latitude": "33.8039604",
                "longitude": "-117.9156226"
            },
            {
                "latitude": "33.8039648",
                "longitude": "-117.9155046"
            },
            {
                "latitude": "33.8046401",
                "longitude": "-117.9155234"
            },
            {
                "latitude": "33.8046312",
                "longitude": "-117.9175431"
            }
        ]
    }
]