export const RISE_OF_THE_RESISTANCE: string = '34b1d70f-11c4-42df-935e-d5582c9f1a8e';
export const INDIANA_JONES: string = '2aedc657-1ee2-4545-a1ce-14753f28cc66';
export const SPACE_MOUNTAIN: string = '9167db1d-e5e7-46da-a07f-ae30a87bc4c4';
export const MATTERHORN: string = 'faaa8be9-cc1e-4535-ac20-04a535654bd0'
export const ROGER_RABBIT: string = '6ce9cdd1-0a43-459e-83cd-f4cace9cfa7b';
export const SMUGGLERS_RUN: string = 'b2c2549c-e9da-4fdd-98ea-1dcff596fed7';
export const BIG_THUNDER_MOUNTAIN: string = '0de1413a-73ee-46cf-af2e-c491cc7c7d3b';
export const RUNAWAY_RAILWAY: string = 'cd670bff-81d1-4f34-8676-7bafdf49220a';
export const PETER_PANS_FLIGHT: string = 'c23af6ba-8515-406a-8a48-d0818ba0bfc9';
export const GADGETCOASTER: string = '59647168-d239-4161-8b24-92eb128e96fb';
export const MICKEYS_HOUSE_MEET_MICKEY: string = '5a107a57-9174-4f3f-9be7-625a5d5bb899';
export const ALICE: string = 'a07f3110-013e-43bb-a182-e66bb8b5e28d';
export const HAUNTED_MANSION: string = 'ff52cb64-c1d5-4feb-9d43-5dbd429bac81';
export const AUTOPIA: string = '1da85181-bf0f-4ccc-b98e-243142f7347b';
export const ASTRO_ORBITER: string = '6c225598-91c9-44a3-95e2-7c423475db61';
export const DUMBO: string = 'cc980e8e-192f-48b6-848c-27784084e54b';
export const JUNGLE_CRUISE: string = '1b83fda8-d60e-48e4-9a3d-90ddcbcd1001';
export const PIRATES_OF_THE_CARIBBEAN: string = '82aeb29b-504a-416f-b13f-f41fa5b766aa';
export const BUZZ_LIGHTYEAR: string = '88197808-3c56-4198-a5a4-6066541251cf';
export const FINDING_NEMO: string = '64d44aaa-6857-4693-b24b-bcff6c6dcfa1';
export const SNOW_WHITE: string = '4f0053e7-b8db-4833-b02f-35e1c91b4523';
export const STAR_TOURS: string = 'cc718d11-fa15-44ee-87d0-ded989ad61bc';
export const MR_TOADS: string = '9d401ad3-49b2-469f-ac73-93eb429428fb';
export const PINOCCHIO: string = '90ee50d4-7cc9-4824-b29d-2aac801acc29';
export const STORYLAND_CANAL_BOATS: string = 'cb929138-d77a-4dd2-983c-f651bbd1bd92';
export const EXPLORER_CANOES: string = '5bd95ae8-181d-449c-8f04-a621e2448961';
export const ITS_A_SMALL_WORLD: string = '3638ac09-9fce-4a43-8c79-8ebbe17afce2';
export const DISNEYLAND_RAILROAD: string = 'e2d460e9-2bef-4613-b126-092ab7cb37e5';
export const CASEY_JR_CIRCUS_TRAIN: string = '8e686e4c-f3db-4d9c-a185-2d54b1fa8899';
export const MARK_TWAIN_RIVERBOAT: string = '6c30d5b0-8c0a-406f-9258-0b6c55d4a5e4';
export const WINNIE_THE_POOH: string = '52a8ef64-d54c-4974-883f-027c3026e3f1';
export const MAD_TEA_PARTY: string = 'e0cfed11-96d7-40f3-907f-5cfed172592a';
export const MONORAIL: string = '56d0bd6d-5106-4420-8f60-0005475c04c3';
export const KING_ARTHUR_CAROUSEL: string = 'f7904912-3f08-4563-b99e-fd59f43cc9f2';
export const PIRATES_LAYER_TOM_SAWYERS: string = '07952343-3498-404b-8337-734de9a185c1';