import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { onRefresh, selectSelectedPark } from '../../redux/parks';
import { getScheduleDataForPark } from '../../data';
import MagicKingdom from './MagicKingdom';
import Epcot from './Epcot';
import AnimalKingdom from './AnimalKingdom';
import HollywoodStudios from './HollywoodStudios';
import Disneyland from './Disneyland';
import { ParkSchedule, parseParkSchedule } from '../../data/schedule';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import CaliforniaAdventure from './CaliforniaAdventure';

type RopeDropParkSchedule = {
    todaysParkSchedule: ParkSchedule;
    tomorrowsParkSchedule: ParkSchedule
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'} >{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


function RopeDrop() {
    const selectedPark = useAppSelector(selectSelectedPark);
    const refreshState = useAppSelector(onRefresh);
    const [schedule, setSchedule] = useState<RopeDropParkSchedule>()

    const [openTabValue, setOpenTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, openTabValue: number) => {
        setOpenTabValue(openTabValue);
    };

    useEffect(() => {
        const loadWaitTimesForPark = async () => {
            const response = await getScheduleDataForPark(selectedPark.id);
            const todaysParkSchedule = parseParkSchedule(response);
            const tomorrowsParkSchedule = parseParkSchedule(response, 1);

            setSchedule({
                todaysParkSchedule,
                tomorrowsParkSchedule
            });
        }
        loadWaitTimesForPark();
    }, [selectedPark, refreshState]);

    if (schedule == null) {
        return <></>
    }
    let today = null;
    let tomorrow = null;
    switch (selectedPark.name) {
        case 'Magic Kingdom':
            today = <MagicKingdom parkSchedule={schedule.todaysParkSchedule}/>
            tomorrow = <MagicKingdom parkSchedule={schedule.tomorrowsParkSchedule}/>
            break;
        case 'Epcot':
            today = <Epcot parkSchedule={schedule.todaysParkSchedule}/>
            tomorrow = <Epcot parkSchedule={schedule.tomorrowsParkSchedule}/>
            break;
        case 'Animal Kingdom':
            today = <AnimalKingdom parkSchedule={schedule.todaysParkSchedule}/>
            tomorrow = <AnimalKingdom parkSchedule={schedule.tomorrowsParkSchedule}/>
            break;
        case 'Hollywood Studios':
            today = <HollywoodStudios parkSchedule={schedule.todaysParkSchedule}/>
            tomorrow = <HollywoodStudios parkSchedule={schedule.tomorrowsParkSchedule}/>
            break;
        case 'Disneyland': 
            today = <Disneyland parkSchedule={schedule.todaysParkSchedule}/>
            tomorrow = <Disneyland parkSchedule={schedule.tomorrowsParkSchedule}/>
            break;

        case 'Disney California Adventure':
            today = <CaliforniaAdventure parkSchedule={schedule.todaysParkSchedule}/>
            tomorrow = <CaliforniaAdventure parkSchedule={schedule.tomorrowsParkSchedule}/>
            break;
    }

    return (<>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={openTabValue} onChange={handleChange}>
                <Tab label={'Today'}  />
                <Tab label={'Tomorrow'} />
            </Tabs>
            </Box> 
            <CustomTabPanel value={openTabValue} index={0}>
                {today}
            </CustomTabPanel>
            <CustomTabPanel value={openTabValue} index={1}>
                {tomorrow}
            </CustomTabPanel>
        </>
    )

}

export default RopeDrop;
