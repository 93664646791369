import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { buildDefaultDisneylandItems, sortByDate } from './items';
import { ParkSchedule } from '../../data/schedule';
import { addMinutes, toCustomTimeString } from '../../data/time';

type CaliforniaAdventureProps = {
    parkSchedule: ParkSchedule
}

function CaliforniaAdventure({parkSchedule}: CaliforniaAdventureProps) {
    let defaultItems = buildDefaultDisneylandItems(parkSchedule);
    defaultItems = defaultItems.sort(sortByDate);

    let earlyEntry = <div>No early entry</div>;
    if (parkSchedule.earlyEntryParkOpen != null) {
        earlyEntry = <div>Early entry: {toCustomTimeString(parkSchedule.earlyEntryParkOpen)}</div> 
    }

    const firstGeniePlusRideTime = new Date();
    firstGeniePlusRideTime.setHours(7, 0, 0, 0);

    // don't bother displaying if not possible
    let earlyEntryRSR = null;
    if (parkSchedule.earlyEntryParkOpen != null) {
        earlyEntryRSR =  <React.Fragment>
            <ListItem>
                <ListItemText primary={ <Typography variant="h5">
                    Racers (EE)
                </Typography>} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.earlyEntryParkOpen) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -10))} 
                secondary={'Mission Breakout or Webslingers'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.earlyEntryParkOpen) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -10))} 
                secondary={'Maters, if time allows or Avengers 2x'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -10))} 
                secondary={'Line up for Radiator Springs Racers'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.normalParkOpen)} 
                secondary={'Radiator Springs Racers'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 15))} 
                secondary={'Maters/Luigis'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 50))} 
                secondary={'Pixar pier - Midway Mania -> Incredicoaster'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 90))} 
                secondary={'Pixar pal-round'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 150))} 
                secondary={'Aim for first genie plus ride'} />
            </ListItem>
        </React.Fragment>
    }


  return (
    <>
     <List sx={{ width: '100%' }}>
        <ListItem>
            <ListItemText sx={{textAlign: 'left'}} secondaryTypographyProps={{component: 'span'}} secondary={ <div>
                <Typography component={'span'}  sx={{ color:'black', display: 'inline', fontSize: '1.8em' }} variant="body2"> 
                    <div>{parkSchedule.dateString}</div>
                </Typography>
            </div>} />
        </ListItem>
        <ListItem>
            <ListItemText sx={{textAlign: 'left'}} secondaryTypographyProps={{component: 'span'}} secondary={ <div>
                <Typography component={'span'}  sx={{ color:'black', display: 'inline', fontSize: '1.3em' }} variant="body2"> 
                    {earlyEntry}
                    <div>Normal park hours: {toCustomTimeString(parkSchedule.normalParkOpen)} - {toCustomTimeString(parkSchedule.parkClose)}</div>
                </Typography>
            </div>} />
        </ListItem>
        <Divider />
        <ListItem>
            <ListItemText primary={ <Typography variant="h5">
                Before opening
            </Typography>} />
        </ListItem>
        {defaultItems.map((item, index) => {
            return (<ListItem alignItems="flex-start" key={index}>
                <ListItemText
                    primary={item.time}
                    secondary={
                        <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {item.text}
                        </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>)
        })}
         <ListItem>
            <ListItemText 
                primary={toCustomTimeString(firstGeniePlusRideTime)} 
                secondary={
                    <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {'Genie plus: Book Toy Story Midway Mania'}
                        </Typography>
                    </React.Fragment>
                } 
            />
        </ListItem>
        <Divider />
       
        {earlyEntryRSR}
    
        <ListItem>
            <ListItemText primary={ <Typography variant="h5">
                Pixar Pier (no EE)
            </Typography>} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(parkSchedule.normalParkOpen) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 30))} 
            secondary={'Midway Mania -> Incredicoaster'} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 30))} 
            secondary={'Other pixar pier rides'} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 150))} 
            secondary={'Aim for first genie plus ride'} />
        </ListItem>
      </List>
      </>
  );
}

export default CaliforniaAdventure;
