import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { IPark } from '../data/interfaces'
import { ANIMAL_KINGDOM, DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE, EPCOT, HOLLYWOOD_STUDIOS, MAGIC_KINGDOM, PARK_NAMES } from '../constants/parks'
import { getCurrentLocation } from '../data/location'

type RefreshState = {
    refreshedAt: string,
    isHardRefresh: boolean
}

interface ParksState {
    selectedPark: IPark;
    refreshState: RefreshState;
}

export const availableParks = [
    {name: PARK_NAMES[MAGIC_KINGDOM], id: MAGIC_KINGDOM},
    {name: PARK_NAMES[EPCOT], id: EPCOT},
    {name: PARK_NAMES[HOLLYWOOD_STUDIOS], id: HOLLYWOOD_STUDIOS},
    {name: PARK_NAMES[ANIMAL_KINGDOM], id: ANIMAL_KINGDOM},
    {name: PARK_NAMES[DISNEYLAND], id: DISNEYLAND},
    {name: PARK_NAMES[DISNEY_CALIFORNIA_ADVENTURE], id: DISNEY_CALIFORNIA_ADVENTURE}
] as IPark[]

let initialPark = availableParks[0];
const cachedSelectedItem = localStorage.getItem('selectedPark');
if (cachedSelectedItem != null) {
    initialPark = JSON.parse(cachedSelectedItem);
}

// Define the initial state using that type
const initialState = {
    selectedPark: initialPark,
    refreshState: {
        isHardRefresh: true,
        refreshedAt: new Date().toUTCString()
    }
} as ParksState;

export const parksSlice = createSlice({
  name: 'parks',
  initialState,
  reducers: {
    setSelectedPark: (state, action: PayloadAction<IPark>)  => {
        localStorage.setItem('selectedPark', JSON.stringify(action.payload));
        return {
            ...state,
            selectedPark: action.payload,
            refreshedAt: new Date().toUTCString()
        }
    },
    hardRefreshPage: (state, _:  PayloadAction<void>) => {
        return {
            ...state,
            refreshState: {
                isHardRefresh: true,
                refreshedAt: new Date().toUTCString()
            }
        }
    },
    refreshPage: (state, _: PayloadAction<void>) => {
        return {
            ...state,
            refreshState: {
                isHardRefresh: false,
                refreshedAt: new Date().toUTCString()
            }
        }
    }
  }
});

export const { setSelectedPark, refreshPage, hardRefreshPage } = parksSlice.actions;

export const selectSelectedPark = (state: RootState) => state.parks.selectedPark;
export const onRefresh = (state: RootState) => state.parks.refreshState;

export default parksSlice.reducer;