import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { buildDefaultDisneyWorldItems, sortByDate } from './items';
import { ParkSchedule } from '../../data/schedule';
import { toCustomTimeString } from '../../data/time';

type HollywoodStudiosProps = {
    parkSchedule: ParkSchedule
}

function HollywoodStudios({parkSchedule}: HollywoodStudiosProps) {
    let defaultItems = buildDefaultDisneyWorldItems(parkSchedule);
    defaultItems = defaultItems.sort(sortByDate);

  return (
    <>
     <List sx={{ width: '100%' }}>
        <ListItem>
            <ListItemText sx={{textAlign: 'left'}} secondaryTypographyProps={{component: 'span'}} secondary={ <div>
                <Typography component={'span'}  sx={{ color:'black', display: 'inline', fontSize: '1.1em' }} variant="body2"> 
                    <div>{parkSchedule.dateString}</div> 
                    <div>Early entry: {toCustomTimeString(parkSchedule.earlyEntryParkOpen)}</div>  
                    <div>Normal park hours: {toCustomTimeString(parkSchedule.normalParkOpen)} - {toCustomTimeString(parkSchedule.parkClose)}</div>
                    <div></div>
                </Typography>
            </div>} />
        </ListItem>
        {defaultItems.map((item, index) => {
            return (<ListItem alignItems="flex-start" key={index}>
                <ListItemText
                    primary={item.time}
                    secondary={
                        <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {item.text}
                        </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>)
        })}
        <Divider />
       
        <ListItem>
            <ListItemText primary={ <Typography variant="h5" gutterBottom>
                Rise of the resistance
            </Typography>} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(parkSchedule.earlyEntryParkOpen)} secondary={'Rise'} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(parkSchedule.earlyEntryParkOpen) + '-' + toCustomTimeString(parkSchedule.normalParkOpen)}
                secondary={'Runaway railway'} />
        </ListItem>
      </List>
      </>
  );
}

export default HollywoodStudios;
