import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { getLiveDataForPark } from '../../data';
import {EntityType} from '../../data/interfaces';
import { useAppSelector } from '../../redux/hooks';
import { onRefresh, selectSelectedPark } from '../../redux/parks';
import { Performance } from './Performance';
import { Attraction } from '../../data/attractions';
import Header from '../../components/Header';


function ShowTimes() {
    const selectedPark = useAppSelector(selectSelectedPark);
    const refreshState = useAppSelector(onRefresh);

    const [performances, setPerformances] = useState<Attraction[]>([]);
    const [performancesHaveBeenLoaded, setPerformancesHaveBeenLoaded] = useState<boolean>(false);

    useEffect(() => {
        const loadShowTimesForPark = async () => {
            const showTimes = await getLiveDataForPark(selectedPark.id);
    
            const showsThatArePerformances: Attraction[] = showTimes.liveData
                .filter(data => data.entityType === EntityType.Show)
                .map(data => new Attraction(data))
                .filter(attraction => attraction.isAPerformance());
        
            setPerformances(showsThatArePerformances);
            setPerformancesHaveBeenLoaded(true);
        }

        if (refreshState.isHardRefresh) { 
            setPerformancesHaveBeenLoaded(false);
            setPerformances([]);
        }
        loadShowTimesForPark();
    }, [selectedPark, refreshState]);

    let sortedPerformances = performances;
    sortedPerformances = sortedPerformances.sort(Attraction.sortByShowTime);

    let performancesHtml = null;
    if (sortedPerformances.length === 0 && performancesHaveBeenLoaded) {
        performancesHtml = <ListItem>
            <ListItemText primary={'No more shows'} />
        </ListItem>;
    }
    else {
        performancesHtml = sortedPerformances.map(performance =>  
            (<Performance key={performance.name} attraction={performance} />
        ));
    }

    return (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper'
            }}
        >
            <ListItem>
                <ListItemText sx={{textAlign: 'center'}} secondaryTypographyProps={{component: 'span'}} secondary={<Header />} />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
           {performancesHtml}
        </List>
    )
};


export default ShowTimes;