import { VirtualQueueAttraction } from "../../data/attractions";
import { toCustomTimeString } from "../../data/time";


const averageGroupsPerHour: {[id: string]: number} = {
    'e3549451-b284-453d-9c31-e3b1207abd79': 14, // guardians
    '5a43d1a7-ad53-4d25-abfe-25625f0da304': 13, // tron
    'ff52cb64-c1d5-4feb-9d43-5dbd429bac81': 22 // haunted mansion holiday
}

export class MyBoardingGroup {
    myBoardingGroupNumber: number;
    vqStart?: number;
    vqEnd?: number;
    attraction: VirtualQueueAttraction;

    constructor(myBoardingGroupNumber: string, attraction: VirtualQueueAttraction) {
        this.myBoardingGroupNumber = Number(myBoardingGroupNumber);
        this.vqStart = attraction.currentBoardingGroupStartNumber == null ? undefined : Number(attraction.currentBoardingGroupStartNumber);
        this.vqEnd = attraction.currentBoardingGroupEndNumber == null ? undefined : Number(attraction.currentBoardingGroupEndNumber);
        this.attraction = attraction;
    }

    hasPast = (): boolean => {
        if (this.vqStart == null) {
            return true;
        }
        return this.myBoardingGroupNumber <= this.vqStart;
    }

    isActive = (): boolean => {
        if (this.vqEnd == null || this.vqStart == null) {
            return false;
        }

        return this.myBoardingGroupNumber >= this.vqStart && this.myBoardingGroupNumber <= this.vqEnd;
    }

    isProjectedInNextThirtyMinutes = (currentLocalTime: Date): boolean => {
        if (this.vqEnd == null) {
            return false;
        }

        const diffInMinutes = this.getMinutesUntilProjectedReturnTime(currentLocalTime);
        if (diffInMinutes == null) {
            return false;
        }

        // sometimes the data hasn't updated, but we still want to know the VQ is coming soon
        return (diffInMinutes >= 0 && diffInMinutes <= 30) || (diffInMinutes < 0 && this.myBoardingGroupNumber > this.vqEnd);
    }

    getMinutesUntilProjectedReturnTime = (currentLocalTime: Date): number | null => {
        const returnTime = this.getProjectedReturnTime();
        if (returnTime == null) {
            return null;
        }

        const diffInMillis = returnTime.getTime() - currentLocalTime.getTime();

        const diffInSeconds = diffInMillis / 1000;
        const diffInMinutes = diffInSeconds / 60;
        return diffInMinutes;
    }

    getProjectedReturnTime = (): Date | null => {
        const bgHistory = this.attraction?.boardingGroupHistory?.boardingGroupHistory;
        if (bgHistory == null || bgHistory.length == 0) {
            return null;
        }

        const latestBoardingGroup = this.attraction.boardingGroupHistory.boardingGroupHistory[0];

        const latestBoardingGroupNum = Number(latestBoardingGroup.boardingGroupEnd);
        const groupsPerHourForThisAttraction: number = averageGroupsPerHour[this.attraction.id] || 10;
        const diff = this.myBoardingGroupNumber - latestBoardingGroupNum;
        const timeInMinutes = diff * 60 / groupsPerHourForThisAttraction;
        const returnTime = new Date(latestBoardingGroup.lastUpdated);
        returnTime.setMinutes(returnTime.getMinutes() + timeInMinutes);

        return returnTime;
    }

    buildEstimatedReturnTime = () => {
        if (this.isActive()) {
            return 'Boarding group is active';
        }

        const returnTime = this.getProjectedReturnTime();
        if (returnTime == null) {
            return null;
        }

        return 'Projected ' + toCustomTimeString(returnTime);
    }
}