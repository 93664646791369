import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { markGeniePlusComplete } from "../../data";
import { useAppDispatch } from "../../redux/hooks";
import { refreshPage } from "../../redux/parks";

interface MarkAttractionCompleteDialogProps {
  open: boolean;
  onClose: () => void;
  attractionName: string;
  attractionId: string;
  parkId: string;
}


function MarkAttractionCompleteDialog({open, onClose, attractionName, attractionId, parkId}: MarkAttractionCompleteDialogProps) { 
  const dispatch = useAppDispatch();

  const handleOk = async () => {
    await markGeniePlusComplete(attractionId, parkId);
    dispatch(refreshPage());
    onClose();
  };
  
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogContent dividers>
        Mark {attractionName} complete?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MarkAttractionCompleteDialog;