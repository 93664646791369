import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { buildDefaultDisneylandItems, sortByDate } from './items';
import { ParkSchedule } from '../../data/schedule';
import { addMinutes, toCustomTimeString } from '../../data/time';

type DisneylandProps = {
    parkSchedule: ParkSchedule
}

function Disneyland({parkSchedule}: DisneylandProps) {
    let defaultItems = buildDefaultDisneylandItems(parkSchedule);
    defaultItems = defaultItems.sort(sortByDate);

    let earlyEntry = <div>No early entry</div>;
    if (parkSchedule.earlyEntryParkOpen != null) {
        earlyEntry = <div>Early entry: {toCustomTimeString(parkSchedule.earlyEntryParkOpen)}</div> 
    }

    const firstGeniePlusRideTime = new Date();
    firstGeniePlusRideTime.setHours(7, 0, 0, 0);

    // don't bother displaying if not possible
    let fantasylandEEWithRise = null;
    let fantasylandEEWithoutRise = null;
    let thrillRideWithEE = null;
    if (parkSchedule.earlyEntryParkOpen != null) {
        fantasylandEEWithRise =  <React.Fragment>
             <ListItem>
                <ListItemText primary={ <Typography variant="h5">
                    Fantasyland (EE) w/ Rise
                </Typography>} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.earlyEntryParkOpen) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -10))} 
                secondary={'Peter Pan'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -10))} 
                secondary={'Line up for rise'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.normalParkOpen)} 
                secondary={'Rise of the resistance'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 60))} 
                secondary={'Jungle cruise or Fantasyland'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 150))} 
                secondary={'Aim for first genie plus ride'} />
            </ListItem>
        </React.Fragment>

        fantasylandEEWithoutRise =  <React.Fragment>
            <ListItem>
                <ListItemText primary={ <Typography variant="h5">
                    Fantasyland (EE) without Rise
                </Typography>} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.earlyEntryParkOpen) + " - " + toCustomTimeString(parkSchedule.normalParkOpen)} 
                secondary={'Peter Pan -> Alice'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.normalParkOpen) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 20))} 
                secondary={'Mr Toad -> Snow White -> Pinocchio'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 20))} 
                secondary={'Gadgetcoaster -> Runaway railway'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 60))} 
                secondary={'Jungle cruise'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 150))} 
                secondary={'Aim for first genie plus ride'} />
            </ListItem>
        </React.Fragment>

        thrillRideWithEE =  <React.Fragment>
            <ListItem>
                <ListItemText primary={ <Typography variant="h5">
                    Thrill Ride (EE)
                </Typography>} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.earlyEntryParkOpen) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -20))} 
                secondary={'Space mountain'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.earlyEntryParkOpen, 10)) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -10))} 
                secondary={'One Fantasyland ride, if time'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, -10))} 
                secondary={'Line up for rise'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(parkSchedule.normalParkOpen)} 
                secondary={'Rise of the resistance'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 150))} 
                secondary={'Aim for first genie plus ride'} />
            </ListItem>
        </React.Fragment>
    }


  return (
    <>
     <List sx={{ width: '100%' }}>
     <ListItem>
            <ListItemText sx={{textAlign: 'left'}} secondaryTypographyProps={{component: 'span'}} secondary={ <div>
                <Typography component={'span'}  sx={{ color:'black', display: 'inline', fontSize: '1.8em' }} variant="body2"> 
                    <div>{parkSchedule.dateString}</div>
                </Typography>
            </div>} />
        </ListItem>
        <ListItem>
            <ListItemText sx={{textAlign: 'left'}} secondaryTypographyProps={{component: 'span'}} secondary={ <div>
                <Typography component={'span'}  sx={{ color:'black', display: 'inline', fontSize: '1.3em' }} variant="body2"> 
                    {earlyEntry}
                    <div>Normal park hours: {toCustomTimeString(parkSchedule.normalParkOpen)} - {toCustomTimeString(parkSchedule.parkClose)}</div>
                </Typography>
            </div>} />
        </ListItem>
        <Divider />
        {defaultItems.map((item, index) => {
            return (<ListItem alignItems="flex-start" key={index}>
                <ListItemText
                    primary={item.time}
                    secondary={
                        <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {item.text}
                        </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>)
        })}
         <ListItem>
            <ListItemText 
                primary={toCustomTimeString(firstGeniePlusRideTime)} 
                secondary={
                    <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {'Genie plus: Book runaway railway or Indiana Jones'}
                        </Typography>
                    </React.Fragment>
                } 
            />
        </ListItem>
        <Divider />
       
        {fantasylandEEWithRise}
        {fantasylandEEWithoutRise}
        {thrillRideWithEE}
    
        <ListItem>
            <ListItemText primary={ <Typography variant="h5">
                Fantasyland (no EE)
            </Typography>} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(parkSchedule.normalParkOpen) + " - " + toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 30))} 
            secondary={'Alice -> Mr. Toad -> Snow white -> Pinocchio'} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 30))} 
            secondary={'Gadgetcoaster -> Runaway railway'} />
        </ListItem>
        <ListItem>
            <ListItemText primary={toCustomTimeString(addMinutes(parkSchedule.normalParkOpen, 150))} 
            secondary={'Aim for first genie plus ride'} />
        </ListItem>
      </List>
      </>
  );
}

export default Disneyland;
