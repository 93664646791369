import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { unmarkGeniePlusComplete } from "../../data";
import { useAppDispatch } from "../../redux/hooks";
import { refreshPage } from "../../redux/parks";

interface UnmarkAttractionCompleteDialog {
  open: boolean;
  onClose: () => void;
  attractionName: string;
  attractionId: string;
  parkId: string;
}


function UnmarkAttractionCompleteDialog({open, onClose, attractionName, attractionId, parkId}: UnmarkAttractionCompleteDialog) { 
  const dispatch = useAppDispatch();

  const handleOk = async () => {
    await unmarkGeniePlusComplete(attractionId, parkId);
    dispatch(refreshPage());
    onClose();
  };
  
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogContent dividers>
        Unmark {attractionName}?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnmarkAttractionCompleteDialog;