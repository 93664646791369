import ApiHandler, {ApiMethod } from './api'
import { BoardingGroupHistory, IActivePark, IGeniePlusCompleteAttraction, LiveEntityResponse, MyBoardingGroupEntry, OpenTimeHistory, ScheduleEntityResponse, Session, WaitHistory } from './interfaces';

export const backend = new ApiHandler('https://6v8pyvrdg8.execute-api.us-east-1.amazonaws.com/prod', true);
export const unauthorized = new ApiHandler('https://6v8pyvrdg8.execute-api.us-east-1.amazonaws.com/prod', false);

type LocalStorageItem<T> = {
    item: T,
    expiry: number
}

const eightHoursInMilliseconds = 28800000;

const cacheResponse = <T>(key: string, response: T, ttl: number = eightHoursInMilliseconds) => {
    const now = new Date()
    const tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    let expiry = now.getTime() + ttl;
    if (tomorrow.getTime() < expiry) {
        // the cache should hard expire the next day regardless of when we cache it
        expiry = tomorrow.getTime();
    }

    const item: LocalStorageItem<T> = {
        item: response,
        expiry: expiry,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const getFromCache = <T>(key: string) : T | null => {
    const itemAsString = localStorage.getItem(key)
	if (itemAsString == null) {
		return null
	}
	const localStorageItem = JSON.parse(itemAsString)
	const now = new Date()
	if (now.getTime() > localStorageItem.expiry) {
		localStorage.removeItem(key)
		return null
	}
	return localStorageItem.item;
}


export const login = (idToken: string): Promise<Session> => {
    const body: any = {
        idToken: idToken
    };

    return unauthorized.getResource<Session>(ApiMethod.POST, 'login', body)
}

export const setActivePark = (parkId: string) => {
    const body: any = {
        parkId: parkId
    }

    return backend.getResource<any>(ApiMethod.POST, "active-park",  body)
}

export const getActivePark = () => {
    return backend.getResource<IActivePark>(ApiMethod.GET, "active-park");
}

export const saveSelloutAlertOffset = (offset: string) => {
    const body: any = {
        offset: offset
    }

    return backend.getResource<any>(ApiMethod.POST, "alerts/sellout/offset",  body)
}

export const getSelloutAlertOffset = () => {
    return backend.getResource<string>(ApiMethod.GET, "alerts/sellout/offset");
}

export const getLiveDataForPark = (parkId: string, shouldIncludeRelatedParks?: boolean) => {
    if (shouldIncludeRelatedParks == null) {
        return backend.getResource<LiveEntityResponse>(ApiMethod.GET, `entity/${parkId}/live`);
    }
    return backend.getResource<LiveEntityResponse>(
        ApiMethod.GET, 
        `entity/${parkId}/live`, 
        null,
        [{key: 'should-include-related-parks', value: 'true'}]
    );
}

export const getOpenTimeHistory = () => {
    return backend.getResource<OpenTimeHistory[]>(ApiMethod.GET, `analytics`)
}

export const getScheduleDataForPark = async (parkId: string) : Promise<ScheduleEntityResponse> => {
    const cacheKey: string = `entity/${parkId}/schedule`;
    const item = getFromCache<ScheduleEntityResponse>(cacheKey);
    if (item != null) {
        return item;
    }

    const response = await backend.getResource<ScheduleEntityResponse>(ApiMethod.GET, `entity/${parkId}/schedule`)
    cacheResponse(cacheKey, response);
    return response;
}

export const getWaitHistoryForPark = (parkId: string, shouldIncludeRelatedParks?: boolean) => { 
    if (shouldIncludeRelatedParks == null) {
        return backend.getResource<WaitHistory[]>(ApiMethod.GET, `waits/${parkId}/history`);
    }
    return backend.getResource<WaitHistory[]>(
        ApiMethod.GET, 
        `waits/${parkId}/history`, 
        null,
        [{key: 'should-include-related-parks', value: 'true'}]
    );
}

export const getBoardingGroupHistory = (parkId: string) => {
    return backend.getResource<BoardingGroupHistory[]>(ApiMethod.GET, `boarding-group/${parkId}/history`)
}

export const markGeniePlusComplete = (attractionId: string, parkId: string) => {
    const body: any = {
        attractionId: attractionId,
        parkId: parkId
    }

    return backend.getResource<any>(ApiMethod.POST, 'genie/complete',  body)
}

export const unmarkGeniePlusComplete = (attractonId: string, parkId: string) => {
    const body: any = {
        attractionId: attractonId,
        parkId: parkId
    }

    return backend.getResource<any>(ApiMethod.DELETE, 'genie/complete',  body)
}

export const getGeniePlusCompleteAttractions = (parkId: string) => {
    return backend.getResource<IGeniePlusCompleteAttraction[]>(ApiMethod.GET, `genie/complete/${parkId}`);
}

export const setMyBoardingGroup = (attractionId: string, parkId: string, boardingGroupNumber: string) => {
    const body: any = {
        attractionId: attractionId,
        parkId: parkId,
        boardingGroupNumber: boardingGroupNumber
    }

    return backend.getResource<any>(ApiMethod.POST, 'my-boarding-group',  body)
}

export const getMyBoardingGroups = (parkId: string) => {
    return backend.getResource<MyBoardingGroupEntry[]>(ApiMethod.GET, `my-boarding-group/${parkId}`);
}
