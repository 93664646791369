import React from "react";
import { Attraction, VirtualQueueAttraction } from "../data/attractions";
import { Avatar, Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import ErrorOutline from '@mui/icons-material/Error';
import { pink } from "@mui/material/colors";
import { calculateTimeDiff } from "../data/time";
import WaitTimeHistory from "../pages/WaitTimes/WaitTimeHistory";
import BoardingGroupHistory from "./BoardingGroupHistory";
import { MyBoardingGroup } from "../pages/Dashboard/boardingGroup";

export interface VirtualQueueAttractionProps {
    attraction: VirtualQueueAttraction;
    currentLocalTime: Date;
}

export const VirtualQueueAttractionComponent = ({attraction, currentLocalTime}: VirtualQueueAttractionProps) => {
    let boardingGroup = (<div>No more boarding groups</div>)
    if (attraction.currentBoardingGroupStartNumber != null) {
        boardingGroup = (<div>Boarding group {attraction.currentBoardingGroupStartNumber} - {attraction.currentBoardingGroupEndNumber}</div>);
    }

    let secondaryAction = null;
    if (attraction.individualLightningLaneCost) {
        secondaryAction =  (<ListItemAvatar sx={{ fontSize: '.85em', color: '#505050'  }}>
            <Avatar sx={{ bgcolor: pink[500], width: 56, height: 49 }}>${Math.trunc(attraction.individualLightningLaneCost/100)}</Avatar>
        </ListItemAvatar>)
    }

    const [boardingGroupHistoryOpen, setBoardingGroupHistoryOpen] = React.useState(false);
    const onAttractionClick = () => {
        setBoardingGroupHistoryOpen(true);
    }
    let boardingGroupHistory = <></>
    if (boardingGroupHistoryOpen) {
        boardingGroupHistory = <BoardingGroupHistory attraction={attraction} open={boardingGroupHistoryOpen} handleClose={() => setBoardingGroupHistoryOpen(false)} />;
    }

    return (<React.Fragment key={attraction.name}>
        <ListItem onClick={onAttractionClick} secondaryAction={secondaryAction}>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: pink[500] }}>{attraction.waitTime}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={attraction.name} secondary={
                <Typography component="span" sx={{ display: 'inline', fontSize: '.85em', color: '#505050'  }}>
                    <div>Updated {calculateTimeDiff(currentLocalTime, attraction.lastUpdated)}</div>
                    {boardingGroup}
                </Typography>
            } />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
        {boardingGroupHistory}
    </React.Fragment>);
};

export interface OpenAttractionProps {
    attraction: Attraction;
    currentLocalTime: Date;
    shouldCalculatePercentFromAverage: boolean
}

export const OpenAttractionComponent = ({attraction, currentLocalTime, shouldCalculatePercentFromAverage}: OpenAttractionProps) => {
    let secondaryAction = null;
    if (attraction.returnTime !== null) {
        secondaryAction =  (<Typography sx={{ fontSize: '.85em', color: '#505050'  }}>
            {attraction.returnTime}
        </Typography>)
    }

    if (attraction.individualLightningLaneCost) {
        secondaryAction =  (<ListItemAvatar sx={{ fontSize: '.85em', color: '#505050'  }}>
            <Avatar sx={{ bgcolor: pink[500], width: 56, height: 49 }}>${Math.trunc(attraction.individualLightningLaneCost/100)}</Avatar>
        </ListItemAvatar>)
    }

    let lastUpdatedText = <>
        Updated {calculateTimeDiff(currentLocalTime, attraction.lastUpdated)}
    </>
    const timeSinceComingBackUpString = attraction.buildTimeSinceComingBackUpString();
    if (timeSinceComingBackUpString !== '') {
        lastUpdatedText = <Typography component="span" sx={{ display: 'inline', color: 'red', fontWeight: 'bold'  }}>{
            timeSinceComingBackUpString}
        </Typography>
    }

    const [waitHistoryOpen, setWaitHistoryOpen] = React.useState(false);
    const onAttractionClick = () => {
        setWaitHistoryOpen(true);
    }
    let waitHistory = <></>
    if (waitHistoryOpen) {
        waitHistory = <WaitTimeHistory attraction={attraction} open={waitHistoryOpen} handleClose={() => setWaitHistoryOpen(false)} />;
    }

    let diffFromAverageText = <></>;

    let diffFromAverage = attraction.getDistanceFromAverage();
    if (shouldCalculatePercentFromAverage) {
        diffFromAverage = attraction.getPercentFromAverage();
    }
    if (diffFromAverage != null ) {
        if (diffFromAverage > 0) {
            diffFromAverageText = <Typography component="div" sx={{ color: 'red', mt: '3px', fontSize: '.8em', textAlign: 'center', width: '40px'  }}>
                +{diffFromAverage.toFixed(0)}{shouldCalculatePercentFromAverage ? '%' : null}
            </Typography>
        } else {
            diffFromAverageText = <Typography component="div" sx={{ color: 'green', mt: '3px', fontSize: '.8em', textAlign: 'center', width: '40px'  }}>
                {diffFromAverage.toFixed(0)}{shouldCalculatePercentFromAverage ? '%' : null}
            </Typography>
        }
    }

    return (<React.Fragment key={attraction.name}>
        <ListItem onClick={onAttractionClick} sx={{bgcolor: attraction.isGeniePlus ? '#F5F5F5' : ''}} secondaryAction={secondaryAction}>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: pink[500] }}>{attraction.waitTime}</Avatar>
                {diffFromAverageText}
            </ListItemAvatar>
            <ListItemText primary={<>
                {attraction.name}
            </>} secondary={
                <Typography component="span" sx={{ display: 'inline', fontSize: '.85em', color: '#505050'  }}>
                    {lastUpdatedText}
                    <div>{attraction.buildWaitTimeHistoryString()}</div>
                </Typography>
            } />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
        {waitHistory}
    </React.Fragment>);
};

export interface ClosedAttractionProps {
    attraction: Attraction;
    currentLocalTime: Date;
}

export const ClosedAttractionComponent = ({attraction, currentLocalTime}: ClosedAttractionProps) => {
    const [waitHistoryOpen, setWaitHistoryOpen] = React.useState(false);
    const onAttractionClick = () => {
        setWaitHistoryOpen(true);
    }
    let waitHistory = <></>
    if (waitHistoryOpen) {
        waitHistory = <WaitTimeHistory attraction={attraction} open={waitHistoryOpen} handleClose={() => setWaitHistoryOpen(false)} />;
    }

    return (<React.Fragment key={attraction.name}>
        <Box sx={{ marginLeft: 7 }}>
            <ListItem onClick={onAttractionClick}>
                <ListItemText primary={attraction.name + ' [CLOSED]'} secondary={
                    <Typography component="span" sx={{ display: 'inline', fontSize: '.85em', color: '#505050'  }}>
                        Updated {calculateTimeDiff(currentLocalTime, attraction.lastUpdated)}
                    </Typography>
                } />
            </ListItem>
        </Box>
        <Divider variant="fullWidth" component="li" />
        {waitHistory}
    </React.Fragment>);
};

export interface DownAttractionProps {
    attraction: Attraction;
    currentLocalTime: Date;
}

export const DownAttractionComponent = ({attraction, currentLocalTime}: DownAttractionProps) => {
    let secondaryAction = null;
    if (attraction.returnTime !== null) {
        secondaryAction =  (<Typography sx={{ fontSize: '.85em', color: '#505050'  }}>
            {attraction.returnTime}
        </Typography>)
    }

    const [waitHistoryOpen, setWaitHistoryOpen] = React.useState(false);
    const onAttractionClick = () => {
        setWaitHistoryOpen(true);
    }
    let waitHistory = <></>
    if (waitHistoryOpen) {
        waitHistory = <WaitTimeHistory attraction={attraction} open={waitHistoryOpen} handleClose={() => setWaitHistoryOpen(false)} />;
    }

    return (<React.Fragment key={attraction.name}>
        <Box>
            <ListItem onClick={onAttractionClick} sx={{bgcolor: attraction.isGeniePlus ? '#F5F5F5' : ''}} secondaryAction={secondaryAction}>
                <ListItemAvatar>
                    <ErrorOutline sx={{height: 45, width: 45, color: 'black'}}/>
                </ListItemAvatar>
                <ListItemText primary={attraction.name + attraction.tier + ' [DOWN]'} secondary={
                        <Typography component="span" sx={{ display: 'inline', fontSize: '.85em', color: '#505050'  }}>
                            <div>{attraction.buildDownSinceString(currentLocalTime)}</div>
                        </Typography>
                    } />
            </ListItem>
        </Box>
        <Divider variant="fullWidth" component="li" />
        {waitHistory}
    </React.Fragment>);
};


export interface MyBoardingGroupAttractionComponentProps {
    myBoardingGroup: MyBoardingGroup;
    currentLocalTime: Date;
}

export const MyBoardingGroupAttractionComponent = ({myBoardingGroup, currentLocalTime}: MyBoardingGroupAttractionComponentProps) => {
    const attraction: VirtualQueueAttraction = myBoardingGroup.attraction;
    let boardingGroupNumberHtml = (<div>No more boarding groups</div>)
    if (attraction.currentBoardingGroupStartNumber != null) {
        boardingGroupNumberHtml = (<div>Active boarding groups: {attraction.currentBoardingGroupStartNumber} - {attraction.currentBoardingGroupEndNumber}</div>);
    }

    const [boardingGroupHistoryOpen, setBoardingGroupHistoryOpen] = React.useState(false);
    const onAttractionClick = () => {
        setBoardingGroupHistoryOpen(true);
    }
    let boardingGroupHistory = <></>
    if (boardingGroupHistoryOpen) {
        boardingGroupHistory = <BoardingGroupHistory 
            attraction={attraction} 
            open={boardingGroupHistoryOpen} 
            handleClose={() => setBoardingGroupHistoryOpen(false)}
            existingBoardingGroupNumber={myBoardingGroup.myBoardingGroupNumber.toString()} />;
    }

    let color = '#FFFFFF';
    if (myBoardingGroup.isActive()) {
        color = '#a2cf6e'
    }
    else if (myBoardingGroup.isProjectedInNextThirtyMinutes(currentLocalTime)) {
        color = '#ffcd38'
    }

    return (<React.Fragment key={attraction.name}>
        <ListItem onClick={onAttractionClick} sx={{bgcolor: color}}>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: pink[500] }}>{myBoardingGroup.myBoardingGroupNumber}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={attraction.name} secondary={
                <Typography component="span" sx={{ display: 'inline', fontSize: '.85em', color: '#505050'  }}>
                    <div>Updated {calculateTimeDiff(currentLocalTime, attraction.lastUpdated)}</div>
                    {boardingGroupNumberHtml}
                    {myBoardingGroup.buildEstimatedReturnTime()}
                </Typography>
            } />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
        {boardingGroupHistory}
    </React.Fragment>);
};