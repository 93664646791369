import { ANIMAL_KINGDOM, DISNEY_CALIFORNIA_ADVENTURE, DISNEYLAND, EPCOT, HOLLYWOOD_STUDIOS, MAGIC_KINGDOM } from "../../constants/parks";

export const averageWaits: {[parkId: string] : {[attractionId: string] : number}} = {
    [MAGIC_KINGDOM]: { // MK
        "86a41273-5f15-4b54-93b6-829f140e5161": 54, // Peter pan
        "e76c93df-31af-49a5-8e2f-752c76c937c9": 29, // enchanted tales with belle
        "012a211b-4c91-451c-8a0e-5e3ab398eda8": 27, // meet ariel
        
        "796b0a25-c51e-456e-9bb8-50a324e301b3": 35, // Jungle cruise
        "de3309ca-97d5-4211-bffe-739fed47e92f": 35, // big thunder mountain
        "2551a77d-023f-4ab1-9a19-8afec0190f39": 28, // haunted mansion
        "40737d3d-0ff6-4a9e-a050-beb87bf90120": 30, // cindarella and a princess
        "a2d92647-634d-4eb4-886b-9da858e871f1": 34, // meet mickey
        "cf4b2ba4-3626-4de7-9d07-abe8a65b1665": 34, // meet tiana
        "b2260923-9315-40fd-9c6b-44dd811dbe64": 54, // space mountain
        "352feb94-e52e-45eb-9c92-e4b44c6b1a9d": 25, // pirates
        "0d94ad60-72f0-4551-83a6-ebaecdd89737": 26, // winnie the pooh

        "f5aad2d4-a419-4384-bd9a-42f86385c750": 19, // small world
        "72c7343a-f7fb-4f66-95df-c91016de7338": 29, // buzz lightyear
        "890fa430-89c0-4a3f-96c9-11597888005e": 20, // dumbo
        "0aae716c-af13-4439-b638-d75fb1649df3": 11, // mad tea party
        "7c5e1e02-3a44-4151-9005-44066d5ba1da": 10, // philharmagic
        "e8f0b426-7645-4ea3-8b41-b94ae7091a41": 15, // monsters inc
        "924a3b2c-6b4b-49e5-99d3-e9dc3f2e8a48": 18, // barnstormer
        "96455de6-f4f1-403c-9391-bf8396979149": 16, // aladdin
        "f163ddcd-43e1-488d-8276-2381c1db0a39": 27, // tomorrowland speedway
        "3cba0cb4-e2a6-402c-93ee-c11ffcb127ef": 20, // under the sea

        "d9d12438-d999-4482-894b-8955fdb20ccf": 24, // astro orbiter
        "273ddb8d-e7b5-4e34-8657-1113f49262a5": 7, // prince charming carrousel
        "9d4d5229-7142-44b6-b4fb-528920969a2c": 75, // seven dwarves mine train
        "ffcfeaa2-1416-4920-a1ed-543c1a1695c4": 9, // peoplemover
        "6fd1e225-53a0-4a80-a577-4bbc9a471075": 5 // tiki room
    },
    [EPCOT]: {  // Epcot
        "1e735ffb-4868-47f1-b2cd-2ac1156cd5f0": 69, // remy
        "8d7ccdb1-a22b-4e26-8dc8-65b1938ed5f0": 64, // frozen
        "37ae57c5-feaf-4e47-8f27-4b385be200f0": 62, // test track

        "81b15dfd-cf6a-466f-be59-3dd65d2a2807": 42, // soarin
        "5b6475ad-4e9a-4793-b841-501aa382c9c0": 22, // mission space

        "480fde8f-fe58-4bfb-b3ab-052a39d4db7c": 17, // spaceship earth
        "fb076275-0570-4d62-b2a9-4d6515130fa3": 13, // the seas with nemo and friends
        "35ed719b-f7f0-488f-8346-4fbf8055d373": 8, // short film festival
        "75449e85-c410-4cef-a368-9d2ea5d52b58": 13, // imagination with figment
        "8f353879-d6ac-4211-9352-4029efb47c18": 17, // living with the land
        "57acb522-a6fc-4aa4-a80e-21f21f317250": 12, // turtle talk with crush
    },
    [HOLLYWOOD_STUDIOS]: { // HS
        "399aa0a1-98e2-4d2b-b297-2b451e9665e1": 77, // slinky dog
        "34c4916b-989b-4ff1-a7e3-a6a846a3484f": 40, // smugglers run

        "6e118e37-5002-408d-9d88-0b5d9cdb5d14": 47, // runaway railway
        "d56506e2-6ad3-443a-8065-fea37987248d": 24, // alien swirling saucers
        "e516f303-e82d-4fd3-8fbf-8e6ab624cf89": 57, // rock n roller coaster
        "6f6998e8-a629-412c-b964-2cb06af8e26b": 50, // tower of terror
        "20b5daa8-e1ea-436f-830c-2d7d18d929b5": 41, // toy story mania

        "76dfa347-94bb-4552-b183-a08490c54acc": 8, // muppet vision
        "27f9fc86-2341-4bf4-8cbf-67fc16a841f1": 18, // meet olaf
        "3b290419-8ca2-44bc-a710-a6c83fca76ec": 10, // star tours

        "1a2e70d9-50d5-4140-b69e-799e950f7d18": 86, // rise of the resistance
        "5262e79e-8553-4ec0-a832-3177d377136d": 14 // lightning mcqueen's racing
    },
    [ANIMAL_KINGDOM]: { // AK
        "32e01181-9a5f-4936-8a77-0dace1de836c": 34, // killamanjaro's safari
        "7a5af3b7-9bc1-4962-92d0-3ea9c9ce35f0": 75, // navi river journey

        "55c531b6-3ce2-4c47-a8a1-0dc9107d825b": 25, // dinosaur
        "64a6915f-a835-4226-ba5c-8389fc4cade3": 30, // expedition everest
        "d58d9262-ec95-4161-80a0-07ca43b2f5f3": 33, // kali river rapids

        "ba44245e-5562-4359-be27-9dfb2d96cb2d": 10, // tough to be a bug
        "13d7c9d1-a785-403d-9ba8-71fee2f02d55": 23, // disney pals at outpost

        "24cf863c-b6ba-4826-a056-0b698989cbf7": 132, // flight of passage
    },
    [DISNEYLAND]: { // DL, using august avg
        '34b1d70f-11c4-42df-935e-d5582c9f1a8e': 60, // rise
        '2aedc657-1ee2-4545-a1ce-14753f28cc66': 53, // indiana jones
        '9167db1d-e5e7-46da-a07f-ae30a87bc4c4': 52, // space mountain
        'faaa8be9-cc1e-4535-ac20-04a535654bd0': 52, // matterhorn
        '6ce9cdd1-0a43-459e-83cd-f4cace9cfa7b': 47, // roger rabbit
        'b2c2549c-e9da-4fdd-98ea-1dcff596fed7': 45, // smugglers run
        '0de1413a-73ee-46cf-af2e-c491cc7c7d3b': 37, // big thunder
        'cd670bff-81d1-4f34-8676-7bafdf49220a': 37, // runaway railway
        'c23af6ba-8515-406a-8a48-d0818ba0bfc9': 33, // peter pan's flight
        '59647168-d239-4161-8b24-92eb128e96fb': 31, // gadgetcoaster
        '5a107a57-9174-4f3f-9be7-625a5d5bb899': 30, // mickeys house, meet mickey
        'a07f3110-013e-43bb-a182-e66bb8b5e28d': 29, // alice
        'ff52cb64-c1d5-4feb-9d43-5dbd429bac81': 28, // haunted mansion
        '1da85181-bf0f-4ccc-b98e-243142f7347b': 28, // autopia
        '6c225598-91c9-44a3-95e2-7c423475db61': 26, // astro orbiter
        'cc980e8e-192f-48b6-848c-27784084e54b': 25, // dumbo
        '1b83fda8-d60e-48e4-9a3d-90ddcbcd1001': 24, // jungle cruise
        '82aeb29b-504a-416f-b13f-f41fa5b766aa': 23, // pirates of the carib
        '88197808-3c56-4198-a5a4-6066541251cf': 23, // buzz
        '64d44aaa-6857-4693-b24b-bcff6c6dcfa1': 23, // finding nemo
        '4f0053e7-b8db-4833-b02f-35e1c91b4523': 19, // snow white
        'cc718d11-fa15-44ee-87d0-ded989ad61bc': 19, // star tours
        '9d401ad3-49b2-469f-ac73-93eb429428fb': 15, // mr toads
        '90ee50d4-7cc9-4824-b29d-2aac801acc29': 15, // pinocchio
        'cb929138-d77a-4dd2-983c-f651bbd1bd92': 14, // canal boats
        '5bd95ae8-181d-449c-8f04-a621e2448961': 13, // explorer canoes
        '3638ac09-9fce-4a43-8c79-8ebbe17afce2': 12, // small world
        'e2d460e9-2bef-4613-b126-092ab7cb37e5': 12, // railroad
        '8e686e4c-f3db-4d9c-a185-2d54b1fa8899': 10, // casey jr
        '6c30d5b0-8c0a-406f-9258-0b6c55d4a5e4': 10, // mark twain riverboat
        '52a8ef64-d54c-4974-883f-027c3026e3f1': 9, // winnie the pooh
        'e0cfed11-96d7-40f3-907f-5cfed172592a': 8, // mad tea party
        '56d0bd6d-5106-4420-8f60-0005475c04c3': 8, // monorail
        'f7904912-3f08-4563-b99e-fd59f43cc9f2': 7, // king arthur carousel
        '07952343-3498-404b-8337-734de9a185c1': 5 // pirate's layer tom sawyers
    },
    [DISNEY_CALIFORNIA_ADVENTURE]: { // DCA
        'c60c768b-3461-465c-8f4f-b44b087506fc': 73, // RSR
        'b7678dab-5544-48d5-8fdc-c1a0127cfbcd': 51, // mission breakout
        '86ab3069-110d-49c5-a7e7-29ddf28695a6': 48, // midway mania
        '2295351d-ce6b-4c04-92d5-5b416372c5b5': 47, // webslingers
        'b1d285a7-2444-4a7c-b7bb-d2d4d6428a85': 46, // grizzly river run
        '77f205a4-d482-4d91-a5ff-71e54a086ad2': 43, // soarin
        '40524fba-5d84-49e7-9204-f493dbe2d5a4': 40, // monsters inc
        'f44a5072-3cda-4c7c-8574-33ad09d16cca': 38, // goofy's sky school
        '5d07a2b1-49ca-4de7-9d32-6d08edf69b08': 32, // incredicoaster
        '7a09a2f0-e226-4f3e-86f8-2598ab67ec44': 26, // luigi's
        '528016ef-db24-47fa-a0f2-b6d26d61e29f': 26, // pal-a-round swinging
        '4ca6cdbf-4c5f-45bf-b0dc-db83393ec208': 23, // pal-a-round non-swinging
        '46097afe-a1ea-4807-93d3-14d14f36e55f': 16, // mater's
        '6d876f4c-c3ff-4ae3-a2d8-d4b831e1039b': 16, // inside out
        '4f5b28d0-b78e-482b-8e2e-1f90756d6220': 13, // symphony swings
        'e1fbc7a1-2cd1-4282-b373-ac11d9d9d38a': 13, // little mermaid
        'c8a4b7b1-c1b2-4dfe-b73c-4e834b4a73db': 11, // jumpin jellyfish
        '8f586a2f-cef5-46d3-b822-fd622c4e9e33': 9, // philharmagic
        '10a5fc6f-5ad3-414b-9bdd-e6bae097b6ad': 8, // golden zephyr
        '388ad3f1-5cf5-4a9d-8d0e-6dfb817d7822': 5, // critter carousel
    }
}