import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, Container, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, styled} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import {IPark} from '../data/interfaces';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { availableParks, selectSelectedPark, setSelectedPark, hardRefreshPage } from '../redux/parks';
import {
  NavLink as RouterLink,
  useLocation,
  Location
} from 'react-router-dom';


type NavBarItemProps = {
  handleDrawerClose: () => void;
  link: string;
  displayText: string;
  location: Location<any>
}

function NavBarItem({handleDrawerClose, displayText, link, location}: NavBarItemProps) {
  const isActiveItem = location.pathname == link;
  return (
    <ListItem key={displayText} disablePadding sx={{
      backgroundColor: isActiveItem ? '#F5F5F5': ''
      
      }}>
      <ListItemButton>
        <ListItemText primary={<Button onClick={handleDrawerClose} component={RouterLink} to={link} 
          sx={{ display: 'block'}}
        >
          {displayText}
        </Button>} />
      </ListItemButton>
    </ListItem>)
}



type NavbarProps = {
  logOut: () => void;
}

function Navbar({logOut}: NavbarProps) {
  const dispatch = useAppDispatch();
  const selectedPark = useAppSelector(selectSelectedPark);

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorElement);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const onMenuItemClick = (park: IPark) => {
    dispatch(setSelectedPark(park));
    handleMenuClose();
  }

  const onRefresh = () => {
    dispatch(hardRefreshPage());
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const location = useLocation();

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
              >
                <MenuIcon />
            </IconButton>
              <Button
                variant="contained"
                onClick={handleOpenMenu}
                endIcon={<KeyboardArrowDownIcon />}
                disableElevation
                sx={{ mr: '20px'}}
              >
                {selectedPark.name}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElement}
              open={menuOpen}
              onClose={handleMenuClose}
            >
              {availableParks.map(park => (
                <MenuItem key={park.id} onClick={() => onMenuItemClick(park)}>{park.name}</MenuItem>
              ))}
          </Menu>
          <Box>
            <Button onClick={onRefresh} sx={{ fontWeight: 'bold', my: 2, color: 'white', display: 'block' }}>
              Refresh
            </Button>
          </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
          },
          }}
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerClose}
      >
        <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
        </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <NavBarItem link='/' displayText='Dashboard' handleDrawerClose={handleDrawerClose} location={location} />
          <NavBarItem link='/waits' displayText='Waits' handleDrawerClose={handleDrawerClose} location={location} />
          <NavBarItem link='/virtual-queues' displayText='Virtual queues' handleDrawerClose={handleDrawerClose} location={location} />
          <NavBarItem link='/genie' displayText='Genie' handleDrawerClose={handleDrawerClose} location={location} />
          <NavBarItem link='/shows' displayText='Shows' handleDrawerClose={handleDrawerClose} location={location} />
          <NavBarItem link='/analytics' displayText='Analytics' handleDrawerClose={handleDrawerClose} location={location} />
          <NavBarItem link='/ropedrop' displayText='Rope drop' handleDrawerClose={handleDrawerClose} location={location} />
          <NavBarItem link='/settings' displayText='Config' handleDrawerClose={handleDrawerClose} location={location} />
          <ListItem key={'logout'} disablePadding>
            <ListItemButton>
              <ListItemText onClick={logOut} primary={<Button sx={{ display: 'block' }}>
                Logout
              </Button>} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>)
}

export default Navbar;