import { Session } from "./interfaces";

export default class ApiHandler {
  apiUrl : string;
  includeAccessToken: boolean;
  
  constructor(apiUrl: string, includeAccessToken: boolean) {
      this.apiUrl = apiUrl;
      this.includeAccessToken = includeAccessToken;
  }

  public getResourceUrl(resourceName?: string) {
      if (resourceName == null) {
        return this.apiUrl + "/";
      }

      return this.apiUrl + "/" + resourceName;
  }

  public buildQueryString(params: KeyValue<string, string>[]): string {
      const searchParams = new URLSearchParams();
      params.forEach(kv => searchParams.append(kv.key, kv.value));
      return searchParams.toString();
  }

  public async getResource<T>(
    apiMethod: ApiMethod, 
    resourceName?: string, 
    body?: any, 
    queryStringParameters?: KeyValue<string, string>[]
  ) : Promise<T> {
      let resourceUrl = this.getResourceUrl(resourceName)
      if (queryStringParameters) {
        let queryString = this.buildQueryString(queryStringParameters)
        if (queryString !== "") {
          resourceUrl = `${resourceUrl}?${queryString}`;
        }
      }

      const headers: HeadersInit = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

      if (this.includeAccessToken) {
        const token = localStorage.getItem('sessionToken');
        if (token == null) {
          throw new Error('No session token found.');
        }

        let session: Session = JSON.parse(token);
        headers['Authorization'] = session.sessionId;
      }

      const response = await fetch(resourceUrl,
          {
            headers: headers,
            method: apiMethod,
            body: body == null ? null : JSON.stringify(body),
            cache: 'no-cache'
          });

      if (!response.ok) {
        const errorMessage: string = await response.text();
        console.log(response);
        throw new Error(errorMessage);
      }

      return response.json() as Promise<T>;
  }
}

export enum ApiMethod  {
  POST="POST",
  GET="GET",
  PUT="PUT",
  DELETE="DELETE"
}

export type KeyValue<T, U> = {
  key: T,
  value: U,
};
