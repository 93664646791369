import { WaitHistory, LiveData, LiveStatusType, WaitHistoryEntry, ShowTimes, EntityType, BoardingGroupHistory, OpenTimeHistory } from "./interfaces";
import { tiers } from "./tiers";
import { calculateDownTime, calculateUpForTime } from "./time";

export class ShowTime {
    type: string;
    endTime: Date;
    startTime: Date; 

    constructor(showTimes: ShowTimes) {
        this.type = showTimes.type;
        this.endTime = new Date(showTimes.endTime);
        this.startTime = new Date(showTimes.startTime);
    }
}


export class Attraction {
    name: string;
    waitTime?: number;
    lastUpdated: Date;
    isGeniePlus: boolean;
    returnTime?: string;
    rawReturnTime?: Date;
    id: string;
    parkId: string;
    tier: string;
    completedAt?: string;
    waitHistories?: WaitHistoryEntry[];
    status: string;
    avgHistoricalWaitTime?: number;

    individualLightningLaneCost?: number;

    showTimes: ShowTime[];
    
    constructor(attraction: LiveData, waitHistory?: WaitHistory, avgHistoricalWaitTime?: number) {
        this.id = attraction.id;
        this.name = attraction.name;
        this.parkId = attraction.parkId;
        
        this.tier = '';
        const parkTiers = tiers[this.parkId]
        if (parkTiers !== undefined) {
            const tier = parkTiers[this.id]
            if (tier !== undefined) {
                this.tier = ' (T' + tier + ')';
            }
        }

        this.waitTime = attraction.queue?.STANDBY?.waitTime;
        this.avgHistoricalWaitTime = avgHistoricalWaitTime;
        this.lastUpdated = this.calculateLastUpdated(attraction.lastUpdated, waitHistory?.waitHistory);
        this.waitHistories = waitHistory?.waitHistory;
        this.status = attraction.status;

        this.isGeniePlus = false;
        if (attraction.queue?.RETURN_TIME) {
            this.isGeniePlus = true;

            this.returnTime = 'Sold out';
            if (attraction.queue.RETURN_TIME.returnStart) {
                this.rawReturnTime = new Date(attraction.queue.RETURN_TIME.returnStart)
                this.returnTime =  'Return ' + this.rawReturnTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            }
        }

        this.individualLightningLaneCost = attraction.queue?.PAID_RETURN_TIME?.price?.amount;

        this.showTimes = [];
        if (attraction.showtimes != null) {
            const currentTime = new Date();
            this.showTimes = attraction.showtimes        
                .map(st => new ShowTime(st))
                .filter(st => st.startTime >= currentTime);
        }
    }

    isAPerformance = () => {
        return this.showTimes.some(st => st.type == "Performance Time")
    }

    buildUpcomingPerformanceFirst = () => {
        if (this.showTimes.length == 0) {
            return '';
        }

        return this.showTimes[0].startTime.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'});
    }

    buildUpcomingPerformanceStringEnd = () => {
        let showTimesSlice: ShowTime[] = this.showTimes.slice(1, 3);
        let showTimesString = '';
        for (let i=0; i < showTimesSlice.length; i++){
            if (i==0) {
                showTimesString = showTimesString + ' / ';
            }

            const showTime = showTimesSlice[i];
            showTimesString = showTimesString + showTime.startTime.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'});

            if (i !== showTimesSlice.length - 1) {
                showTimesString = showTimesString + ' / ';
            }
        }

        return showTimesString;
    }

    calculateLastUpdated = (lastUpdated: string, waitHistory?: WaitHistoryEntry[]) => {
        if (waitHistory == null || waitHistory.length == 0) {
            return new Date(lastUpdated);
        }

        return new Date(waitHistory[0].lastUpdated);
    }

    buildWaitTimeHistoryString = () => {
        if (this.waitHistories == null) {
            return '';
        }

        const waitHistoriesSlice = this.waitHistories.slice(1,6);

        let historyString = '';
        for (let i=0; i < waitHistoriesSlice.length; i++){
            if (i==0) {
                historyString = '( ';
            }

            const history = waitHistoriesSlice[i];
            if (history.waitTime == null) {
                historyString = historyString + history.status;
            }
            else {
                historyString = historyString + history.waitTime;
            };

            if (i !== waitHistoriesSlice.length - 1) {
                historyString = historyString + ' / ';
            } else {
                historyString = historyString + ' )';
            }
        }
        return historyString;
    }

    buildDownSinceString = (currentLocalTime: Date) => {
        if (this.waitHistories == null || this.waitHistories.length == 0 || this.waitHistories[0].status != LiveStatusType.Down) {
            return '';
        }

        return calculateDownTime(currentLocalTime, new Date(this.waitHistories[0].lastUpdated))
    }

    buildTimeSinceComingBackUpString = () => {
        if (this.waitHistories == null || this.waitHistories.length <= 1) {
            return '';
        }

        const wasPreviouslyDown = this.waitHistories[1].status == LiveStatusType.Down;
        const isNowOpen = this.waitHistories[0].status == LiveStatusType.Operating;

        if (!wasPreviouslyDown || !isNowOpen) {
            return '';
        }

        return calculateUpForTime(new Date(), new Date(this.waitHistories[0].lastUpdated))
    }

    getDistanceFromAverage = () => {
        if (this.waitTime === undefined || this.avgHistoricalWaitTime === undefined) {
            return null;
        }

        return this.waitTime - this.avgHistoricalWaitTime;
    }

    getPercentFromAverage = () => {
        if (this.waitTime === undefined || this.avgHistoricalWaitTime === undefined) {
            return null;
        }

        return (this.waitTime - this.avgHistoricalWaitTime) / this.avgHistoricalWaitTime * 100;
    }

    static IsAnAttraction = (liveData: LiveData) => {
        return liveData.entityType == EntityType.Attraction;
    }

    static IsAShowWithWaitTimes = (liveData: LiveData) => {
        return liveData.entityType == EntityType.Show && 
            liveData.queue?.STANDBY?.waitTime != null;
    }

    static IsAPerformance = (liveData: LiveData) => {
        return liveData.entityType == EntityType.Show && 
            liveData.showtimes != null && 
            liveData.showtimes.some(st => st.type == "Performance Time")
    }

    static HasAVirtualQueue = (liveData: LiveData) => {
        return this.IsAnAttraction(liveData) && liveData.queue?.BOARDING_GROUP != null;
    }

    static sortByWaitTime = (a: Attraction, b: Attraction) => {
        if (a.waitTime == null) return 1;
        if (b.waitTime == null) return -1;
    
        return a.waitTime > b.waitTime ? -1 : a.waitTime < b.waitTime ? 1 : 0
    }

    static sortByAbsoluteFromAverage = (a: Attraction, b: Attraction) => {
        if (a == null || a.getDistanceFromAverage() == null) return 1;
        if (b == null || b.getDistanceFromAverage() == null) return -1;
    
        return a.getDistanceFromAverage()! < b.getDistanceFromAverage()! ? -1 
            : a.getDistanceFromAverage()! > b.getDistanceFromAverage()! ? 1 : 0
    }

    static sortByPercentFromAverage = (a: Attraction, b: Attraction) => {
        if (a == null || a.getPercentFromAverage() == null) return 1;
        if (b == null || b.getPercentFromAverage() == null) return -1;
    
        return a.getPercentFromAverage()! < b.getPercentFromAverage()! ? -1 
            : a.getPercentFromAverage()! > b.getPercentFromAverage()! ? 1 : 0
    }

    static sortByShowTime = (a: Attraction, b: Attraction) => {
        if (a.showTimes == null || a.showTimes.length == 0) return 1;
        if (b.showTimes == null || b.showTimes.length == 0) return -1;

        return a.showTimes[0].startTime < b.showTimes[0].startTime ? -1 : a.showTimes[0].startTime > b.showTimes[0].startTime ? 1 : 0
    }

    static sortByReturnTime = (a: Attraction, b: Attraction) => {
        if (a.rawReturnTime == null) return 1;
        if (b.rawReturnTime == null) return -1;
    
        return a.rawReturnTime.getTime() > b.rawReturnTime.getTime() ? -1 
            : a.rawReturnTime.getTime() < b.rawReturnTime.getTime() ? 1 : 0
    }

    static sortByCompletedAt = (a: Attraction, b: Attraction) => {
        if (a.completedAt == null) return 1;
        if (b.completedAt == null) return -1;
    
        return a.completedAt > b.completedAt ? -1 
            : a.completedAt < b.completedAt ? 1 : 0
    }
}

export class AttractionWithAnalytics extends Attraction {
    openTimeHistory: OpenTimeHistory[];

    tenMinutesInMillis = 10 * 60 * 1000;
    thirtyMinutesInMillis = 30 * 60 * 1000;
    sixtyMinutesInMillis = 60 * 60 * 1000;

    averageOpeningTimeInMinutes: number = 0;
    percentOpenWithin10Minutes: number = 0;
    percentOpenWithin30Minutes: number = 0;
    percentOpenWithin60Minutes: number = 0;
    percentOpenMoreThan60Minutes: number = 0;
    numberOfHistoryRecords: number = 0;

    constructor(attraction: LiveData, openTimeHistory: OpenTimeHistory[]) {
        super(attraction);
        this.openTimeHistory = openTimeHistory ?? [];
        this.calculateOpeningMetrics(this.openTimeHistory);
    }

    calculateOpeningMetrics = (openTimeHistory: OpenTimeHistory[]) => {
        let totalOpeningTimeDiffMillis: number = 0;
        let openingTimesWithin10Minutes: number = 0;
        let openingTimesWithin30Minutes: number = 0;
        let openingTimesWithin60Minutes: number = 0;
        let openingTimesMoreThan60Minutes: number = 0;

        for (const history of openTimeHistory) {
            const actualOpeningTime = this.timeStringToDate(history.time);
            const expectedOpeningTime = this.timeStringToDate(history.operatingStartTime);

            const diffInMillis = actualOpeningTime.getTime() - expectedOpeningTime.getTime();
            totalOpeningTimeDiffMillis = totalOpeningTimeDiffMillis + diffInMillis;

            if (diffInMillis <= this.tenMinutesInMillis) {
                openingTimesWithin10Minutes++;
            } 
            else if (diffInMillis <= this.thirtyMinutesInMillis) {
                openingTimesWithin30Minutes++;
            }
            else if (diffInMillis <= this.sixtyMinutesInMillis) {
                openingTimesWithin60Minutes++;
            }
            else {
                openingTimesMoreThan60Minutes++;
            }
        }

        this.numberOfHistoryRecords = openTimeHistory.length;
        if (this.numberOfHistoryRecords == 0) {
            return
        }

        const averageOpeningTimeInMillis: number = totalOpeningTimeDiffMillis / this.numberOfHistoryRecords;
        this.averageOpeningTimeInMinutes = Math.round(averageOpeningTimeInMillis / 1000 / 60);

        this.percentOpenWithin10Minutes = Math.round((openingTimesWithin10Minutes / this.numberOfHistoryRecords) * 100);
        this.percentOpenWithin30Minutes = Math.round((openingTimesWithin30Minutes / this.numberOfHistoryRecords) * 100);
        this.percentOpenWithin60Minutes = Math.round((openingTimesWithin60Minutes / this.numberOfHistoryRecords) * 100);
        this.percentOpenMoreThan60Minutes = Math.round((openingTimesMoreThan60Minutes / this.numberOfHistoryRecords) * 100);
    }

    timeStringToDate = (timeString: string) => {
        const timeSplit = timeString.split(':');
        const hour: number = +timeSplit[0];
        const minute: number = +timeSplit[1];
        const second: number = +timeSplit[2];
    
        const time = new Date();
        time.setHours(hour);
        time.setMinutes(minute);
        time.setSeconds(second);
    
        return time;
    }
}

export class VirtualQueueAttraction extends Attraction {
    currentBoardingGroupStartNumber?: string;
    currentBoardingGroupEndNumber?: string;

    boardingGroupHistory: BoardingGroupHistory

    constructor(attraction: LiveData, boardingGroupHistory: BoardingGroupHistory, waitHistory?: WaitHistory) {
        super(attraction, waitHistory);
        this.name = attraction.status == LiveStatusType.Closed ? attraction.name + " [CLOSED]" : attraction.name;
        this.waitTime = attraction.queue?.BOARDING_GROUP?.estimatedWait;
        this.isGeniePlus = false;
        this.currentBoardingGroupStartNumber = attraction.queue?.BOARDING_GROUP?.currentGroupStart;
        this.currentBoardingGroupEndNumber = attraction.queue?.BOARDING_GROUP?.currentGroupEnd;

        this.boardingGroupHistory = boardingGroupHistory;
    }
}