export const DISNEYLAND: string = '7340550b-c14d-4def-80bb-acdb51d49a66';
export const DISNEY_CALIFORNIA_ADVENTURE = '832fcd51-ea19-4e77-85c7-75d5843b127c';
export const ANIMAL_KINGDOM = '1c84a229-8862-4648-9c71-378ddd2c7693';
export const HOLLYWOOD_STUDIOS = '288747d1-8b4f-4a64-867e-ea7c9b27bad8';
export const EPCOT = '47f90d2c-e191-4239-a466-5892ef59a88b';
export const MAGIC_KINGDOM = '75ea578a-adc8-4116-a54d-dccb60765ef9';

export const PARK_NAMES = {
    [DISNEYLAND]: 'Disneyland',
    [DISNEY_CALIFORNIA_ADVENTURE]: 'Disney California Adventure',
    [MAGIC_KINGDOM]: 'Magic Kingdom',
    [EPCOT]: 'Epcot',
    [HOLLYWOOD_STUDIOS]: 'Hollywood Studios',
    [ANIMAL_KINGDOM]: 'Animal Kingdom'
}