import { ScheduleEntityResponse } from "./interfaces";

export type ParkSchedule = {
    hasEarlyEntry: boolean;
    absoluteParkOpen: Date;
    earlyEntryParkOpen?: Date;
    normalParkOpen: Date;
    parkClose: Date;
    dateString: string;
}

export const parseParkSchedule = (parkSchedule: ScheduleEntityResponse, dateOffset: number = 0): ParkSchedule => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + dateOffset);

    const dateString = currentDate.getFullYear() + '-' + 
        ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' + 
        ('0' + currentDate.getDate()).slice(-2);
    const todaysSchedules = parkSchedule.schedule.filter(s => s.date == dateString);
    const earlyEntry = todaysSchedules.find(s => s.type == 'TICKETED_EVENT' && s.description == 'Early Entry');
    const operatingHours = todaysSchedules.find(s => s.type == 'OPERATING')!;

    return {
        hasEarlyEntry: earlyEntry != null,
        absoluteParkOpen: earlyEntry != null ? new Date(earlyEntry.openingTime) : new Date(operatingHours.openingTime),
        earlyEntryParkOpen: earlyEntry != null ? new Date(earlyEntry.openingTime) : undefined,
        normalParkOpen: new Date(operatingHours.openingTime),
        parkClose: new Date(operatingHours.closingTime),
        dateString:  currentDate.toLocaleDateString(undefined, {weekday: 'short', month: 'short', day: 'numeric'})
    }
}