import './App.css';
import {useState} from 'react';
import { CredentialResponse, GoogleLogin, googleLogout } from '@react-oauth/google';
import { HashRouter, Routes, Route } from 'react-router-dom';
import WaitTimes from './pages/WaitTimes';
import GeniePlus from './pages/GeniePlus';
import { AppLayout } from './layout/AppLayout';
import { login } from './data';
import Settings from './pages/Settings';
import ShowTimes from './pages/ShowTimes';
import RopeDrop from './pages/RopeDrop';
import Dashboard from './pages/Dashboard';
import { Session } from './data/interfaces';
import Analytics from './pages/Analytics';
import VirtualQueues from './pages/VirtualQueues';


function App() {
  const [sessionToken, setSessionToken] = useState<Session | null>(() => {
    const sessionTokenInLocalStorage = localStorage.getItem('sessionToken');    
    if (sessionTokenInLocalStorage == null) {
      return null;
    }

    try {
      return JSON.parse(sessionTokenInLocalStorage);
    }
    catch (e) {
      // invalidate the session token if it's not valid json
      return null;
    }
  });
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const logOut = () => {
      googleLogout();
      localStorage.removeItem('sessionToken');
      setSessionToken(null);
  };

  const onLogin = async (creds: CredentialResponse) => {
      try {
        const session: Session = await login(creds.credential!);
        setSessionToken(session)
        localStorage.setItem('sessionToken', JSON.stringify(session));
      }
      catch (e) {
        console.error(e);
        setIsUnauthorized(true);
      }
  }

  if (isUnauthorized) {
    return (<p>Unauthorized</p>)
  }

  if (sessionToken) {
    return (
      <HashRouter>
          <Routes>
            <Route element={<AppLayout logOut={logOut} />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/waits" element={<WaitTimes />} />
              <Route path="/genie" element={<GeniePlus />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/shows" element={<ShowTimes />} />
              <Route path="/ropedrop" element={<RopeDrop />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/virtual-queues" element={<VirtualQueues />} />
            </Route>
          </Routes>
      </HashRouter>
    );
  }
  else {
    return (
      <GoogleLogin
        onSuccess={(creds) => onLogin(creds)}
        onError={() => {
          console.log('Login Failed');
        }}
      />)
  }
}

export default App;
