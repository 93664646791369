// defined for this app

export interface Session {
    sessionId: string;
    userName: string;
}

export interface IPark {
    name: string;
    id: string;
}

export interface IActivePark {
    parkId: string;
}

export interface IGeniePlusCompleteAttraction {
    date: string;
    attractionId: string;
    completedAt: string;
}

export interface MyBoardingGroupEntry {
    date: string;
    attractionId: string;
    boardingGroupNumber: string;
}

export interface BoardingGroupHistory {
    date: string;
    attractionId: string;
    name: string;
    boardingGroupHistory: BoardingGroupHistoryEntry[]
}

export interface BoardingGroupHistoryEntry {
    lastUpdated: string;
    boardingGroupStart: string;
    boardingGroupEnd: string;
    status: string;
}

export interface WaitHistory {
    date: string;
    attractionId: string;
    name: string;
    waitHistory: WaitHistoryEntry[];
}

export interface WaitHistoryEntry {
    status: string;
    lastUpdated: string;
    waitTime?: string;
}

// defined by API

export interface StandbyQueue {
    waitTime?: number
}

export interface GeniePlusQueue {
    returnStart: string;
    returnEnd: string;
    status: string;
}

export interface SingleRiderQueue {
    waitTime?: number;
}

export interface BoardingGroupQueue {
    allocationStatus: string;
    currentGroupStart: string;
    currentGroupEnd: string;
    nextAllocationTime: string;
    estimatedWait: number;
}

export interface PriceData {
    amount: number;
    currency: string;
}

export interface IndividualLightningLaneQueue {
    state: string;
    returnStart: string;
    returnEnd: string;
    price: PriceData;
}

export interface LiveQueue {
    RETURN_TIME?: GeniePlusQueue;
    STANDBY?: StandbyQueue;
    SINGLE_RIDER?: SingleRiderQueue;
    BOARDING_GROUP?: BoardingGroupQueue;
    PAID_RETURN_TIME?: IndividualLightningLaneQueue;
}

export enum EntityType {
    Attraction = 'ATTRACTION',
    Show = 'SHOW'
}

export enum LiveStatusType {
    Operating = 'OPERATING', 
    Down = 'DOWN', 
    Closed = 'CLOSED', 
    Refurbishment = 'REFURBISHMENT'
}

export interface ShowTimes {
    type: string;
    endTime: string;
    startTime: string;
}

export interface LiveData {
    entityType: EntityType;
    lastUpdated: string;
    name: string;
    status: string;
    queue?: LiveQueue;
    id: string;
    showtimes: ShowTimes[];
    parkId: string;
}

export interface LiveEntityResponse {
    liveData: LiveData[]
}

export interface Schedule {
    date: string;
    type: string;
    description: string;
    openingTime: string;
    closingTime: string;
}

export interface ScheduleEntityResponse {
    schedule: Schedule[]
    name: string;
}

export interface OpenTimeHistory {
    id: string;
    date: string;
    time: string;
    operatingStartTime: string;
}