import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableContainer, TableHead } from "@mui/material";
import Paper from '@mui/material/Paper';
import { Attraction } from "../../data/attractions";
import { calculateTimeTillStart, toCustomTimeString } from "../../data/time";
import { StyledTableCell, StyledTableRow } from "../../components/Tables";

interface PerformanceDetailsProps {
    open: boolean;
    handleClose: () => void;
    attraction: Attraction;
}

function PerformanceDetails({open, handleClose, attraction}: PerformanceDetailsProps) {
    const currentTime = new Date();
    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{minWidth: '75%', height: '100%'}}
        fullScreen={true}
      >
        <DialogTitle id="alert-dialog-title">
          {attraction.name}
        </DialogTitle>
        <DialogContent>
            <p>Currently {toCustomTimeString(currentTime)}</p>
            <TableContainer component={Paper}>
                <Table size="medium">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Start time</StyledTableCell>
                            <StyledTableCell>End time</StyledTableCell>
                            <StyledTableCell>Starts in</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {attraction.showTimes.map((st) => {
                            return (
                                <StyledTableRow key={st.startTime.toISOString()}>
                                    <StyledTableCell component="th" scope="row">{toCustomTimeString(st.startTime)}</StyledTableCell>
                                    <StyledTableCell>{toCustomTimeString(st.endTime)}</StyledTableCell>
                                    <StyledTableCell>{calculateTimeTillStart(currentTime, st.startTime)}</StyledTableCell>
                                </StyledTableRow>
                            )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>);
};

export default PerformanceDetails;
