export const JAMBALAYA_JAZZ: string = '3220fd76-38e4-49a4-afa3-4842c07203fa';
export const PEARLY_BAND: string = '4119dd28-6da8-46a0-9246-bea86e07ac61';
export const FLAG_RETREAT: string = 'eeafc865-a4e0-4dc5-9cea-446108bf7d48';
export const FANTASMIC: string = '8c36ff0b-3a32-4d7b-9388-0516c19277db';
export const THE_BOOTSTRAPPERS: string = '8160b905-b318-41be-84bf-869fb6a55b14';
export const MAGIC_HAPPENS_PARADE: string = '8c7b2829-3061-421b-98d1-a5bef01e78b0';
export const DAPPER_DANS: string = 'fdf0e3b1-2eeb-42b4-9516-5d43463d021e';
export const DISNEYLAND_BAND: string = 'a6760f7b-3489-47df-9547-d70dd2d8cb76';
export const STORYTELLING_AT_ROYAL_THEATER: string = '72340a8a-b6d2-474f-aca5-5ea7d2d52335';
export const MAIN_STREET_PIANO_PLAYER: string = '8b12916b-a384-4305-838a-c4b07affbb14';
export const TOGETHER_FOREVER_FIREWORKS: string = '39bb7fcc-5fb7-4123-bd95-c32982244d8d';
export const TOGETHER_FOREVER_PROJECTIONS: string = 'b9a095d9-599a-4116-a788-52030a794fc5';