import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import { ScheduleEntityResponse } from "../data/interfaces";
import { toCustomTimeString } from "../data/time";
import { StyledTableCell, StyledTableRow } from "./Tables";

interface ParkSchedule5DayProps {
    open: boolean;
    handleClose: () => void;
    parkSchedule?: ScheduleEntityResponse;
}

function ParkSchedule5Day({open, handleClose, parkSchedule}: ParkSchedule5DayProps) {
    if (parkSchedule == null) {
        return null;
    }

    const fiveDaysFromNow = new Date();
    fiveDaysFromNow.setDate(fiveDaysFromNow.getDate() + 4);
    fiveDaysFromNow.setHours(0, 0, 0, 0);

    const filteredSchedule = parkSchedule.schedule
        .filter(schedule => schedule.description != 'Park Hopping')
        .filter(schedule => new Date(schedule.date) <= fiveDaysFromNow);
        
    let rowsByDateNumber: string[] = [];
    let dateCount = 1;

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{minWidth: '75%', height: '100%'}}
        fullScreen={true}
      >
        <DialogTitle id="alert-dialog-title">
          {parkSchedule.name}
        </DialogTitle>
        <DialogContent>
            <TableContainer component={Paper}>
                <Table size="medium">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Open</StyledTableCell>
                            <StyledTableCell>Close</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSchedule.map((schedule) => {
                            let backgroundColor = null;
                            const dateString = new Date(schedule.openingTime).toLocaleDateString(undefined, 
                                {month: 'numeric', day: 'numeric'}
                            );
                            if (!rowsByDateNumber.includes(dateString)) {
                                rowsByDateNumber.push(dateString);
                                dateCount++;
                            }

                            backgroundColor = dateCount % 2 == 1 ? 'rgba(0, 0, 0, 0.04)' : null;

                            return (
                                <TableRow  sx={{backgroundColor: backgroundColor}} key={schedule.openingTime}>
                                    <TableCell>{dateString}</TableCell>
                                    <TableCell>{toCustomTimeString(new Date(schedule.openingTime))}</TableCell>
                                    <TableCell>{toCustomTimeString(new Date(schedule.closingTime))}</TableCell>
                                    <TableCell>{schedule.description}</TableCell>
                                </TableRow>
                            )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={
            () => {
                handleClose()
          }} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>);
};

export default ParkSchedule5Day;
