import { useEffect, useState } from "react";

const initialTime = new Date().toLocaleString();

const Clock = () => {
  const [time, setTime] = useState(initialTime);
  const updateTime = () => {
    const currentTime = new Date();
    setTime(currentTime.toLocaleString());
  }

  useEffect(() => {
    let intervalId = setInterval(updateTime,1000)
    return(() => {
        clearInterval(intervalId)
    })
},[])

  return (
      <span>Current time is {time}</span>
  );
};

export default Clock;