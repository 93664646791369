import React from "react";
import { AttractionWithAnalytics } from "../../data/attractions";
import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import OpeningTimeHistory from "./OpeningTimeHistory";
import { pink } from "@mui/material/colors";

type AttractionAnalyticsProps = {
    attraction: AttractionWithAnalytics
}

export const AttractionAnalytics = ({attraction}: AttractionAnalyticsProps) => {
    const [openTimeHistoryOpen, setOpenTimeHistoryOpen] = React.useState(false);

    const onAttractionClick = async () => {
        setOpenTimeHistoryOpen(true);
    }

    let openHistory = <></>
    if (openTimeHistoryOpen) {
        openHistory = <OpeningTimeHistory 
            attraction={attraction} 
            open={openTimeHistoryOpen} 
            openTimeHistory={attraction.openTimeHistory}
            handleClose={() => setOpenTimeHistoryOpen(false)} />;
    }

    return (<React.Fragment key={attraction.name}>
        <ListItem onClick={() => onAttractionClick()}>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: pink[500] }}>{
                attraction.percentOpenWithin10Minutes == 0 ? '' : attraction.percentOpenWithin10Minutes
            }</Avatar>
            </ListItemAvatar>
            <ListItemText primary={
                <Typography component="span" sx={{ display: 'inline', fontSize: '1.25em', color: '#505050'  }}>
                    {attraction.name}
                </Typography>
            }
             />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
        {openHistory}
    </React.Fragment>);
};