import ParkSchedule from "./ParkSchedule";
import Refresh from './Refresh';

type HeaderProps = {
    extraText?: string;
}

const Header = ({extraText}: HeaderProps) => {
    return(<>
        <ParkSchedule />
        <Refresh />
        {extraText ?? <div>{extraText}</div>}
    </>);
}

export default Header;