import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import { Attraction } from "../../data/attractions";
import { LiveStatusType } from "../../data/interfaces";
import { utcToLocal } from "../../data/time";
import { StyledTableCell, StyledTableRow } from "../../components/Tables";

interface WaitHistoryProps {
    open: boolean;
    handleClose: () => void;
    attraction: Attraction;
}

function WaitTimeHistory({open, handleClose, attraction}: WaitHistoryProps) {
   if (attraction.waitHistories == null) {
    // TODO: dialog content here
    return;
   }

   let maxWaitTime: number | null = null;
   let minWaitTime: number | null = null;
   for (const wh of attraction.waitHistories) {
        if (wh.waitTime == null) {
            continue;
        }

        const waitTimeAsNumber = Number(wh.waitTime);
        if (maxWaitTime == null || waitTimeAsNumber > maxWaitTime) {
            maxWaitTime = waitTimeAsNumber
        }

        if (minWaitTime == null || waitTimeAsNumber < minWaitTime) {
            minWaitTime = waitTimeAsNumber;
        }
    }

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{minWidth: '75%', height: '100%'}}
        fullScreen={true}
      >
        <DialogTitle id="alert-dialog-title">
          {attraction.name}
        </DialogTitle>
        <DialogContent>
            <TableContainer component={Paper}>
                <Table size="medium">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Wait</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Last updated</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {attraction.waitHistories.map((wh) => {
                            if (wh.status === LiveStatusType.Down) {
                                return (<TableRow sx={{backgroundColor: 'grey'}} key={wh.lastUpdated}>
                                    <TableCell scope="row">{wh.waitTime}</TableCell>
                                    <TableCell sx={{color: 'white'}}>{wh.status}</TableCell>
                                    <TableCell sx={{color: 'white'}}>{utcToLocal(wh.lastUpdated)}</TableCell>
                                </TableRow>);
                            }

                            const isMaxWaitTime = wh.waitTime != null && Number(wh.waitTime) === maxWaitTime;
                            const isMinWaitTime = wh.waitTime != null && Number(wh.waitTime) === minWaitTime;
                            return (
                                <StyledTableRow key={wh.lastUpdated}>
                                    <StyledTableCell 
                                        sx={{
                                            color: isMaxWaitTime ? 'red' : isMinWaitTime ? 'green': '',
                                            fontWeight: isMaxWaitTime || isMinWaitTime ? 'bold' : ''
                                        }} component="th" scope="row"
                                    >{wh.waitTime}</StyledTableCell>
                                    <StyledTableCell>{wh.status}</StyledTableCell>
                                    <StyledTableCell>{utcToLocal(wh.lastUpdated)}</StyledTableCell>
                                </StyledTableRow>
                            )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>);
};

export default WaitTimeHistory;
