import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { getBoardingGroupHistory, getLiveDataForPark, getMyBoardingGroups } from '../../data';
import { BoardingGroupHistory, LiveData, MyBoardingGroupEntry} from '../../data/interfaces';
import { useAppSelector } from '../../redux/hooks';
import { onRefresh, selectSelectedPark } from '../../redux/parks';
import { MyBoardingGroupAttractionComponent, VirtualQueueAttractionComponent } from '../../components/Attractions';
import { Attraction, VirtualQueueAttraction } from '../../data/attractions';
import Header from '../../components/Header';
import { MyBoardingGroup } from '../Dashboard/boardingGroup';


function VirtualQueues() {
    const selectedPark = useAppSelector(selectSelectedPark);
    const refreshState = useAppSelector(onRefresh);

    const [attractions, setAttractions] = useState<LiveData[]>([]);
    const [myBoardingGroups, setMyBoardingGroups] = useState<MyBoardingGroupEntry[]>([]);
    const [boardingGroupHistory, setBoardingGroupHistory] = useState<{[id: string]: BoardingGroupHistory}>({});

    useEffect(() => {
        const loadWaitTimesForPark = async () => {
            const waitTimes = await getLiveDataForPark(selectedPark.id);
            const allAttractions: LiveData[] = waitTimes.liveData
                .filter(data => Attraction.HasAVirtualQueue(data));
        
            setAttractions(allAttractions);
        }    

        if (refreshState.isHardRefresh) {
            setAttractions([]);
        }
        loadWaitTimesForPark();
    }, [selectedPark, refreshState]);

    useEffect(() => {
        const loadBoardingGroupHistory = async () => {
            const newBoardingGroupHistory: {[id: string]: BoardingGroupHistory} = {};
            const boardingGroupHistoryResponse = await getBoardingGroupHistory(selectedPark.id);
            for (const history of boardingGroupHistoryResponse) {
                newBoardingGroupHistory[history.attractionId] = history;
            }
            setBoardingGroupHistory(newBoardingGroupHistory);    
        }
    
        loadBoardingGroupHistory();
    }, [selectedPark, refreshState]);


    useEffect(() => {    
        const loadMyBoardingGroups = async () => {
            const myBoardingGroups = await getMyBoardingGroups(selectedPark.id);
            setMyBoardingGroups(myBoardingGroups);
        }

        loadMyBoardingGroups();
    }, [selectedPark, refreshState]);

    let virtualQueues: VirtualQueueAttraction[] = [];
    let activeBoardingGroups: MyBoardingGroup[] = [];
    let unavailableVirtualQueues: VirtualQueueAttraction[] = [];
    for (const attraction of attractions) {
        const myBoardingGroup = myBoardingGroups.find(bg => bg.attractionId == attraction.id);
        const boardingGroupHistoryForThisAttraction = boardingGroupHistory[attraction.id] || [];

        const vqAttraction = new VirtualQueueAttraction(attraction, boardingGroupHistoryForThisAttraction);
        if (myBoardingGroup != null) {
            const thisBoardingGroup = new MyBoardingGroup(myBoardingGroup.boardingGroupNumber, vqAttraction);            
            if (thisBoardingGroup.hasPast()) {
                unavailableVirtualQueues.push(vqAttraction);
            }
            else {
                activeBoardingGroups.push(thisBoardingGroup);
            }
        }
        else {
            virtualQueues.push(vqAttraction);
        }
    }

    const currentLocalTime = new Date();
    let activeBoardingGroupsHtml = null;
    if (activeBoardingGroups.length > 0) {
        activeBoardingGroupsHtml = (<>
            <ListItem>
                <ListItemText primary={"Active boarding groups"} />
            </ListItem>
            
            {activeBoardingGroups.map(boardingGroup =>  
                (<MyBoardingGroupAttractionComponent key={boardingGroup.attraction.name} myBoardingGroup={boardingGroup} currentLocalTime={currentLocalTime} />
            ))}

            <Divider variant="fullWidth" component="li"  sx={{mt: '50px'}} />
        </>);
    }

    let availableVirtualQueuesHtml = null;
    if (virtualQueues.length > 0) {
        availableVirtualQueuesHtml = (<>
            <ListItem>
                <ListItemText primary={"Available virtual queues"} />
            </ListItem>
            {virtualQueues.map(attraction =>  
                (<VirtualQueueAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}
            <Divider variant="fullWidth" component="li" />
        </>);
    }

    let unavailableVirtualQueuesHtml = null;
    if (unavailableVirtualQueues.length > 0) {
        unavailableVirtualQueuesHtml = (<>
              <ListItem>
                <ListItemText primary={"Unavailable virtual queues"} />
            </ListItem>
            {unavailableVirtualQueues.map(attraction =>  
                (<VirtualQueueAttractionComponent key={attraction.name} attraction={attraction} currentLocalTime={currentLocalTime} />
            ))}
            <Divider variant="fullWidth" component="li" />
        </>);
    }

    return (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper'
            }}
        >
            <ListItem>
                <ListItemText sx={{textAlign: 'center'}} secondaryTypographyProps={{component: 'span'}} secondary={<Header />} />
            </ListItem>
            <Divider variant="fullWidth" component="li" />

            {activeBoardingGroupsHtml}
            {availableVirtualQueuesHtml}
            {unavailableVirtualQueuesHtml}
        </List>
    )
};


export default VirtualQueues;