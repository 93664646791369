import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';

type AppLayoutProps = {
    logOut: () => void;
}


export const AppLayout = ({logOut}: AppLayoutProps) => {
    return (
        <>
            <Navbar logOut={logOut} />
            <Outlet/>
        </>
    );
};