import { AVENGERS_CAMPUS, BUENA_VISTA_STREET, CARS_LAND, GRIZZLY_PEAK, HOLLYWOOD_LAND, PARADISE_GARDENS_PARK, PIXAR_PIER, SAN_FRANSOKYO_SQUARE } from "../../constants/lands/dca";
import { FRONTIERLAND, FANTASYLAND, GALAXYS_EDGE, TOONTOWN, ADVENTURELAND, NEW_ORLEANS_SQUARE, TOMORROWLAND, CRITTER_COUNTRY, MAIN_STREET } from "../../constants/lands/disneyland";
import { DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE } from "../../constants/parks";

// to find the closest X rides, we may need to look at neighboring lands
export const neighboringLands: {
    [park: string]: {
        [land: string]: string[]
    }
   } = {
    [DISNEYLAND]: {
        [FANTASYLAND]: [
            TOONTOWN,
            GALAXYS_EDGE
        ],
        [FRONTIERLAND]: [
            GALAXYS_EDGE,
            ADVENTURELAND,
            NEW_ORLEANS_SQUARE
        ],
        [TOONTOWN]: [
            FANTASYLAND
        ],
        [TOMORROWLAND]: [
            MAIN_STREET
        ],
        [GALAXYS_EDGE]: [
            CRITTER_COUNTRY,
            FANTASYLAND,
            FRONTIERLAND
        ],
        [ADVENTURELAND]: [
            FRONTIERLAND,
            NEW_ORLEANS_SQUARE,
            MAIN_STREET
        ],
        [NEW_ORLEANS_SQUARE]: [
            CRITTER_COUNTRY,
            ADVENTURELAND, 
            FRONTIERLAND
        ],
        [CRITTER_COUNTRY]: [
            GALAXYS_EDGE,
            NEW_ORLEANS_SQUARE
        ],
        [MAIN_STREET]: [
            ADVENTURELAND,
            FRONTIERLAND,
            TOMORROWLAND
        ]
    },
    [DISNEY_CALIFORNIA_ADVENTURE]: {
        [AVENGERS_CAMPUS]: [
            HOLLYWOOD_LAND,
            CARS_LAND,
            BUENA_VISTA_STREET
        ],
        [BUENA_VISTA_STREET]: [
            HOLLYWOOD_LAND,
            GRIZZLY_PEAK,
            AVENGERS_CAMPUS
        ],
        [CARS_LAND]: [
            AVENGERS_CAMPUS,
            SAN_FRANSOKYO_SQUARE
        ],
        [GRIZZLY_PEAK]: [
            BUENA_VISTA_STREET,
            PARADISE_GARDENS_PARK
        ],
        [HOLLYWOOD_LAND]: [
            AVENGERS_CAMPUS,
            BUENA_VISTA_STREET
        ],
        [PARADISE_GARDENS_PARK]: [
            PIXAR_PIER,
            GRIZZLY_PEAK,
            SAN_FRANSOKYO_SQUARE
        ],
        [PIXAR_PIER]: [
            PARADISE_GARDENS_PARK,
            SAN_FRANSOKYO_SQUARE
        ],
        [SAN_FRANSOKYO_SQUARE]: [
            PIXAR_PIER,
            PARADISE_GARDENS_PARK,
            CARS_LAND
        ]
    }
}
