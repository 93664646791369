import { useEffect } from "react";
import { useAppDispatch } from "../redux/hooks";
import { refreshPage } from "../redux/parks";

export const utcToLocal = (utcDate: string) => {
    const localDate = new Date(utcDate);
    return localDate.toLocaleTimeString();
}

export const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000)
}

export const calculateTimeDiff = (currentTime: Date, timeInThePast: Date) => {
    const diffInMillis = currentTime.getTime() - timeInThePast.getTime();

    const diffInSeconds = diffInMillis / 1000;
    if (diffInSeconds < 60) {
        return Math.trunc(diffInSeconds) + ' seconds ago';
    }

    const diffInMinutes = diffInSeconds / 60;
    if (diffInMinutes < 60) {
        return Math.trunc(diffInMinutes) + ' minutes ago';
    }

    let diffInHours = Math.trunc(diffInMinutes / 60);
    if (diffInHours > 12) {
        diffInHours = 12;
    }
    return diffInHours + '+ hours ago';
}

export const calculateDownTime = (currentTime: Date, timeThatRideWentDown: Date) => {
    const diffInMillis = currentTime.getTime() - timeThatRideWentDown.getTime();

    const diffInSeconds = diffInMillis / 1000;
    if (diffInSeconds < 60) {
        return 'Down for ' + Math.trunc(diffInSeconds) + ' seconds';
    }

    const diffInMinutes = diffInSeconds / 60;
    if (diffInMinutes < 60) {
        return 'Down for ' + Math.trunc(diffInMinutes) + ' minutes';
    }

    let diffInHours = Math.trunc(diffInMinutes / 60);
    let diffInMinutesFromHours = diffInMinutes % 60;

    return 'Down for ' + diffInHours + ' hour(s) and ' + Math.trunc(diffInMinutesFromHours) + ' minutes';
}

export const calculateUpForTime = (currentTime: Date, timeThatRideWentDown: Date) => {
    const diffInMillis = currentTime.getTime() - timeThatRideWentDown.getTime();

    const diffInSeconds = diffInMillis / 1000;
    if (diffInSeconds < 60) {
        return 'Back up ' + Math.trunc(diffInSeconds) + ' seconds ago';
    }

    const diffInMinutes = diffInSeconds / 60;
    if (diffInMinutes < 45) {
        return 'Back up ' + Math.trunc(diffInMinutes) + ' min ago';
    }

    // ignore over 45 min ago
    return '';
}

export const calculateTimeTillStart = (currentTime: Date, startTime: Date) => {
    const diffInMillis = startTime.getTime() - currentTime.getTime();

    const diffInSeconds = diffInMillis / 1000;
    if (diffInSeconds < 60) {
        return  Math.trunc(diffInSeconds) + ' seconds';
    }

    const diffInMinutes = diffInSeconds / 60;
    if (diffInMinutes < 60) {
        return Math.trunc(diffInMinutes) + ' minutes';
    }

    let diffInHours = Math.trunc(diffInMinutes / 60);
    if (diffInHours > 12) {
        diffInHours = 12;
    }
    return diffInHours + '+ hours';
}

export const toCustomTimeString = (date?: Date) => {
    if (date == null) {
        return '';
    }

    return date.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'});
}

export const useRefreshPageAfterSeconds = (seconds: number) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        // refresh page every x seconds
        let intervalId = setInterval(() => {
            dispatch(refreshPage())
        }, 1000 * seconds)
        return(() => {
            clearInterval(intervalId)
        })
    },[])
}