
import { AVENGERS_CAMPUS, BUENA_VISTA_STREET, CARS_LAND, GRIZZLY_PEAK, HOLLYWOOD_LAND, PARADISE_GARDENS_PARK, PIXAR_PIER, SAN_FRANSOKYO_SQUARE } from "../../../constants/lands/dca";
import { ADVENTURELAND, CRITTER_COUNTRY, FANTASYLAND, FRONTIERLAND, GALAXYS_EDGE, MAIN_STREET, NEW_ORLEANS_SQUARE, TOMORROWLAND, TOONTOWN } from "../../../constants/lands/disneyland";
import { DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE } from "../../../constants/parks";

export const landPolygons = {
    [DISNEYLAND]: [
        {
            "name": FANTASYLAND,
            "border": [
                {
                    "latitude": "33.8137828",
                    "longitude": "-117.9199013"
                },
                {
                    "latitude": "33.8130741",
                    "longitude": "-117.9197994"
                },
                {
                    "latitude": "33.8123654",
                    "longitude": "-117.91955"
                },
                {
                    "latitude": "33.8122997",
                    "longitude": "-117.919314"
                },
                {
                    "latitude": "33.8123632",
                    "longitude": "-117.9185308"
                },
                {
                    "latitude": "33.8123348",
                    "longitude": "-117.9182153"
                },
                {
                    "latitude": "33.8124936",
                    "longitude": "-117.9180607"
                },
                {
                    "latitude": "33.812688",
                    "longitude": "-117.9179223"
                },
                {
                    "latitude": "33.8127354",
                    "longitude": "-117.9176443"
                },
                {
                    "latitude": "33.8130094",
                    "longitude": "-117.9176108"
                },
                {
                    "latitude": "33.8133415",
                    "longitude": "-117.9174539"
                },
                {
                    "latitude": "33.8137716",
                    "longitude": "-117.9176671"
                },
                {
                    "latitude": "33.8149034",
                    "longitude": "-117.9170211"
                },
                {
                    "latitude": "33.8156452",
                    "longitude": "-117.9170081"
                },
                {
                    "latitude": "33.8161083",
                    "longitude": "-117.9176634"
                },
                {
                    "latitude": "33.8150654",
                    "longitude": "-117.9178082"
                },
                {
                    "latitude": "33.814847",
                    "longitude": "-117.9183231"
                },
                {
                    "latitude": "33.8149258",
                    "longitude": "-117.9189642"
                },
                {
                    "latitude": "33.8142929",
                    "longitude": "-117.9196186"
                },
                {
                    "latitude": "33.8137828",
                    "longitude": "-117.9199013"
                }
            ]
        },
        {
            "name": GALAXYS_EDGE,
            "border": [
                {
                    "latitude": "33.8137828",
                    "longitude": "-117.9199013"
                },
                {
                    "latitude": "33.8142929",
                    "longitude": "-117.9196186"
                },
                {
                    "latitude": "33.8153415",
                    "longitude": "-117.9201677"
                },
                {
                    "latitude": "33.815814",
                    "longitude": "-117.9205218"
                },
                {
                    "latitude": "33.8157783",
                    "longitude": "-117.9218683"
                },
                {
                    "latitude": "33.8148825",
                    "longitude": "-117.9234883"
                },
                {
                    "latitude": "33.813795",
                    "longitude": "-117.9240194"
                },
                {
                    "latitude": "33.8130949",
                    "longitude": "-117.9233057"
                },
                {
                    "latitude": "33.8130283",
                    "longitude": "-117.9229143"
                },
                {
                    "latitude": "33.813095",
                    "longitude": "-117.922587"
                },
                {
                    "latitude": "33.813688",
                    "longitude": "-117.9223779"
                },
                {
                    "latitude": "33.8139732",
                    "longitude": "-117.9219058"
                },
                {
                    "latitude": "33.8138663",
                    "longitude": "-117.9209617"
                },
                {
                    "latitude": "33.8137828",
                    "longitude": "-117.9199013"
                }
            ]
        },
        {
            "name": FRONTIERLAND,
            "border": [
                {
                    "latitude": "33.8138303",
                    "longitude": "-117.9206342"
                },
                {
                    "latitude": "33.8138704",
                    "longitude": "-117.9208595"
                },
                {
                    "latitude": "33.8139732",
                    "longitude": "-117.9219058"
                },
                {
                    "latitude": "33.813688",
                    "longitude": "-117.9223779"
                },
                {
                    "latitude": "33.813095",
                    "longitude": "-117.922587"
                },
                {
                    "latitude": "33.8126759",
                    "longitude": "-117.9219377"
                },
                {
                    "latitude": "33.8120341",
                    "longitude": "-117.9216212"
                },
                {
                    "latitude": "33.811673",
                    "longitude": "-117.9214013"
                },
                {
                    "latitude": "33.8115795",
                    "longitude": "-117.9210418"
                },
                {
                    "latitude": "33.8117221",
                    "longitude": "-117.9207522"
                },
                {
                    "latitude": "33.8118609",
                    "longitude": "-117.9206389"
                },
                {
                    "latitude": "33.8121143",
                    "longitude": "-117.9206664"
                },
                {
                    "latitude": "33.8121633",
                    "longitude": "-117.9199904"
                },
                {
                    "latitude": "33.8123654",
                    "longitude": "-117.91955"
                },
                {
                    "latitude": "33.8130741",
                    "longitude": "-117.9197994"
                },
                {
                    "latitude": "33.8137828",
                    "longitude": "-117.9199013"
                },
                {
                    "latitude": "33.8138303",
                    "longitude": "-117.9206342"
                }
            ]
        },
        {
            "name": ADVENTURELAND,
            "border": [
                {
                    "latitude": "33.8115349",
                    "longitude": "-117.920602"
                },
                {
                    "latitude": "33.8105187",
                    "longitude": "-117.9208273"
                },
                {
                    "latitude": "33.8099526",
                    "longitude": "-117.9198671"
                },
                {
                    "latitude": "33.810171",
                    "longitude": "-117.9196525"
                },
                {
                    "latitude": "33.8107371",
                    "longitude": "-117.9196096"
                },
                {
                    "latitude": "33.8111248",
                    "longitude": "-117.9194594"
                },
                {
                    "latitude": "33.8114101",
                    "longitude": "-117.9195345"
                },
                {
                    "latitude": "33.8116107",
                    "longitude": "-117.9197115"
                },
                {
                    "latitude": "33.8117399",
                    "longitude": "-117.9197437"
                },
                {
                    "latitude": "33.8117711",
                    "longitude": "-117.9195291"
                },
                {
                    "latitude": "33.8121321",
                    "longitude": "-117.9194647"
                },
                {
                    "latitude": "33.8123654",
                    "longitude": "-117.91955"
                },
                {
                    "latitude": "33.8122079",
                    "longitude": "-117.9198885"
                },
                {
                    "latitude": "33.8121633",
                    "longitude": "-117.9199904"
                },
                {
                    "latitude": "33.8121143",
                    "longitude": "-117.9206664"
                },
                {
                    "latitude": "33.8115349",
                    "longitude": "-117.920602"
                }
            ]
        },
        {
            "name": NEW_ORLEANS_SQUARE,
            "border": [
                {
                    "latitude": "33.8118603",
                    "longitude": "-117.9206449"
                },
                {
                    "latitude": "33.8117221",
                    "longitude": "-117.9207522"
                },
                {
                    "latitude": "33.8115795",
                    "longitude": "-117.9210418"
                },
                {
                    "latitude": "33.811673",
                    "longitude": "-117.9214013"
                },
                {
                    "latitude": "33.8117789",
                    "longitude": "-117.92151"
                },
                {
                    "latitude": "33.8119249",
                    "longitude": "-117.9216669"
                },
                {
                    "latitude": "33.8116363",
                    "longitude": "-117.9218291"
                },
                {
                    "latitude": "33.8113477",
                    "longitude": "-117.9220504"
                },
                {
                    "latitude": "33.8105008",
                    "longitude": "-117.9223883"
                },
                {
                    "latitude": "33.8102245",
                    "longitude": "-117.921927"
                },
                {
                    "latitude": "33.8102557",
                    "longitude": "-117.9211921"
                },
                {
                    "latitude": "33.8105187",
                    "longitude": "-117.9208273"
                },
                {
                    "latitude": "33.8115349",
                    "longitude": "-117.920602"
                },
                {
                    "latitude": "33.8118603",
                    "longitude": "-117.9206449"
                }
            ]
        },
        {
            "name": CRITTER_COUNTRY,
            "border": [
                {
                    "latitude": "33.8130283",
                    "longitude": "-117.9229143"
                },
                {
                    "latitude": "33.8130949",
                    "longitude": "-117.9233057"
                },
                {
                    "latitude": "33.8129612",
                    "longitude": "-117.9235417"
                },
                {
                    "latitude": "33.8120296",
                    "longitude": "-117.9234934"
                },
                {
                    "latitude": "33.8105008",
                    "longitude": "-117.9223883"
                },
                {
                    "latitude": "33.8113477",
                    "longitude": "-117.9220504"
                },
                {
                    "latitude": "33.8116708",
                    "longitude": "-117.9218063"
                },
                {
                    "latitude": "33.8120341",
                    "longitude": "-117.9216212"
                },
                {
                    "latitude": "33.8126759",
                    "longitude": "-117.9219377"
                },
                {
                    "latitude": "33.8130013",
                    "longitude": "-117.9224313"
                },
                {
                    "latitude": "33.813095",
                    "longitude": "-117.922587"
                },
                {
                    "latitude": "33.8130283",
                    "longitude": "-117.9229143"
                }
            ]
        },
        {
            "name": TOONTOWN,
            "border": [
                {
                    "latitude": "33.8153415",
                    "longitude": "-117.9201677"
                },
                {
                    "latitude": "33.8142929",
                    "longitude": "-117.9196186"
                },
                {
                    "latitude": "33.8149258",
                    "longitude": "-117.9189642"
                },
                {
                    "latitude": "33.8148656",
                    "longitude": "-117.9185377"
                },
                {
                    "latitude": "33.814847",
                    "longitude": "-117.9183231"
                },
                {
                    "latitude": "33.8150654",
                    "longitude": "-117.9178082"
                },
                {
                    "latitude": "33.8161083",
                    "longitude": "-117.9176634"
                },
                {
                    "latitude": "33.8161997",
                    "longitude": "-117.9176956"
                },
                {
                    "latitude": "33.8162153",
                    "longitude": "-117.9193424"
                },
                {
                    "latitude": "33.8153415",
                    "longitude": "-117.9201677"
                }
            ]
        },
        {
            "name": MAIN_STREET,
            "border": [
                {
                    "latitude": "33.8099526",
                    "longitude": "-117.9198671"
                },
                {
                    "latitude": "33.8095678",
                    "longitude": "-117.9191995"
                },
                {
                    "latitude": "33.8095455",
                    "longitude": "-117.9187596"
                },
                {
                    "latitude": "33.8096926",
                    "longitude": "-117.9184914"
                },
                {
                    "latitude": "33.8100269",
                    "longitude": "-117.9182446"
                },
                {
                    "latitude": "33.8108827",
                    "longitude": "-117.9182822"
                },
                {
                    "latitude": "33.8117786",
                    "longitude": "-117.9183305"
                },
                {
                    "latitude": "33.8119881",
                    "longitude": "-117.9184753"
                },
                {
                    "latitude": "33.8121886",
                    "longitude": "-117.9185558"
                },
                {
                    "latitude": "33.8123632",
                    "longitude": "-117.9185308"
                },
                {
                    "latitude": "33.8122997",
                    "longitude": "-117.919314"
                },
                {
                    "latitude": "33.8123654",
                    "longitude": "-117.91955"
                },
                {
                    "latitude": "33.8121321",
                    "longitude": "-117.9194647"
                },
                {
                    "latitude": "33.8117711",
                    "longitude": "-117.9195291"
                },
                {
                    "latitude": "33.8117399",
                    "longitude": "-117.9197437"
                },
                {
                    "latitude": "33.8116107",
                    "longitude": "-117.9197115"
                },
                {
                    "latitude": "33.8114101",
                    "longitude": "-117.9195345"
                },
                {
                    "latitude": "33.8111248",
                    "longitude": "-117.9194594"
                },
                {
                    "latitude": "33.8107089",
                    "longitude": "-117.9196179"
                },
                {
                    "latitude": "33.810171",
                    "longitude": "-117.9196525"
                },
                {
                    "latitude": "33.8099526",
                    "longitude": "-117.9198671"
                }
            ]
        },
        {
            "name": TOMORROWLAND,
            "border": [
                {
                    "latitude": "33.8123632",
                    "longitude": "-117.9185308"
                },
                {
                    "latitude": "33.8121886",
                    "longitude": "-117.9185558"
                },
                {
                    "latitude": "33.8119881",
                    "longitude": "-117.9184753"
                },
                {
                    "latitude": "33.8117786",
                    "longitude": "-117.9183305"
                },
                {
                    "latitude": "33.8100269",
                    "longitude": "-117.9182446"
                },
                {
                    "latitude": "33.8101636",
                    "longitude": "-117.9171097"
                },
                {
                    "latitude": "33.8119109",
                    "longitude": "-117.9158866"
                },
                {
                    "latitude": "33.8129717",
                    "longitude": "-117.9156184"
                },
                {
                    "latitude": "33.8141483",
                    "longitude": "-117.9156292"
                },
                {
                    "latitude": "33.8146921",
                    "longitude": "-117.9166806"
                },
                {
                    "latitude": "33.8149034",
                    "longitude": "-117.9170211"
                },
                {
                    "latitude": "33.8137716",
                    "longitude": "-117.9176671"
                },
                {
                    "latitude": "33.8133415",
                    "longitude": "-117.9174539"
                },
                {
                    "latitude": "33.8130094",
                    "longitude": "-117.9176108"
                },
                {
                    "latitude": "33.8127354",
                    "longitude": "-117.9176443"
                },
                {
                    "latitude": "33.812688",
                    "longitude": "-117.9179223"
                },
                {
                    "latitude": "33.8123348",
                    "longitude": "-117.9182153"
                },
                {
                    "latitude": "33.8123632",
                    "longitude": "-117.9185308"
                }
            ]
        }
    ],
    [DISNEY_CALIFORNIA_ADVENTURE]: [
        {
            "name": AVENGERS_CAMPUS,
            "border": [
                {
                    "latitude": "33.8067246",
                    "longitude": "-117.9193412"
                },
                {
                    "latitude": "33.8065196",
                    "longitude": "-117.9184373"
                },
                {
                    "latitude": "33.8060716",
                    "longitude": "-117.9182441"
                },
                {
                    "latitude": "33.8058554",
                    "longitude": "-117.9175924"
                },
                {
                    "latitude": "33.8059869",
                    "longitude": "-117.9171257"
                },
                {
                    "latitude": "33.8066154",
                    "longitude": "-117.9163103"
                },
                {
                    "latitude": "33.8073531",
                    "longitude": "-117.9169567"
                },
                {
                    "latitude": "33.8073575",
                    "longitude": "-117.9171552"
                },
                {
                    "latitude": "33.8071726",
                    "longitude": "-117.9171391"
                },
                {
                    "latitude": "33.8071057",
                    "longitude": "-117.9172168"
                },
                {
                    "latitude": "33.8070946",
                    "longitude": "-117.9180001"
                },
                {
                    "latitude": "33.8070032",
                    "longitude": "-117.9181851"
                },
                {
                    "latitude": "33.8071993",
                    "longitude": "-117.9185231"
                },
                {
                    "latitude": "33.8072684",
                    "longitude": "-117.9187618"
                },
                {
                    "latitude": "33.8070857",
                    "longitude": "-117.9190139"
                },
                {
                    "latitude": "33.8067246",
                    "longitude": "-117.9193412"
                }
            ]
        },
        {
            "name": CARS_LAND,
            "border": [
                {
                    "latitude": "33.8067246",
                    "longitude": "-117.9193412"
                },
                {
                    "latitude": "33.8069162",
                    "longitude": "-117.9197424"
                },
                {
                    "latitude": "33.8064746",
                    "longitude": "-117.9197981"
                },
                {
                    "latitude": "33.8058946",
                    "longitude": "-117.9193893"
                },
                {
                    "latitude": "33.8057475",
                    "longitude": "-117.9193893"
                },
                {
                    "latitude": "33.8053775",
                    "longitude": "-117.9197058"
                },
                {
                    "latitude": "33.8049897",
                    "longitude": "-117.9200008"
                },
                {
                    "latitude": "33.8038664",
                    "longitude": "-117.9200116"
                },
                {
                    "latitude": "33.803853",
                    "longitude": "-117.9185846"
                },
                {
                    "latitude": "33.8053106",
                    "longitude": "-117.9174098"
                },
                {
                    "latitude": "33.8058554",
                    "longitude": "-117.9175924"
                },
                {
                    "latitude": "33.8060716",
                    "longitude": "-117.9182441"
                },
                {
                    "latitude": "33.8065196",
                    "longitude": "-117.9184373"
                },
                {
                    "latitude": "33.8067246",
                    "longitude": "-117.9193412"
                }
            ]
        },
        {
            "name": GRIZZLY_PEAK,
            "border": [
                {
                    "latitude": "33.806573",
                    "longitude": "-117.921915"
                },
                {
                    "latitude": "33.8065953",
                    "longitude": "-117.9207348"
                },
                {
                    "latitude": "33.8069162",
                    "longitude": "-117.9197424"
                },
                {
                    "latitude": "33.8075447",
                    "longitude": "-117.9195171"
                },
                {
                    "latitude": "33.808552",
                    "longitude": "-117.919501"
                },
                {
                    "latitude": "33.8086938",
                    "longitude": "-117.9194589"
                },
                {
                    "latitude": "33.8086635",
                    "longitude": "-117.920075"
                },
                {
                    "latitude": "33.8085921",
                    "longitude": "-117.9205095"
                },
                {
                    "latitude": "33.8084228",
                    "longitude": "-117.9206489"
                },
                {
                    "latitude": "33.8082712",
                    "longitude": "-117.9212444"
                },
                {
                    "latitude": "33.8075001",
                    "longitude": "-117.9211908"
                },
                {
                    "latitude": "33.8073062",
                    "longitude": "-117.9212846"
                },
                {
                    "latitude": "33.8073017",
                    "longitude": "-117.9216306"
                },
                {
                    "latitude": "33.8070588",
                    "longitude": "-117.9220437"
                },
                {
                    "latitude": "33.806573",
                    "longitude": "-117.921915"
                }
            ]
        },
        {
            "name": HOLLYWOOD_LAND,
            "border": [
                {
                    "latitude": "33.8072684",
                    "longitude": "-117.9187618"
                },
                {
                    "latitude": "33.8071993",
                    "longitude": "-117.9185231"
                },
                {
                    "latitude": "33.8070032",
                    "longitude": "-117.9181851"
                },
                {
                    "latitude": "33.8070946",
                    "longitude": "-117.9180001"
                },
                {
                    "latitude": "33.8071057",
                    "longitude": "-117.9172168"
                },
                {
                    "latitude": "33.8071726",
                    "longitude": "-117.9171391"
                },
                {
                    "latitude": "33.8073575",
                    "longitude": "-117.9171552"
                },
                {
                    "latitude": "33.8073531",
                    "longitude": "-117.9169567"
                },
                {
                    "latitude": "33.8073508",
                    "longitude": "-117.9162344"
                },
                {
                    "latitude": "33.8081464",
                    "longitude": "-117.9162398"
                },
                {
                    "latitude": "33.8087348",
                    "longitude": "-117.9165965"
                },
                {
                    "latitude": "33.8087281",
                    "longitude": "-117.9180744"
                },
                {
                    "latitude": "33.8082066",
                    "longitude": "-117.9180798"
                },
                {
                    "latitude": "33.8082088",
                    "longitude": "-117.9185116"
                },
                {
                    "latitude": "33.8075447",
                    "longitude": "-117.9185304"
                },
                {
                    "latitude": "33.8072684",
                    "longitude": "-117.9187618"
                }
            ]
        },
        {
            "name": PARADISE_GARDENS_PARK,
            "border": [
                {
                    "latitude": "33.806573",
                    "longitude": "-117.921915"
                },
                {
                    "latitude": "33.8064434",
                    "longitude": "-117.9220753"
                },
                {
                    "latitude": "33.806537",
                    "longitude": "-117.9226171"
                },
                {
                    "latitude": "33.8065771",
                    "longitude": "-117.9227861"
                },
                {
                    "latitude": "33.8065303",
                    "longitude": "-117.9234298"
                },
                {
                    "latitude": "33.8063074",
                    "longitude": "-117.9236605"
                },
                {
                    "latitude": "33.8058818",
                    "longitude": "-117.9236525"
                },
                {
                    "latitude": "33.8056344",
                    "longitude": "-117.9236122"
                },
                {
                    "latitude": "33.8054828",
                    "longitude": "-117.9234003"
                },
                {
                    "latitude": "33.8055007",
                    "longitude": "-117.9227432"
                },
                {
                    "latitude": "33.8055118",
                    "longitude": "-117.9224777"
                },
                {
                    "latitude": "33.8058082",
                    "longitude": "-117.9223382"
                },
                {
                    "latitude": "33.8056611",
                    "longitude": "-117.9217052"
                },
                {
                    "latitude": "33.8055475",
                    "longitude": "-117.9212385"
                },
                {
                    "latitude": "33.8057748",
                    "longitude": "-117.9208938"
                },
                {
                    "latitude": "33.805845",
                    "longitude": "-117.9207041"
                },
                {
                    "latitude": "33.8059508",
                    "longitude": "-117.9206055"
                },
                {
                    "latitude": "33.8060957",
                    "longitude": "-117.9202005"
                },
                {
                    "latitude": "33.8062333",
                    "longitude": "-117.9198182"
                },
                {
                    "latitude": "33.8063016",
                    "longitude": "-117.9196754"
                },
                {
                    "latitude": "33.8064746",
                    "longitude": "-117.9197981"
                },
                {
                    "latitude": "33.8069162",
                    "longitude": "-117.9197424"
                },
                {
                    "latitude": "33.8065953",
                    "longitude": "-117.9207348"
                },
                {
                    "latitude": "33.806573",
                    "longitude": "-117.921915"
                }
            ]
        },
        {
            "name": PIXAR_PIER,
            "border": [
                {
                    "latitude": "33.8054828",
                    "longitude": "-117.9234003"
                },
                {
                    "latitude": "33.8047606",
                    "longitude": "-117.922689"
                },
                {
                    "latitude": "33.8043936",
                    "longitude": "-117.9221884"
                },
                {
                    "latitude": "33.803979",
                    "longitude": "-117.9220436"
                },
                {
                    "latitude": "33.8038587",
                    "longitude": "-117.9213677"
                },
                {
                    "latitude": "33.8038664",
                    "longitude": "-117.9200116"
                },
                {
                    "latitude": "33.8049897",
                    "longitude": "-117.9200008"
                },
                {
                    "latitude": "33.805845",
                    "longitude": "-117.9207041"
                },
                {
                    "latitude": "33.8057748",
                    "longitude": "-117.9208938"
                },
                {
                    "latitude": "33.8055475",
                    "longitude": "-117.9212385"
                },
                {
                    "latitude": "33.8051881",
                    "longitude": "-117.9212778"
                },
                {
                    "latitude": "33.8052617",
                    "longitude": "-117.9218304"
                },
                {
                    "latitude": "33.8055118",
                    "longitude": "-117.9224777"
                },
                {
                    "latitude": "33.8054828",
                    "longitude": "-117.9234003"
                }
            ]
        },
        {
            "name": SAN_FRANSOKYO_SQUARE,
            "border": [
                {
                    "latitude": "33.805845",
                    "longitude": "-117.9207041"
                },
                {
                    "latitude": "33.8049897",
                    "longitude": "-117.9200008"
                },
                {
                    "latitude": "33.8057475",
                    "longitude": "-117.9193893"
                },
                {
                    "latitude": "33.8058946",
                    "longitude": "-117.9193893"
                },
                {
                    "latitude": "33.8063016",
                    "longitude": "-117.9196754"
                },
                {
                    "latitude": "33.8062333",
                    "longitude": "-117.9198182"
                },
                {
                    "latitude": "33.8059508",
                    "longitude": "-117.9206055"
                },
                {
                    "latitude": "33.805845",
                    "longitude": "-117.9207041"
                }
            ]
        },
        {
            "name": BUENA_VISTA_STREET,
            "border": [
                {
                    "latitude": "33.8086938",
                    "longitude": "-117.9194589"
                },
                {
                    "latitude": "33.808552",
                    "longitude": "-117.919501"
                },
                {
                    "latitude": "33.8075447",
                    "longitude": "-117.9195171"
                },
                {
                    "latitude": "33.8069162",
                    "longitude": "-117.9197424"
                },
                {
                    "latitude": "33.8067246",
                    "longitude": "-117.9193412"
                },
                {
                    "latitude": "33.8070857",
                    "longitude": "-117.9190139"
                },
                {
                    "latitude": "33.8072684",
                    "longitude": "-117.9187618"
                },
                {
                    "latitude": "33.8075447",
                    "longitude": "-117.9185304"
                },
                {
                    "latitude": "33.8082088",
                    "longitude": "-117.9185116"
                },
                {
                    "latitude": "33.8082066",
                    "longitude": "-117.9180798"
                },
                {
                    "latitude": "33.8087281",
                    "longitude": "-117.9180744"
                },
                {
                    "latitude": "33.8087138",
                    "longitude": "-117.9182385"
                },
                {
                    "latitude": "33.8087138",
                    "longitude": "-117.9185336"
                },
                {
                    "latitude": "33.8088208",
                    "longitude": "-117.9187508"
                },
                {
                    "latitude": "33.8088208",
                    "longitude": "-117.9192041"
                },
                {
                    "latitude": "33.8086938",
                    "longitude": "-117.9194589"
                }
            ]
        }
    ]
}