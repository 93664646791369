import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { getGeniePlusCompleteAttractions, getLiveDataForPark, getWaitHistoryForPark } from '../../data';
import { useAppSelector } from '../../redux/hooks';
import { onRefresh, selectSelectedPark } from '../../redux/parks';
import { AttractionComponent, DownAttractionComponent } from './Attractions';
import { Attraction } from '../../data/attractions';
import { tiers } from '../../data/tiers';
import { IGeniePlusCompleteAttraction, LiveData, LiveStatusType, WaitHistory } from '../../data/interfaces';
import { isGeniePlusRide } from '../../data/common';
import Header from '../../components/Header';


function GeniePlus() {
    const selectedPark = useAppSelector(selectSelectedPark);
    const refreshState = useAppSelector(onRefresh);

    const [attractions, setAttractions] = useState<LiveData[]>([]);
    const [genieCompleteAttractions, setGenieCompleteAttractions] = useState<{[id: string]: IGeniePlusCompleteAttraction}>({})
    const [waitHistory, setWaitHistory] = useState<{[id: string]: WaitHistory}>({});

    useEffect(() => {
        const loadWaitTimesForPark = async () => {
            const waitTimes = await getLiveDataForPark(selectedPark.id, true);
            
            const geniePlusAttractions: LiveData[] = waitTimes.liveData
                .filter(isGeniePlusRide);

            setAttractions(geniePlusAttractions);
        }

        if (refreshState.isHardRefresh) {
            setAttractions([]);
        }
        loadWaitTimesForPark();
    }, [selectedPark, refreshState]);

    useEffect(() => {
        const loadGeniePlusCompleteAttractions = async () => {
            const geniePlusCompleteAttractions = await getGeniePlusCompleteAttractions(selectedPark.id);
    
            const geniePlusCompletionMap: {[id: string]: IGeniePlusCompleteAttraction} = 
                Object.assign({}, ...geniePlusCompleteAttractions
                    .filter(a => a.completedAt != null)
                    .map(a => ({[a.attractionId]: a})));
    
            setGenieCompleteAttractions(geniePlusCompletionMap);
        }

        loadGeniePlusCompleteAttractions();
    }, [refreshState])

    useEffect(() => {
        const loadWaitHistoryForPark = async () => {
            const newWaitHistory: {[id: string]: WaitHistory} = {};
            const waitHistoryResponse = await getWaitHistoryForPark(selectedPark.id, true);
            for (const history of waitHistoryResponse) {
                newWaitHistory[history.attractionId] = history;
            }
            setWaitHistory(newWaitHistory);
        }

        loadWaitHistoryForPark();
    }, [selectedPark, refreshState]);
    
    const currentLocalTime = new Date();

    let tier1Attractions: Attraction[] = [];
    let tier2Attractions: Attraction[] = [];
    let tier3Attractions: Attraction[] = [];
    let downAttractions: Attraction[] = [];
    let completedAttractions: Attraction[] = [];
    for (const attraction of attractions) {
        const thisParksTiers: any = tiers[attraction.parkId];
        const thisAttractionsTier = thisParksTiers[attraction.id];
        const waitHistoryForThisAttraction = waitHistory[attraction.id];

        const thisAttraction = new Attraction(attraction, waitHistoryForThisAttraction);
        if (attraction.id in genieCompleteAttractions) {
            thisAttraction.completedAt = genieCompleteAttractions[attraction.id].completedAt;
            completedAttractions.push(thisAttraction);
        }
        else if (attraction.status == LiveStatusType.Down) {
            downAttractions.push(thisAttraction);
        }
        else if (thisAttractionsTier == 1) {
            tier1Attractions.push(thisAttraction);
        }
        else if (thisAttractionsTier == 2) {
            tier2Attractions.push(thisAttraction);
        }
        else if (thisAttractionsTier == 3) {
            tier3Attractions.push(thisAttraction);
        }
    }

    tier1Attractions = tier1Attractions.sort(Attraction.sortByReturnTime);
    tier2Attractions = tier2Attractions.sort(Attraction.sortByReturnTime);
    tier3Attractions = tier3Attractions.sort(Attraction.sortByReturnTime);
    completedAttractions = completedAttractions.sort(Attraction.sortByCompletedAt);

    let downAttractionsList = null;
    let openAttractionsRefreshedAt: JSX.Element | null = (<ListItem>
            <ListItemText sx={{textAlign: 'center'}} secondaryTypographyProps={{component: 'span'}} secondary={<Header />} />
        </ListItem>);
    if (downAttractions.length > 0) {
        downAttractionsList = (<List sx={{ width: '100%', bgcolor: 'background.paper'}}>
            <ListItem>
                <ListItemText sx={{textAlign: 'center'}} secondaryTypographyProps={{component: 'span'}} secondary={<Header />} />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
            {downAttractions.map(attraction =>  
                    (<DownAttractionComponent 
                        key={attraction.name}
                        attraction={attraction} 
                        tier={1}
                        currentLocalTime={currentLocalTime} />
                ))
            }
        </List>);
        openAttractionsRefreshedAt = null;
    }

    return (
    <>
        {downAttractionsList}
        <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
            {openAttractionsRefreshedAt}
            <Divider variant="fullWidth" component="li" />
            {tier1Attractions.map(attraction =>  
                    (<AttractionComponent 
                        key={attraction.name} 
                        attraction={attraction} 
                        tier={1}
                        currentLocalTime={currentLocalTime} />
                ))
            }
        </List>
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
            <Divider variant="fullWidth" component="li"  sx={{mt: '50px'}} />
            {tier2Attractions.map(attraction =>  
                    (<AttractionComponent 
                        key={attraction.name} 
                        attraction={attraction} 
                        tier={2}
                        currentLocalTime={currentLocalTime} />
                ))
            }
        </List>
        <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
            <Divider variant="fullWidth" component="li" sx={{mt: '50px'}} />
            {tier3Attractions.map(attraction =>  
                    (<AttractionComponent 
                        key={attraction.name} 
                        attraction={attraction} 
                        tier={3}
                        currentLocalTime={currentLocalTime} />
                ))
            }
        </List>

        <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
            <Divider variant="fullWidth" component="li" sx={{mt: '50px'}} />
            {completedAttractions.map(attraction =>  
                    (<AttractionComponent 
                        key={attraction.name} 
                        attraction={attraction} 
                        tier={3}
                        currentLocalTime={currentLocalTime}
                        hasBeenCompletedToday />
                ))
            }
        </List>
    </>)
};


export default GeniePlus;