import { useAppSelector } from "../redux/hooks";
import { selectSelectedPark } from "../redux/parks";
import { useEffect, useState } from "react";
import { getScheduleDataForPark } from "../data";
import { toCustomTimeString } from "../data/time";
import { Typography } from "@mui/material";
import { pink } from "@mui/material/colors";
import ParkSchedule5Day from "./ParkSchedule5Day";
import { ScheduleEntityResponse } from "../data/interfaces";
import { parseParkSchedule } from "../data/schedule";

interface ParkHours {
    hasEarlyEntry: boolean;
    parkOpen?: Date;
    parkClose?: Date;
    rawParkSchedule?: ScheduleEntityResponse
}

const defaultParkHours: ParkHours = {
    hasEarlyEntry: false
}

const ParkSchedule = () => { 
    const selectedPark = useAppSelector(selectSelectedPark);

    const [parkHours, setParkHours] = useState<ParkHours>(defaultParkHours);

    const loadParkSchedule = async () => {
        const response = await getScheduleDataForPark(selectedPark.id);
        const parkSchedule = parseParkSchedule(response);
        
        setParkHours({
            hasEarlyEntry: parkSchedule.hasEarlyEntry,
            parkOpen: parkSchedule.hasEarlyEntry ? parkSchedule.earlyEntryParkOpen : parkSchedule.normalParkOpen,
            parkClose: parkSchedule.parkClose,
            rawParkSchedule: response
        });
    }

    useEffect(() => {
        loadParkSchedule();
    }, [selectedPark]);

    const [fiveDayScheduleOpen, setFiveDayScheduleOpen] = useState(false);
    const onScheduleClick = () => {
        setFiveDayScheduleOpen(true);
    }
    let fiveDaySchedule = <ParkSchedule5Day parkSchedule={parkHours.rawParkSchedule} open={fiveDayScheduleOpen} handleClose={() => setFiveDayScheduleOpen(false)} />;

    let parkStart = <>{toCustomTimeString(parkHours.parkOpen)}</>
    if (parkHours.hasEarlyEntry) {
        parkStart =<Typography component={'span'}  sx={{ display: 'inline', fontSize: '1em', color: pink[500] }} variant="body2">
            {toCustomTimeString(parkHours.parkOpen)}
        </Typography>
    }

    let parkText = <></>;
    if (parkHours.parkOpen != null) {
        const currentLocalTime = new Date();
        const parkIsClosed: boolean = currentLocalTime < parkHours.parkOpen || (parkHours.parkClose != null && currentLocalTime > parkHours.parkClose)

        parkText = <>
            {parkIsClosed ? <Typography component={'div'}  sx={{ fontWeight: 'bold', fontSize: '1.5em', color: 'red' }} variant="body2">
                Park closed
            </Typography> : null}
            <div>Park hours: {parkStart} - {toCustomTimeString(parkHours.parkClose)}</div>
        </>
    }

    return (
        <div>
            <Typography onClick={onScheduleClick} component={'span'}  sx={{ display: 'inline', fontSize: '1.1em' }} variant="body2">
                {parkText}
            </Typography>
            {fiveDaySchedule}
        </div>);
};

export default ParkSchedule;