import { ANIMAL_KINGDOM, DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE, EPCOT, HOLLYWOOD_STUDIOS, MAGIC_KINGDOM } from "../constants/parks";

export const tiers: {[parkId: string] : {[attractionId: string] : number}} = {
    [MAGIC_KINGDOM]: { // MK
        "86a41273-5f15-4b54-93b6-829f140e5161": 1, // Peter pan
        "e76c93df-31af-49a5-8e2f-752c76c937c9": 1, // enchanted tales with belle
        "012a211b-4c91-451c-8a0e-5e3ab398eda8": 1, // meet ariel
        
        "796b0a25-c51e-456e-9bb8-50a324e301b3": 2, // Jungle cruise
        "de3309ca-97d5-4211-bffe-739fed47e92f": 2, // big thunder mountain
        "2551a77d-023f-4ab1-9a19-8afec0190f39": 2, // haunted mansion
        "40737d3d-0ff6-4a9e-a050-beb87bf90120": 2, // cindarella and a princess
        "a2d92647-634d-4eb4-886b-9da858e871f1": 2, // meet mickey
        "cf4b2ba4-3626-4de7-9d07-abe8a65b1665": 2, // meet tiana
        "b2260923-9315-40fd-9c6b-44dd811dbe64": 2, // space mountain
        "352feb94-e52e-45eb-9c92-e4b44c6b1a9d": 2, // pirates
        "0d94ad60-72f0-4551-83a6-ebaecdd89737": 2, // winnie the pooh

        "f5aad2d4-a419-4384-bd9a-42f86385c750": 3, // small world
        "72c7343a-f7fb-4f66-95df-c91016de7338": 3, // buzz lightyear
        "890fa430-89c0-4a3f-96c9-11597888005e": 3, // dumbo
        "0aae716c-af13-4439-b638-d75fb1649df3": 3, // mad tea party
        "7c5e1e02-3a44-4151-9005-44066d5ba1da": 3, // philharmagic
        "e8f0b426-7645-4ea3-8b41-b94ae7091a41": 3, // monsters inc
        "924a3b2c-6b4b-49e5-99d3-e9dc3f2e8a48": 3, // barnstormer
        "96455de6-f4f1-403c-9391-bf8396979149": 3, // aladdin
        "f163ddcd-43e1-488d-8276-2381c1db0a39": 3, // tomorrowland speedway
        "3cba0cb4-e2a6-402c-93ee-c11ffcb127ef": 3 // under the sea
    },
    [EPCOT]: {  // Epcot
        "1e735ffb-4868-47f1-b2cd-2ac1156cd5f0": 1, // remy
        "8d7ccdb1-a22b-4e26-8dc8-65b1938ed5f0": 1, // frozen
        "37ae57c5-feaf-4e47-8f27-4b385be200f0": 1, // test track

        "81b15dfd-cf6a-466f-be59-3dd65d2a2807": 2, // soarin
        "5b6475ad-4e9a-4793-b841-501aa382c9c0": 2, // mission space

        "480fde8f-fe58-4bfb-b3ab-052a39d4db7c": 3, // spaceship earth
        "fb076275-0570-4d62-b2a9-4d6515130fa3": 3, // the seas with nemo and friends
        "35ed719b-f7f0-488f-8346-4fbf8055d373": 3, // short film festival
        "75449e85-c410-4cef-a368-9d2ea5d52b58": 3, // imagination with figment
        "8f353879-d6ac-4211-9352-4029efb47c18": 3, // living with the land
        "57acb522-a6fc-4aa4-a80e-21f21f317250": 3, // turtle talk with crush
    },
    [HOLLYWOOD_STUDIOS]: { // HS
        "399aa0a1-98e2-4d2b-b297-2b451e9665e1": 1, // slinky dog
        "34c4916b-989b-4ff1-a7e3-a6a846a3484f": 1, // smugglers run

        "6e118e37-5002-408d-9d88-0b5d9cdb5d14": 2, // runaway railway
        "d56506e2-6ad3-443a-8065-fea37987248d": 2, // alien swirling saucers
        "e516f303-e82d-4fd3-8fbf-8e6ab624cf89": 2, // rock n roller coaster
        "6f6998e8-a629-412c-b964-2cb06af8e26b": 2, // tower of terror
        "20b5daa8-e1ea-436f-830c-2d7d18d929b5": 2, // toy story mania

        "375197ac-27ac-41f7-bd93-f4e9b9fc4d5d": 3, // beauty and the beast show
        "c35fd0d8-3a15-4609-8edf-92204c20d0e6": 3, // disney junior play and dance
        "d91a0e9a-8652-4036-822f-e7b12b381273": 3, // frozen sing-a-long
        "7357772c-6b11-4a8d-af97-05a1bb45f001": 3, // indiana jones
        "76dfa347-94bb-4552-b183-a08490c54acc": 3, // muppet vision
        "27f9fc86-2341-4bf4-8cbf-67fc16a841f1": 3, // meet olaf
        "3b290419-8ca2-44bc-a710-a6c83fca76ec": 3, // star tours
    },
    [ANIMAL_KINGDOM]: { // AK
        "32e01181-9a5f-4936-8a77-0dace1de836c": 1, // killamanjaro's safari
        "7a5af3b7-9bc1-4962-92d0-3ea9c9ce35f0": 1, // navi river journey

        "55c531b6-3ce2-4c47-a8a1-0dc9107d825b": 2, // dinosaur
        "64a6915f-a835-4226-ba5c-8389fc4cade3": 2, // expedition everest
        "d58d9262-ec95-4161-80a0-07ca43b2f5f3": 2, // kali river rapids

        "3a4e0f49-f9ff-4481-a95b-d4952cdf6097": 3, // festival of the lion king
        "95712b31-ceed-4f7d-be3e-3d5e6badac5c": 3, // finding nemo show
        "ba44245e-5562-4359-be27-9dfb2d96cb2d": 3, // tough to be a bug
        "13d7c9d1-a785-403d-9ba8-71fee2f02d55": 3, // disney pals at outpost
        "3b7309a5-7b57-4edf-b4e6-36ad958ac21e": 3, // animation experience
        "d8214ba6-22a7-4a92-8977-bae3779d5c4c": 3, // feathered friends in flight
    },
    [DISNEYLAND]: { // DL
        '2aedc657-1ee2-4545-a1ce-14753f28cc66': 1, // indiana jones
        'faaa8be9-cc1e-4535-ac20-04a535654bd0': 1, // matterhorn
        'cd670bff-81d1-4f34-8676-7bafdf49220a': 1, // runaway railway
        '9167db1d-e5e7-46da-a07f-ae30a87bc4c4': 1, // space mountain

        '0de1413a-73ee-46cf-af2e-c491cc7c7d3b': 2, // big thunder
        '88197808-3c56-4198-a5a4-6066541251cf': 2, // buzz lightyear astro blasters
        'ff52cb64-c1d5-4feb-9d43-5dbd429bac81': 2, // haunted mansion
        'b2c2549c-e9da-4fdd-98ea-1dcff596fed7': 2, // smugglers run
        '82aeb29b-504a-416f-b13f-f41fa5b766aa': 2, // pirates
        '6ce9cdd1-0a43-459e-83cd-f4cace9cfa7b': 2, // roger rabbit
        'cc718d11-fa15-44ee-87d0-ded989ad61bc': 2, // star tours

        '3638ac09-9fce-4a43-8c79-8ebbe17afce2': 3, // small world
        '1da85181-bf0f-4ccc-b98e-243142f7347b': 3, // autopia
    },
    [DISNEY_CALIFORNIA_ADVENTURE]: { // DCA
        'b7678dab-5544-48d5-8fdc-c1a0127cfbcd': 1, // mission breakout
        '86ab3069-110d-49c5-a7e7-29ddf28695a6': 1, // midway mania
        '2295351d-ce6b-4c04-92d5-5b416372c5b5': 1, // webslingers

        'f44a5072-3cda-4c7c-8574-33ad09d16cca': 2, // goofy's sky school
        'b1d285a7-2444-4a7c-b7bb-d2d4d6428a85': 2, // grizzly river run
        '5d07a2b1-49ca-4de7-9d32-6d08edf69b08': 2, // incredicoaster
        '40524fba-5d84-49e7-9204-f493dbe2d5a4': 2, // monsters inc
        '77f205a4-d482-4d91-a5ff-71e54a086ad2': 2, // soarin

        'e1fbc7a1-2cd1-4282-b373-ac11d9d9d38a': 3 // little mermaid
    }
}